import React from "react";
import { ICON_COLOR, TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";

const TeambookRadioButton = ({ checked, onCheck, style }) => {
  return (
    <>
      {checked ? (
        <TeambookIcon
          style={{ cursor: "pointer", ...style }}
          color={ICON_COLOR.ALTERNATIVE}
          name={icons.RADIO_CHECKED}
          className="tb-radio-button"
          onClick={onCheck}
        />
      ) : (
        <TeambookIcon
          style={{ cursor: "pointer", ...style }}
          name={icons.RADIO_UNCHECKED}
          className="tb-radio-button"
          onClick={onCheck}
        />
      )}
    </>
  );
};

export default TeambookRadioButton;
