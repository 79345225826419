import { forwardRef, useState, useImperativeHandle, useContext, useRef, useEffect } from "react";
import { ICON_COLOR, TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { Portal } from "./Portal";
import { PlannerContext } from "../context/PlannerContext";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import lsKeys from "../../default_values/defaultKeys";
import { useBookingsStore } from "../../../stores/planner";
import { shallow } from "zustand/shallow";

export const WeekendInfo = forwardRef(({ deleteBookingRequests, selectBooking, addBookingToCreation }, ref) => {
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState({ x: null, y: null });
  const [userIndex, setUserIndex] = useState(null);
  const [userId, setUserId] = useState(null);
  const [dates, setDates] = useState([]);
  const lang = localStorage.getItem(lsKeys.LANGUAGE);
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(140);
  const [containerWidth, setContainerWidth] = useState(190);
  const role = localStorage.getItem(lsKeys.ROLE);

  const [bookings, setBookings] = useBookingsStore((state) => [state.bookings, state.setBookings], shallow);

  const { t } = useTranslation();

  const close = () => {
    setOpen(false);
  };

  const { projects } = useContext(PlannerContext);

  const deleteBooking = (booking) => {
    deleteBookingRequests([booking]).then(() => {
      if (bookings.length - 1 === 0) {
        close();
      }
    });
  };

  const editBooking = (booking) => {
    selectBooking(booking);
    close();
  };

  useImperativeHandle(ref, () => {
    return {
      open: open,
      setOpen: setOpen,
      setPos,
      setUserIndex: setUserIndex,
      setUserId: setUserId,
      setDates,
    };
  });

  const startBookingCreation = (creationDate) => {
    addBookingToCreation(userIndex, new Date(creationDate));
    close();
  };

  const calculateTopOffset = () => {
    if (pos.y + containerHeight + 15 >= window.innerHeight) {
      return pos.y - containerHeight - 15;
    } else return pos.y + 15;
  };

  const calculateLeftOffset = () => {
    if (pos.x + containerWidth / 2 > window.innerWidth) {
      return pos.x - containerWidth;
    } else return pos.x - containerWidth / 2;
  };

  useEffect(() => {
    if (open) {
      setContainerHeight(containerRef.current.getBoundingClientRect().height);
      setContainerWidth(containerRef.current.getBoundingClientRect().width);
    }
  }, [open]);

  return (
    open && (
      <>
        <Portal onClick={close} />
        <div
          ref={containerRef}
          id="weekend-info__container"
          style={{ left: calculateLeftOffset(), top: calculateTopOffset() }}
          className="weekend-info__container"
        >
          {dates.map((date, index) => {
            return (
              <div className="weekend-info__container-day">
                <div className="weekend-info__day-header">
                  <p>
                    {DateTime.fromISO(date).setLocale(lang).toFormat("d MMMM")},{" "}
                    {DateTime.fromISO(date).setLocale(lang).weekdayShort}
                  </p>
                </div>
                <div className="weekend-info__bookings-list">
                  {bookings
                    .filter((booking) => {
                      return booking.date === date && userId === booking.user_id;
                    })
                    .map((booking) => {
                      return (
                        <div className="weekend-info__booking">
                          <div className="weekend-info__project-color" style={{ background: booking.project_color }} />
                          <p className="weekend-info__project-name">
                            {projects.find((project) => project.id === booking.project_id)?.name || ""}
                          </p>

                          <p className="weekend-info__booking-duration">{booking.duration / 60}h </p>

                          <div className="weekend-info__booking-actions">
                            <TeambookIcon
                              onClick={() => editBooking(booking)}
                              className="pointer weekend-info__booking-button"
                              name={icons.PENCIL}
                            />
                            <TeambookIcon
                              className="pointer weekend-info__booking-button"
                              onClick={() => deleteBooking(booking)}
                              name={icons.TRASH}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
                {["planner", "admin", "self_planner"].includes(role) && (
                  <div
                    onClick={() => startBookingCreation(date)}
                    className="weekend-info__create-booking-button pointer"
                  >
                    <TeambookIcon style={{ width: 16, height: 16 }} name={icons.PLUS} />
                    <p>{t("planning.add_booking")}</p>
                  </div>
                )}
              </div>
            );
          })}

          <TeambookIcon
            onClick={close}
            name={icons.NEW_CROSS}
            style={{ position: "absolute", right: 20, top: 21, width: 18, height: 18, cursor: "pointer" }}
          />
        </div>
      </>
    )
  );
});
