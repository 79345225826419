import React, { useState, useEffect, useRef } from "react";
import TimeZones from "../../../default_values/TimeZones";
import { useTranslation } from "react-i18next";
import Api from "../../../../Api";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import UsersData from "../lib/UsersData";
import { useDataChange } from "../../../../lib/useDataChange";
import WarningModal from "../../../default_components/WarningModal";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { DateTime } from "luxon";
import { shallow } from "zustand/shallow";
import { useProfileStore } from "../../../../stores/profileStore";
import { useNotificationStore } from "../../../../stores/notificationStore";
import { useTeambookDataStore } from "../../../../stores/users";

const EditUser = ({ getAllUsers, plannerActions = null }) => {
  const { t } = useTranslation();
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [currentUser] = useProfileStore((state) => [state.profile], shallow);
  const [departments, setDepartments, teams, user, tab, setTab, userFormState, setUserFormState, setUser] =
    useTeambookDataStore(
      (state) => [
        state.departments,
        state.setDepartments,
        state.teams,
        state.chosenUser,
        state.tab,
        state.setTab,
        state.userFormState,
        state.setUserFormState,
        state.setChosenUser,
      ],
      shallow
    );

  const roleOptions = () => {
    const roles = [
      { value: "regular", name: t("users.regular") },
      { value: "contractor", name: t("users.contractor") },
      { value: "self_planner", name: t("users.self_planner") },
      { value: "planner", name: t("users.planner") },
    ];

    if (currentUser.role === "admin") {
      roles.push({ value: "admin", name: t("users.admin") });
    }

    return roles;
  };

  const userBillability = [
    { value: true, name: t("users.billable") },
    { value: false, name: t("users.non_billable") },
  ];

  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [email, setEmail] = useState(user.email);
  const [billable, setBillable] = useState(userBillability.find(({ value }) => value === user.billable));
  const [role, setRole] = useState(roleOptions().find(({ value }) => value === user.role));
  const [timeZone, setTimeZone] = useState(TimeZones.timeZonesArray.find(({ value }) => value === user.time_zone));
  const [tagName, setTagName] = useState(user.tags);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const [userTeams, setUserTeams] = useState(
    teams.length === 1 ? [teams[0]] : user.team_ids.map((user_team) => teams.find((team) => team.id === user_team))
  );
  const [schedule, setSchedule] = useState(JSON.parse(JSON.stringify(user.schedule)));
  const [uploadPhotoProps, setUploadPhotoProps] = useState({
    croppedImage: undefined,
    analyleUpdateRespnose: () => {},
  });
  const [startDate, setStartDate] = useState(user.start_date ? DateTime.fromISO(user.start_date) : null);
  const [endDate, setEndDate] = useState(user.end_date ? DateTime.fromISO(user.end_date) : null);
  const editorRef = useRef();
  const [notificationSetting, setNotificationSetting] = useState(user.notification_setting);
  const [warningOpened, setWarningOpened] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [selectedHoliday, setSelectedHoliday] = useState();
  const [customWorkingHours, setCustomWorkingHours] = useState(user.custom_working_hours);
  const [departmentId, setDepartmentId] = useState({
    value: user.department_id,
    name: departments?.find((d) => d.id === user.department_id)?.name || "None",
  });

  useEffect(() => {
    Api.Integrations.Holidays.get().then((res) => {
      setHolidays(res.data);

      if (res.data.length > 0) {
        const holiday = res.data.filter((holiday) => holiday.users.map((u) => u.id).includes(user.id))[0];

        if (holiday) {
          setSelectedHoliday(holiday.id);
        }
      }
    });
  }, []);

  const isChanged = useDataChange(
    {
      first_name: firstName,
      last_name: lastName,
      role: role.value,
      email: email,
      billable: billable?.value,
      start_date: startDate,
      end_date: endDate,
      notification_setting: notificationSetting,
      team_ids: userTeams.filter((t) => t).map((team) => team.id),
      tags: tagName.map((tag) => tag.id),
      schedule: schedule,
    },
    {
      ...user,
      start_date: user.start_date ? DateTime.fromISO(user.start_date) : null,
      end_date: user.end_date ? DateTime.fromISO(user.end_date) : null,
      tags: user.tags.map((tag) => tag.id),
    }
  );

  const submitUploadPhoto = () => {
    const { croppedImage, analyzeUpdateResponse } = uploadPhotoProps;
    if (croppedImage) {
      const formData = new FormData();
      formData.append("token", localStorage.getItem("Auth-Token"));
      formData.append("image", croppedImage["croppedImage"]);
      Api.Users.update_photo(formData, user.id).then(analyzeUpdateResponse);
    }
  };

  const submitForm = () => {
    if (startDate && endDate && startDate > endDate) {
      setErrorMessage(t("actuals.date_error"));
      setErrorField("userEndDate");
      return;
    }

    if (!userTeams.length) {
      setErrorMessage(t("users.noteams"));
      setErrorField("teams");
    } else {
      Api.Users.update(user.id, {
        settings: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          billable: billable?.value || false,
          role: role.value,
          time_zone: timeZone?.value || null,
          schedule: schedule,
          team_ids: userTeams.filter((t) => t).map(({ id }) => id),
          start_date: startDate,
          end_date: endDate,
          holiday: selectedHoliday,
          notes: editorRef?.current?.getComment() === "<p></p>" ? null : editorRef?.current?.getComment(),
          custom_working_hours: customWorkingHours,
          department_id: departmentId?.value,
        },

        notifications: notificationSetting,

        tags: tagName.map(({ id }) => id),
      })
        .then((response) => {
          getAllUsers();
          Api.Departments.all().then((response) => {
            setDepartments(response.data);
          });
          setInAppNotification(t("users.updated_successfully"));
          closeEditingWindow(false);
          setUser(null);
        })
        .catch((error) => analyzeEditUserError(error));
    }
  };

  const analyzeEditUserError = (error) => {
    setErrorMessage(error.response.data.error.message);
    setErrorField(error.response.data.error.field);

    setWarningOpened(false);
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
    setErrorField(null);
  };

  const closeEditingWindow = (handleChanges = true) => {
    if (isChanged && handleChanges) {
      setWarningOpened(true);
    } else {
      setUser(null);
      setUserFormState("");
      setTab("general");
    }
  };

  const closeWarningModal = () => {
    setWarningOpened(false);
  };

  return (
    <>
      <Dialog
        open={plannerActions || userFormState === "edit"}
        onClose={closeEditingWindow}
        className="form-edit"
        classes={{ paper: "user-form-dialog-paper" }}
      >
        <DialogTitle>
          {tab === "general" ? (
            <p>{t("users.edit_user")}</p>
          ) : (
            <p>{`${t("planning.filter.user")}: ${user.first_name} ${user.last_name[0]}`}</p>
          )}

          <TeambookIcon className="form_close" name={icons.CROSS} onClick={closeEditingWindow} />
        </DialogTitle>

        <DialogContent style={{ overflow: "unset" }}>
          <UsersData
            viewType={"edit"}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            billable={billable}
            setBillable={setBillable}
            role={role}
            setRole={setRole}
            timeZone={timeZone}
            setTimeZone={setTimeZone}
            tagName={tagName}
            setTagName={setTagName}
            userTeams={userTeams}
            setUserTeams={setUserTeams}
            closeErrorMessage={closeErrorMessage}
            errorField={errorField}
            errorMessage={errorMessage}
            roleOptions={roleOptions()}
            userBillability={userBillability}
            user={user}
            getAllUsers={getAllUsers}
            schedule={schedule}
            setSchedule={setSchedule}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            tab={tab}
            setTab={setTab}
            notificationSetting={notificationSetting}
            setNotificationSetting={setNotificationSetting}
            holidays={holidays}
            selectedHoliday={selectedHoliday}
            setSelectedHoliday={setSelectedHoliday}
            notes={user.notes}
            setNotes={editorRef}
            customWorkingHours={customWorkingHours}
            setCustomWorkingHours={setCustomWorkingHours}
            departmentId={departmentId}
            setDepartmentId={setDepartmentId}
          />
        </DialogContent>

        <DialogActions style={{ padding: "0px 40px 40px 40px" }}>
          {plannerActions}

          <TeambookOutlinedButton
            onClick={closeEditingWindow}
            className={"new-user__close edit-user__outlined-button"}
            text={t("cancel")}
          />
          <TeambookBlueButton
            text={t("save")}
            onClick={() => {
              submitForm();
              submitUploadPhoto();
            }}
            className="user-form__edit-button edit-user__blue-button"
          />
        </DialogActions>
      </Dialog>

      {warningOpened && (
        <WarningModal
          onNo={() => {
            setUserFormState("");
            setUser(null);
            setTab("general");
          }}
          onYes={() => {
            submitForm();
            submitUploadPhoto();
          }}
          closeModal={closeWarningModal}
        />
      )}
    </>
  );
};

export default EditUser;
