import React from "react";
import EditSettings from "./general_settings_form/EditSettings";
import TimeOffHolidaysSettings from "./time_off_holidays/TimeOffHolidaysSettings";
import ScheduleComponent from "./ScheduleComponent";
import DeleteAccountComponent from "./DeleteAccountComponent";
import SubscriptionComponent from "./subscrtiption/SubscriptionComponent";
import IntegrationComponent from "./integration/IntegrationComponent";
import TasksPayrollSettings from "./tasks_payroll/TasksPayrollSettings";
import CustomizationComponent from "./customization/CustomizationComponent";

const SettingsRightSide = ({ page, updateAccountRequest, deleteAccountRequest, users, changePage }) => {
  const pages = {
    settings: <EditSettings />,
    time_offs: <TimeOffHolidaysSettings users={users} />,
    tasks: <TasksPayrollSettings />,
    schedule: <ScheduleComponent submitForm={updateAccountRequest} />,
    integration: <IntegrationComponent />,
    customization: <CustomizationComponent />,
    subscription: <SubscriptionComponent />,
    delete: <DeleteAccountComponent submitForm={deleteAccountRequest} />,
  };

  const openedPage = pages[page];

  if (!openedPage) {
    changePage("settings");
  }

  return (
    <div
      className="settings__right-side__container"
      style={{
        height: page === "settings" ? "fit-content" : "unset",
        maxHeight: ["time_offs", "tasks"].includes(page) ? "590px" : "700px",
      }}
    >
      {openedPage}
    </div>
  );
};

export default SettingsRightSide;
