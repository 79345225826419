import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import { useBookingsStore, useCopyBookingsStore, usePlannerInfoModal } from "../../../../stores/planner";
import React, { useContext, useEffect, useState } from "react";
import Api from "../../../../Api";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { PlannerDialogGroup } from "./PlannerDialogGroup";
import { PlannerContext } from "../../context/PlannerContext";
import { shallow } from "zustand/shallow";

const PlannerInfoModal = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [discardedBookings, setDiscardedBookings] = useState([]);
  const { users } = useContext(PlannerContext);
  const [infoModalOpen] = usePlannerInfoModal((state) => [state.infoModalOpened], shallow);

  useEffect(() => {
    setModalOpen(infoModalOpen);
    setModalType(usePlannerInfoModal.getState().infoModalData[0]?.type);
  }, [infoModalOpen]);

  const closeInfoModal = () => {
    if (modalType === "not_enough_space" && discardedBookings.length > 0) {
      Api.Bookings.delete(
        discardedBookings.map((b) => b.bookingId),
        false
      )
        .then((response) => {
          const newBookings = useBookingsStore
            .getState()
            .bookings.filter((booking) => response.data.deleted_bookings.map((b) => b.id).indexOf(booking.id) === -1);

          useBookingsStore.getState().setBookings(newBookings);

          const dispatcher = discardedBookings[0].dispatcher;
          const indexes = discardedBookings[0].indexes;

          dispatcher.afterCreationBookingAssignment(indexes);
        })
        .finally(() => {
          goToNextInfoMessage();
        });
    } else {
      goToNextInfoMessage();
    }
  };

  const goToNextInfoMessage = () => {
    if (usePlannerInfoModal.getState().infoModalData.filter((data) => data.type !== modalType).length > 0) {
      usePlannerInfoModal
        .getState()
        .setInfoModalData(usePlannerInfoModal.getState().infoModalData.filter((data) => data.type !== modalType));
      setModalType(usePlannerInfoModal.getState().infoModalData.filter((data) => data.type !== modalType)[0]?.type);
    } else {
      setModalOpen(false);
      usePlannerInfoModal.getState().setInfoModalOpened(false);
      usePlannerInfoModal.getState().setInfoModalData([]);
      setModalType(null);
      setDiscardedBookings([]);
    }
  };

  const discardBooking = (bookingId, indexes, dispatcher) => {
    setDiscardedBookings([...discardedBookings, { bookingId, indexes, dispatcher }]);
  };

  const data = () => {
    return usePlannerInfoModal.getState().infoModalData.filter((data) => data.type === modalType);
  };

  const infoModalTitle = {
    skipped: "Some dates were skipped during creation",
    not_enough_space: "Overtime created!",
  };

  const groupByUser = () => {
    return Object.entries(
      data().reduce((acc, elem) => {
        if (acc[elem["userId"]]) {
          acc[elem["userId"]].push(elem);
        } else {
          acc[elem["userId"]] = [elem];
        }
        return acc;
      }, {})
    );
  };

  const dialogContents = {
    skipped: (
      <div>
        {groupByUser().map((group, index) => {
          return <PlannerDialogGroup group={group} />;
        })}
      </div>
    ),
    not_enough_space: (
      <div className="planner-info-modal__not-enough">
        {data().map((skippedBooking, index) => {
          const { userId } = skippedBooking;
          const user = users.find((u) => u.id === userId);

          return (
            <div className="planner-info-modal__not-enough-element">
              <p key={index} className="planner-info-modal__not-enough__text">
                <b>{user?.name}</b> - {skippedBooking?.message}
              </p>

              <TeambookBlueButton
                text={"Discard"}
                onClick={() =>
                  discardBooking(
                    skippedBooking?.bookingId,
                    skippedBooking?.indexesWithBookingsCreated,
                    skippedBooking?.plannerDispatcher
                  )
                }
                disabled={discardedBookings?.filter((b) => b.bookingId === skippedBooking?.bookingId).length > 0}
              />
            </div>
          );
        })}
      </div>
    ),
  };

  return (
    <Dialog open={modalOpen} className="tb-default-dialog planner-info-modal__dialog" onClose={closeInfoModal}>
      <DialogTitle>
        <p>{infoModalTitle[modalType]}</p> <TeambookIcon onClick={closeInfoModal} name={icons.CROSS} />
      </DialogTitle>

      <DialogContent>{dialogContents[modalType]}</DialogContent>

      <DialogActions>
        <TeambookBlueButton onClick={closeInfoModal} text={"OK"} />
      </DialogActions>
    </Dialog>
  );
};

export default PlannerInfoModal;
