import "./projection.scss";
import { ICON_COLOR, TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import PropTypes from "prop-types";

const ProjectionDayBookings = ({ bookings, isClientSharing, isLessToday, tasks }) => {
  return bookings.map((booking, i) => {
    const color = booking.project_color;
    const duration = booking.duration / 60;

    const bookingsStyles = {
      background: booking.tentative ? "var(--background-1)" : color + "B3",
      border: booking.tentative && `2px solid ${color}`,
      color: "var(--text-10)",
    };

    const taskName = tasks.find((task) => task.id === booking.task_id)?.name;

    const showProjectCode = isClientSharing && duration > 3.5;

    return (
      <div
        key={booking.id}
        className="projection__booking"
        style={{
          height: booking.duration / 6 - 1,
          borderTop: i > 0 && "1px solid #FFF",
          opacity: isLessToday && 0.7,
        }}
      >
        <div className="projection__booking-content" style={bookingsStyles}>
          {!booking.tentative && <div className="projection__booking-line" style={{ background: color }} />}

          <div className="projection__booking-title">
            {showProjectCode && <p className="projection__booking-code">{booking.project_code}</p>}
            <p className="projection__booking-time">{duration}h</p>
          </div>

          {booking.task_id && (
            <TeambookIcon
              className="projection__booking-task-icon"
              name={icons.TASKS}
              color={ICON_COLOR.DEFAULT}
              style={{ width: 16, height: 16, top: showProjectCode ? 14 : 1 }}
              tooltipTitle={taskName}
              placement="top"
            />
          )}
        </div>
      </div>
    );
  });
};

ProjectionDayBookings.propTypes = {
  bookings: PropTypes.array.isRequired,
  isClientSharing: PropTypes.bool.isRequired,
  isLessToday: PropTypes.bool.isRequired,
};

export default ProjectionDayBookings;
