import { NavLink, useLocation } from "react-router-dom";
import { ICON_COLOR, TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export const HeaderLink = ({ to, icon, text }) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsActive(location.pathname.includes(to));
  }, [location, to]);

  return (
    <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={to}>
      <div className="header-link">
        <TeambookIcon name={icon} size={24} />

        <p>{text}</p>
      </div>
    </NavLink>
  );
};
