import React, { useState } from "react";
import { DateTime } from "luxon";
import MuiDatePicker from "../../default_components/MuiDatePicker";

export default function RegistrationsStatistics({ teambookStatistics, sandboxAccounts }) {
  const [date, setDate] = useState(DateTime.now().startOf("month").minus({ month: 11 }));

  const COLUMN_WIDTH = "7%";
  const TABLE_ROWS = [
    { value: "total", name: "Total (sbx)" },
    { value: "en", name: "EN (sbx)" },
    { value: "fr", name: "FR (sbx)" },
    { value: "de", name: "DE (sbx)" },
    { value: "es", name: "ES (sbx)" },
    { value: "it", name: "IT (sbx)" },
    { value: "subscribed", name: "Subscribed" },
    { value: "churned", name: "Churned" },
    { value: "customers", name: "Customers Count" },
    { value: "conversion", name: "Conversion" },
  ];

  const summarizedMonthStats = (index) => {
    const startDate = date.plus({ month: index })
    const endDate = date.plus({ month: index + 1 }).minus({ day: 1 })

    const filteredStats = teambookStatistics.filter(
      (val) =>
        DateTime.fromISO(val.date) >= startDate &&
        DateTime.fromISO(val.date) <= endDate
    );

    const total = filteredStats.map((stats) => stats.registered_today).reduce((pr, cv) => pr + cv, 0);
    const subscribed = filteredStats.map((stats) => stats.subscribed_today_count).reduce((pr, cv) => pr + cv, 0);

    const sandboxRegistered = sandboxAccounts.filter(
      (val) =>
        DateTime.fromISO(val.created_at) >= startDate &&
        DateTime.fromISO(val.created_at) <= endDate
    )

    return {
      total: `${total} (${sandboxRegistered.length})`,
      en: `${filteredStats.map((stats) => stats.en_registered).reduce((pr, cv) => pr + cv, 0)} (${sandboxRegistered.filter((acc) => acc.language === "en").length})`,
      fr: `${filteredStats.map((stats) => stats.fr_registered).reduce((pr, cv) => pr + cv, 0)} (${sandboxRegistered.filter((acc) => acc.language === "fr").length})`,
      de: `${filteredStats.map((stats) => stats.de_registered).reduce((pr, cv) => pr + cv, 0)} (${sandboxRegistered.filter((acc) => acc.language === "de").length})`,
      es: `${filteredStats.map((stats) => stats.es_registered).reduce((pr, cv) => pr + cv, 0)} (${sandboxRegistered.filter((acc) => acc.language === "es").length})`,
      it: `${filteredStats.map((stats) => stats.it_registered).reduce((pr, cv) => pr + cv, 0)} (${sandboxRegistered.filter((acc) => acc.language === "it").length})`,
      subscribed: subscribed,
      churned: filteredStats.map((stats) => stats.churned).reduce((pr, cv) => pr + cv, 0),
      customers: filteredStats.map((stats) => stats.customers)[0],
      conversion: `${Math.round((subscribed / total) * 100 * 100) / 100}%`,
    };
  };

  const summarizedLineStats = () => {
    const filteredStats = teambookStatistics.filter(
      (val) => DateTime.fromISO(val.date) >= date && DateTime.fromISO(val.date) <= date.plus({ month: 12 })
    );

    const total = filteredStats.map((stats) => stats.registered_today).reduce((pr, cv) => pr + cv, 0);
    const subscribed = filteredStats.map((stats) => stats.subscribed_today_count).reduce((pr, cv) => pr + cv, 0);
    const sandboxRegistered = sandboxAccounts.filter(
      (val) => DateTime.fromISO(val.created_at) >= date && DateTime.fromISO(val.created_at) <= date.plus({ month: 12 })
    );

    return {
      total: `${total} (${sandboxRegistered.length})`,
      en: `${filteredStats.map((stats) => stats.en_registered).reduce((pr, cv) => pr + cv, 0)} (${sandboxRegistered.filter((acc) => acc.language === "en").length})`,
      fr: `${filteredStats.map((stats) => stats.fr_registered).reduce((pr, cv) => pr + cv, 0)} (${sandboxRegistered.filter((acc) => acc.language === "fr").length})`,
      de: `${filteredStats.map((stats) => stats.de_registered).reduce((pr, cv) => pr + cv, 0)} (${sandboxRegistered.filter((acc) => acc.language === "de").length})`,
      es: `${filteredStats.map((stats) => stats.es_registered).reduce((pr, cv) => pr + cv, 0)} (${sandboxRegistered.filter((acc) => acc.language === "es").length})`,
      it: `${filteredStats.map((stats) => stats.it_registered).reduce((pr, cv) => pr + cv, 0)} (${sandboxRegistered.filter((acc) => acc.language === "it").length})`,
      subscribed: subscribed,
      churned: filteredStats.map((stats) => stats.churned).reduce((pr, cv) => pr + cv, 0),
      customers: "-",
      conversion: `${Math.round((subscribed / total) * 100 * 100) / 100}%`,
    };
  };

  return (
    <>
      <MuiDatePicker
        format={"MMM, yyyy"}
        value={date}
        onChange={(date) => setDate(date.startOf("month"))}
        sx={{ width: "150px", input: { padding: "0px" } }}
        className="mui-date-picker"
        views={["month", "year"]}
      />

      <table
        style={{
          width: "100%",
          margin: "70px 5px",
          textAlign: "left",
        }}
        border="1px solid black"
      >
        <tr style={{ textAlign: "center" }}>
          <th style={{ width: COLUMN_WIDTH, padding: 10 }}>Month</th>

          {Array.apply(null, Array(12)).map((_val, index) => (
            <th style={{ width: COLUMN_WIDTH, padding: 10 }}>{date.plus({ month: index }).toFormat("MMM, yyyy")}</th>
          ))}

          <th style={{ width: COLUMN_WIDTH, padding: 10 }}>TOTAL 12 MONTH</th>
        </tr>

        {TABLE_ROWS.map((row) => (
          <tr style={{ textAlign: "center" }}>
            <th style={{ width: COLUMN_WIDTH, padding: 10 }}>{row.name}</th>

            {Array.apply(null, Array(12)).map((_val, index) => (
              <th style={{ width: COLUMN_WIDTH, padding: 10 }}>{summarizedMonthStats(index)[row.value]}</th>
            ))}

            <th style={{ width: COLUMN_WIDTH, padding: 10 }}>{summarizedLineStats()[row.value]}</th>
          </tr>
        ))}
      </table>
    </>
  );
}
