import HolidaysComponent from "./Holidays";
import PropTypes from "prop-types";

const TimeOffHolidaysSettings = ({ users }) => {
  const UserList = () => users.map((user) => (user = { ...user, value: user.id }));

  return (
    <div className="settings__time-off">
      <HolidaysComponent users={UserList()} />
    </div>
  );
};

TimeOffHolidaysSettings.propTypes = {
  users: PropTypes.array.isRequired,
};

export default TimeOffHolidaysSettings;
