import { useTranslation } from "react-i18next";
import TeambookScheduleComponent from "../../../default_components/schedule/TeambookScheduleComponent";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import { useTeambookDataStore } from "../../../../stores/users";
import { shallow } from "zustand/shallow";
import FormErrorMessage from "../../../default_components/FormErrorMessage";
import { NavLink } from "react-router-dom";
import TimeZones from "../../../default_values/TimeZones";
import PropTypes from "prop-types";

const ScheduleTabInfo = ({
  schedule,
  setSchedule,
  kind,
  holidays,
  selectedHoliday,
  setSelectedHoliday,
  customWorkingHours,
  setCustomWorkingHours,
  errorField,
  errorMessage,
  closeErrorMessage,
  timeZone,
  setTimeZone,
}) => {
  const { t } = useTranslation();
  const [user, setChosenUser, setTab, setUserFormState] = useTeambookDataStore(
    (state) => [state.chosenUser, state.setChosenUser, state.setTab, state.setUserFormState],
    shallow
  );

  const selectOptions =
    holidays?.map((holiday) => ({
      value: holiday.id,
      label: holiday.name,
    })) || [];

  const customWorkingHoursText = () => {
    if (user?.custom_working_hours) {
      return `${user.custom_working_hours}`;
    }

    return false;
  };

  selectOptions.push({ value: null, label: t("projects.none") });

  return (
    <div className="users__schedule-tab">
      <div className="users__schedule-tab__head-row">
        <div className="head-block">
          {kind === "show" ? (
            <>
              <p className="head-text">{t("dashboard.holidays")}</p>
              <p className="head-value">{selectedHoliday?.name || t("projects.none")}</p>
            </>
          ) : (
            <TeambookReactSelect
              fieldName={t("dashboard.holidays")}
              height={44}
              value={selectOptions.filter((opt) => opt.value === selectedHoliday)[0]}
              options={selectOptions}
              onChange={(value) => {
                setSelectedHoliday(value.value);
              }}
              width="210px"
              grayPlaceholder
            />
          )}
        </div>

        <div className="head-block" style={{ position: "relative" }}>
          {kind === "show" ? (
            <>
              <p className="head-text">{t("users.strict_working_hours")}</p>
              <div className="head-text__strict-working-hours">
                <p className="head-value">{customWorkingHoursText() || t("no")}</p>
                <p className="head-value">{customWorkingHoursText() && t("planning.monthly")}</p>
              </div>
            </>
          ) : (
            <div className="users__custom-working-hours__input">
              <TeambookTextForm
                fieldName={t("users.strict_working_hours")}
                className="user-form__field-input"
                fieldValue={customWorkingHours}
                onChange={(value) => {
                  if (value.length <= 6) {
                    setCustomWorkingHours(value);
                  }
                }}
                type={"number"}
                style={{ width: "80px", marginBottom: 0 }}
              />
              <p className="input-description">{t("planning.monthly")}</p>
            </div>
          )}
        </div>

        <div className="head-block">
          {kind === "show" ? (
            <>
              <p className="head-text">{t("users.time_zone")}</p>
              <p className="head-value">
                {TimeZones.timeZonesArray.find((zone) => zone.value === user.time_zone).value}
              </p>
            </>
          ) : (
            <TeambookReactSelect
              fieldName={t("users.time_zone")}
              height={44}
              value={TimeZones.timeZonesArray.find((time_zone) => time_zone.value === timeZone.value)}
              options={TimeZones.timeZonesArray}
              onChange={(time_zone) => setTimeZone(time_zone)}
              width="220px"
              grayPlaceholder
            />
          )}
        </div>
      </div>

      <div className="users-schedule__text-link">
        <p>{t("users.schedule_first")}</p>
        &nbsp;
        <NavLink
          to={{
            pathname: "/settings",
            search: "?page=schedule",
          }}
          onClick={() => {
            setChosenUser(undefined);
            setTab("general");
            setUserFormState(undefined);
          }}
        >
          <p className="users-schedule__link">{t("users.schedule_link")}</p>
        </NavLink>
        &nbsp;
        <p>{t("users.schedule_second")}</p>
      </div>

      <TeambookScheduleComponent schedule={schedule} setSchedule={setSchedule} kind={kind} />

      {errorMessage && errorField === "schedule" && (
        <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
      )}
    </div>
  );
};

ScheduleTabInfo.propTypes = {
  schedule: PropTypes.array.isRequired,
  setSchedule: PropTypes.func.isRequired,
  kind: PropTypes.string.isRequired,
  holidays: PropTypes.array,
  selectedHoliday: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.oneOf([null])]),
  setSelectedHoliday: PropTypes.func.isRequired,
  customWorkingHours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setCustomWorkingHours: PropTypes.func.isRequired,
  errorField: PropTypes.string,
  errorMessage: PropTypes.string,
  closeErrorMessage: PropTypes.func.isRequired,
  timeZone: PropTypes.shape({
    value: PropTypes.string,
  }),
  setTimeZone: PropTypes.func.isRequired,
};

ScheduleTabInfo.defaultProps = {
  holidays: [],
  selectedHoliday: null,
  customWorkingHours: "",
  errorField: "",
  errorMessage: "",
  timeZone: { value: "UTC" },
  setSchedule: () => {},
  setSelectedHoliday: () => {},
  setCustomWorkingHours: () => {},
  closeErrorMessage: () => {},
  setTimeZone: () => {},
};

export default ScheduleTabInfo;
