import React from "react";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const ActualsTooltip = React.forwardRef(() => {
  return (
    <div className="mobile_hidden tooltip-component" id="tooltip">
      <div className="tooltip-component__block">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <p className="tooltip-component__project-name" id="tooltip-projectName" />

          <p
            className="tooltip-component__booking-duration"
            id="tooltip-bookingDuration"
            style={{ fontWeight: "500" }}
          />
        </div>

        <div className="tooltip-component__task-name">
          <TeambookIcon className="tooltip-component__icon" name={icons.TASKS} />
          <p id="tooltip-occupation" />
        </div>
        <div className="tooltip-component__team">
          <TeambookIcon className="tooltip-component__icon" name={icons.PAYROLL} />
          <p id="tooltip-activity" />
        </div>
        <div className="tooltip-component__comment">
          <TeambookIcon className="tooltip-component__icon" name={icons.PENCIL} />
          <p id="tooltip-comment" />
        </div>
      </div>

      <div className="tooltip-component__triangle" id="tooltip-triangle" />
      <div className="tooltip-component__triangle_bg" id="tooltip-triangle" />
      <div className="tooltip-component__stub" />
    </div>
  );
});

ActualsTooltip.displayName = "ActualsTooltip";

export default ActualsTooltip;
