import { useState } from "react";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import ChangeProfilePhoto from "../ChangeProfilePhoto";
import { LanguagePicker } from "../../../default_components/LanguagePicker";
import getStringrole from "../../../../lib/GetStringRole";
import CalendarInegrationDialog from "../../../default_components/teambook_calendar_integrations/CalendarInegrationDialog";
import { icons } from "../../../default_images/IconsList";
import { useProfileStore } from "../../../../stores/profileStore";
import { shallow } from "zustand/shallow";
import TeambookIconButton from "../../../default_components/TeambookIconButton";
import PropTypes from "prop-types";

const ShowProfile = ({ setEditing }) => {
  const { t } = useTranslation();
  const [calendarIntegrationOpen, setCalendarIntegrationOpen] = useState(false);
  const [user] = useProfileStore((state) => [state.profile], shallow);

  return (
    <div className="show-profile__container">
      <div className="show-profile__block" style={{ minWidth: "100%", position: "relative", marginBottom: 30 }}>
        <ChangeProfilePhoto bgStyle={{ margin: "0 auto", right: 0 }} user={user} showEdit={false} />
      </div>
      <div className="show-profile__block">
        <div className="show-profile__value" id="profile-first-name">
          <p className="name">{t("profile.general_info.first_name")}</p>
          <p className="value">{user.first_name}</p>
        </div>

        <div className="show-profile__value" id="profile-time-zone">
          <p className="name">{t("profile.general_info.default_time_zone")}</p>
          <p className="value">{user.time_zone || "-"}</p>
        </div>

        <div className="show-profile__value">
          <p className="name">{t("users.authorization")}</p>
          <p className="value"> {getStringrole(user)} </p>
        </div>
      </div>
      <div className="show-profile__block">
        <div className="show-profile__value" id="profile-last-name">
          <p className="name">{t("profile.general_info.last_name")}</p>
          <p className="value">{user.last_name}</p>
        </div>

        <div className="show-profile__value" style={{ textAlign: "left" }} id="profile-language">
          <p className="name">{t("profile.language.name")}</p>
          <LanguagePicker type="text" showCurrent={true} currentLang={user.language} />
        </div>
      </div>
      <CalendarInegrationDialog open={calendarIntegrationOpen} setOpen={setCalendarIntegrationOpen} user={user} />

      <WhiteTooltip title={t("edit")} placement="right">
        <div
          onClick={() => setEditing(true)}
          style={{
            position: "absolute",
            right: 39,
          }}
        >
          <TeambookIconButton name={icons.PENCIL} id="update" isGray />
        </div>
      </WhiteTooltip>

      <WhiteTooltip title={t("my_week.calendar_integration")} placement="right">
        <div
          onClick={() => setCalendarIntegrationOpen(true)}
          style={{
            position: "absolute",
            right: 39,
            top: 94,
            margin: "0 auto",
          }}
        >
          <TeambookIconButton name={icons.CALENDAR} id="update" isGray />
        </div>
      </WhiteTooltip>
    </div>
  );
};

ShowProfile.propTypes = {
  setEditing: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default ShowProfile;
