import { approvedThisMonth, loggedThisMonth } from "../lib/calculateLogData";
import PropTypes from "prop-types";

const Project = ({ project, selectedUser, setSelectedUser, allUserLogs, date }) => {
  return (
    <div
      key={project.id}
      className={`column-user__form ${project.id === selectedUser.id ? " column-user__form_selected" : ""}`}
      onClick={() => setSelectedUser(project)}
    >
      <div
        style={{
          width: 24,
          height: 24,
          background: project.color,
          borderRadius: "4px",
        }}
      />
      <div className="column-user__user-info">
        <p>{project.name}</p>
      </div>
      <div className="column-user__user-approval-hours">
        <p title="Total Logged">{loggedThisMonth(allUserLogs, date) || 0} h /&nbsp;</p>

        <p title="Total Approved">{approvedThisMonth(allUserLogs, date) || 0} h</p>
      </div>
    </div>
  );
};

Project.propTypes = {
  project: PropTypes.object.isRequired,
  selectedUser: PropTypes.object.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  allUserLogs: PropTypes.array.isRequired,
  date: PropTypes.object.isRequired,
}

export default Project;
