import { DialogTitle, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import TeambookBlueButton from "../default_components/TeambookBlueButton";
import DangerPic from "../default_images/danger_deletion.svg";
import TeambookOutlinedButton from "./TeambookOutlinedButton";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import PropTypes from "prop-types";

const TeambookDialogDangerAction = ({
  open,
  setOpen,
  contentText,
  buttonText,
  actionRequest,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog className="delete-dialog" open={open} onClose={() => setOpen(false)}>
      <DialogTitle className="delete-dialog__title">
        <img src={DangerPic} alt={"danger"} />
        <TeambookIcon
          style={{ position: "absolute", right: 40, top: 0, cursor: "pointer" }}
          name={icons.CROSS}
          onClick={() => setOpen(false)}
        />
      </DialogTitle>
      <DialogContent style={{ padding: "0px 40px 20px 40px" }} className="delete-dialog__content">
        <div style={{ marginBottom: "0px" }}>{contentText}</div>
      </DialogContent>
      <DialogActions style={{ padding: "0px 40px 30px 40px", gap: 20 }}>
        <TeambookBlueButton
          text={buttonText || t("delete")}
          onClick={() => {
            actionRequest();
            setOpen(false);
          }}
        />
        <TeambookOutlinedButton text={t("cancel")} onClick={() => setOpen(false)} />
      </DialogActions>
    </Dialog>
  );
}

TeambookDialogDangerAction.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  contentText: PropTypes.string,
  buttonText: PropTypes.string,
  actionRequest: PropTypes.func,
}

export default TeambookDialogDangerAction;
