import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TeambookTextForm from "../../default_components/TeambookTextForm";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import Api from "../../../Api";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const TwoFactorModal = ({ open, setOpen, email, redirectToMainPage }) => {
  const [code, setCode] = useState(null);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);

  const analyzeResponse = (response) => {
    if (response.status === 201) {
      const token = response.data.token;
      const language = response.data.lang;

      localStorage.setItem("Auth-Token", token);
      localStorage.setItem("tb-lang", language);

      Api.updateParamTokens(token);

      redirectToMainPage();
    } else {
      setErrorMessage("error");
    }
  };

  const submitForm = () => {
    Api.Auth.validate_two_factor(email, code)
      .then(analyzeResponse)
      .catch((error) => {
        setErrorMessage(error.response.data.error);
        setErrorField("code");
      });
  };

  const sendAgain = () => {
    Api.Auth.send_two_factor(email).then(() => {
      setCode("");
    });
  };

  const closeModal = () => {
    setOpen(false);
    setErrorField(null);
    setErrorMessage(null);
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
    setErrorField(null);
  };

  return (
    <Dialog className="tb-default-dialog" open={open} onClose={closeModal}>
      <DialogTitle>
        <p>Two-Step Verification</p>

        <TeambookIcon name={icons.CROSS} onClick={() => setOpen(false)} />
      </DialogTitle>
      <DialogContent style={{ margin: "20px 0px" }} className="login-dialog-content">
        <p>
          {t("auth.two_factor_account_request")}
          <br />
          {t("auth.two_factor_check")}
        </p>

        <TeambookTextForm
          width={"120px"}
          fieldName={"Code"}
          onChange={setCode}
          fieldValue={code}
          style={{ marginTop: "15px" }}
          errorField={errorField}
          errorMessage={errorMessage}
          field={"code"}
          inputBottomOffset={"15px"}
          closeErrorMessage={closeErrorMessage}
        />

        <a
          onClick={sendAgain}
          href={"#"}
          style={{
            fontSize: 14,
            textDecoration: "underline",
          }}
        >
          {t("auth.send_again")}
        </a>
      </DialogContent>
      <DialogActions>
        <TeambookBlueButton onClick={submitForm} text={t("submit")} />
      </DialogActions>
    </Dialog>
  );
};

export default TwoFactorModal;
