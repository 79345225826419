import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Api from "../../../../Api";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import GetAllMilestones from "../../requests/GetAllMilestones";
import moment from "moment";
import { DialogTitle } from "@mui/material";
import { useMilestonesStore } from "../../../../stores/planner";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import TeambookDatePicker from "../../../default_components/teambook_date_selector/DatePicker";
import PropTypes from "prop-types";

const MilestoneCreation = ({ project, dialogOpened, closeDialog, setMilestones, milestoneCreationRef }) => {
  const { t } = useTranslation();

  const { dateStart, dateEnd, setDateStart: setRefDateStart, setDateEnd: setRefDateEnd } = milestoneCreationRef;

  useEffect(() => {
    setStartDate(dateStart);
    setEndDate(dateEnd);
  }, [dateStart, dateEnd]);

  const { creationOrder: order } = useMilestonesStore();

  const [milestoneName, setMilestoneName] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const [startDate, setStartDate] = useState(dateStart);
  const [endDate, setEndDate] = useState(dateEnd);

  const isCorrectDates = startDate < endDate?.plus({ days: 1 });

  const changeStartDate = (date) => {
    setStartDate(date);
    setRefDateStart(date);
  };

  const changeEndDate = (date) => {
    setEndDate(date);
    setRefDateEnd(date);
  };

  moment.locale(t(localStorage.getItem("tb-lang")));

  const createMilestone = () => {
    if (isCorrectDates) {
      setErrorMessage(null);
      setErrorField(null);

      if (milestoneName.length < 3 || milestoneName.length > 30) {
        setErrorMessage("Milestone name must be 3 to 30 characters long.");
        setErrorField("name");
      } else {
        Api.Milestones.create({
          name: milestoneName,
          project_id: project.id,
          start_date: startDate,
          end_date: endDate,
          order: order,
        })
          .then(() => {
            GetAllMilestones(setMilestones).then(() => {
              onClose();
            });
          })
          .catch((error) => {
            setErrorMessage(error.response.data.error);
          });
      }
    }
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
    setErrorField(null);
  };

  const onClose = () => {
    closeDialog();
    setMilestoneName("");
    setErrorMessage(null);
    setErrorField(null);
    setStartDate(null);
    setEndDate(null);
    setRefDateStart(null);
    setRefDateEnd(null);
  };

  return (
    <Dialog
      open={dialogOpened}
      onClose={onClose}
      aria-labelledby="deactivation-dialog"
      fullWidth={true}
      sx={{
        "& .MuiDialog-paper": {
          width: 490,
          height: 294,
        },
      }}
      className="tb-default-dialog milestone-modal"
    >
      <DialogTitle id="add-user-dialog">
        <p>{t("planning.milestones.create")}</p>
        <TeambookIcon name={icons.CROSS} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <div className="milestone-creating__creating">
          <TeambookTextForm
            id={"milestoneName"}
            fieldName={t("planning.milestones.name")}
            onChange={(text) => setMilestoneName(text)}
            type={"text"}
            fieldValue={milestoneName}
            width={"78%"}
            placeholder={"Milestone"}
            errorField={errorField}
            errorMessage={errorMessage}
            closeErrorMessage={closeErrorMessage}
            field={"name"}
          />
        </div>

        <div className="milestone-creating__text-row">
          <div className="milestone-date-picker__container">
            <p>{t("projects.start_date")}</p>

            <TeambookDatePicker
              onDayClick={(date) => changeStartDate(date)}
              from={startDate}
              range={1}
              type="default"
              variant="inline"
              formatStart={"MMM d, yyyy"}
              width={86}
              height={24}
            />
          </div>

          <div className="milestone-date-picker__container">
            <p>{t("projects.end_date")}</p>

            <TeambookDatePicker
              onDayClick={(date) => changeEndDate(date)}
              from={endDate}
              range={1}
              type="default"
              variant="inline"
              formatStart={"MMM d, yyyy"}
              width={86}
              height={24}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions className="milestone-creating__actions">
        <TeambookBlueButton
          id="createMilestoneButton"
          text={t("create")}
          disabled={!isCorrectDates}
          onClick={() => createMilestone()}
        />
      </DialogActions>
    </Dialog>
  );
};

MilestoneCreation.propTypes = {
  project: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.object.isRequired,
  setEndDate: PropTypes.func.isRequired,
  dialogOpened: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  setMilestones: PropTypes.func.isRequired,
  milestoneCreationRef: PropTypes.object.isRequired,
};

export default MilestoneCreation;
