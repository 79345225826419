import { useTranslation } from "react-i18next";
import { MonthsOfTheYear } from "../../../../../lib/DateUtils";
import PropTypes from "prop-types";

const MonthRow = ({ date, zoom, days }) => {
  const { t } = useTranslation();

  const calculateMonths = () => {
    if (zoom === "week") {
      if (date.plus({ weeks: 1 }).month !== date.month) {
        const firstMonthWidth = ((date.endOf("month").day - date.day) / 7) * 100;

        return [
          { month: MonthsOfTheYear(t)[date.month - 1], span: firstMonthWidth },
          { month: MonthsOfTheYear(t)[date.plus({ weeks: 1 }).month - 1], span: 100 - firstMonthWidth },
        ];
      }
    }

    return [{ month: MonthsOfTheYear(t)[date.month - 1], span: 100 }];
  };

  return (
    <div className="actuals__by-project__month-row">
      <div className="actuals__by-project__month-row__spacer" />

      <div className="actuals__by-project__month-row__months">
        {Array.apply(null, Array(days)).map((_, index) => (
          <div key={`day-${index}`} className="actuals__by-project__days-row__day">
            {index === 0 && <p className="actuals__by-project__month-row__month">{calculateMonths()[0].month}</p>}
            {index === 6 && <p className="actuals__by-project__month-row__month">{calculateMonths()[1]?.month}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

MonthRow.propTypes = {
  date: PropTypes.shape({
    plus: PropTypes.func.isRequired,
    month: PropTypes.number.isRequired,
    endOf: PropTypes.func.isRequired,
    day: PropTypes.number.isRequired,
  }).isRequired,
  zoom: PropTypes.string.isRequired,
  days: PropTypes.number.isRequired,
};

export default MonthRow;
