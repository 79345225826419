import { useEffect, useRef, useState } from "react";
import "../../../default_styles/tb-colors.css";
import "./new_user.css";
import Api from "../../../../Api";
import { useTranslation } from "react-i18next";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import UsersData from "../lib/UsersData";
import { useDataChange } from "../../../../lib/useDataChange";
import WarningModal from "../../../default_components/WarningModal";
import { icons } from "../../../default_images/IconsList";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { useAccountStore } from "../../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { useProfileStore } from "../../../../stores/profileStore";
import { useNotificationStore } from "../../../../stores/notificationStore";
import { useTeambookDataStore } from "../../../../stores/users";
import PropTypes from "prop-types";

const userBillability = (t) => [
  { value: true, name: t("users.billable") },
  { value: false, name: t("users.non_billable") },
];

const calculateUserTeams = (teams) => {
  if (teams.length === 1) {
    return [teams[0]];
  } else {
    return [];
  }
};

const defaultNotificationSettings = {
  operational_setting: "off",
  capacity_setting: "off",
  capacity_day: 5,
  actual_setting: "off",
  actual_day: 5,
};

const roleOptions = (t, currentUser) => {
  const roles = [
    { value: "regular", name: t("users.regular") },
    { value: "contractor", name: t("users.contractor") },
    { value: "self_planner", name: t("users.self_planner") },
    { value: "planner", name: t("users.planner") },
  ];

  if (currentUser.role === "admin") {
    roles.push({ value: "admin", name: t("users.admin") });
  }

  return roles;
};

const NewUser = ({ analyzeAllUsersResponse, setDisplayingActiveUsers }) => {
  const { t } = useTranslation();
  const [account] = useAccountStore((state) => [state.account], shallow);
  const [currentUser] = useProfileStore((state) => [state.profile], shallow);
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [setDepartments, teams, tab, setTab, userFormState, setUserFormState] = useTeambookDataStore(
    (state) => [
      state.setDepartments,
      state.teams,
      state.tab,
      state.setTab,
      state.userFormState,
      state.setUserFormState,
    ],
    shallow
  );

  const [uploadPhotoProps, setUploadPhotoProps] = useState({
    croppedImage: undefined,
    analyzeUpdateResponse: () => {},
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [billable, setBillable] = useState(userBillability(t)[0]);
  const [role, setRole] = useState(roleOptions(t, currentUser)[0]);
  const [timeZone, setTimeZone] = useState("");
  const [tagName, setTagName] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const [schedule, setSchedule] = useState(JSON.parse(JSON.stringify(account.schedule)));
  const [userTeams, setUserTeams] = useState(calculateUserTeams(teams));
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [notificationSetting, setNotificationSetting] = useState(defaultNotificationSettings);
  const [warningOpened, setWarningOpened] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [selectedHoliday, setSelectedHoliday] = useState();
  const editorRef = useRef();
  const [createdUser, setCreatedUser] = useState(null);
  const [customWorkingHours, setCustomWorkingHours] = useState(null);
  const [departmentId, setDepartmentId] = useState({ value: null, name: "None" });

  useEffect(() => {
    Api.Integrations.Holidays.get().then((res) => {
      setHolidays(res.data);

      if (res.data.length > 0) {
        setSelectedHoliday(res.data[0].id);
      }
    });
  }, []);

  const isChanged = useDataChange(
    {
      first_name: firstName,
      last_name: lastName,
      role: role.value,
      email: email,
      billable: billable.value,
      start_date: startDate,
      end_date: endDate,
      notification_setting: notificationSetting,
      team_ids: userTeams.filter((t) => t).map((team) => team.id),
      tags: tagName.map((tag) => tag.id),
      schedule: schedule,
    },
    {
      first_name: "",
      last_name: "",
      role: roleOptions(t, currentUser)[0].value,
      email: "",
      billable: userBillability(t)[0].value,
      start_date: null,
      end_date: null,
      notification_setting: defaultNotificationSettings,
      team_ids: calculateUserTeams(teams).map((team) => team.id),
      tags: [],
      schedule: account.schedule,
    }
  );

  const submitForm = () => {
    closeErrorMessage();

    if (startDate && endDate && startDate > endDate) {
      setErrorMessage(t("actuals.date_error"));
      setErrorField("userEndDate");
      return;
    }

    if (!userTeams.length) {
      setErrorMessage(t("users.noteams"));
      setErrorField("teams");
      closeWarningModal();
    } else {
      Api.Users.create({
        first_name: firstName === "" ? null : firstName,
        last_name: lastName === "" ? null : lastName,
        email: email,
        billable: billable.value,
        role: role.value,
        time_zone: timeZone?.value || null,
        tags: tagName.map(({ id }) => id),
        team_ids: userTeams.map(({ id }) => id),
        schedule: schedule,
        start_date: startDate,
        end_date: endDate,
        notifications: notificationSetting,
        holiday: selectedHoliday,
        notes: editorRef?.current?.getComment() === "<p></p>" ? null : editorRef?.current?.getComment(),
        custom_working_hours: customWorkingHours,
        department_id: departmentId.value,
      })
        .then(async (response) => {
          if (uploadPhotoProps.croppedImage) {
            const formData = new FormData();
            formData.append("token", localStorage.getItem("Auth-Token"));
            formData.append("image", uploadPhotoProps.croppedImage["croppedImage"]);
            await Api.Users.update_photo(formData, response.data.id).then(uploadPhotoProps.analyzeUpdateResponse);
          }

          await analyzeResponse(response);
        })
        .catch(analyzeNewUserError);
    }
  };

  const analyzeResponse = async (response) => {
    closeCreation(false);
    setCreatedUser(response.data);

    Api.Departments.all().then((response) => {
      setDepartments(response.data);
    });

    Api.Users.all().then((response) => {
      analyzeAllUsersResponse(response);
      setDisplayingActiveUsers(true);
    });

    setInAppNotification(t("users.created_successfully", { name: `${firstName} ${lastName}` }));
  };

  const analyzeNewUserError = (error) => {
    setErrorMessage(error.response.data.error.message);
    setErrorField(error.response.data.error.field);

    closeWarningModal();
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
    setErrorField(null);
  };

  const closeCreation = (handleChanges = true) => {
    if (isChanged && handleChanges) {
      setWarningOpened(true);
    } else {
      setUserFormState("");
      setTab("general");
    }
  };

  const closeWarningModal = () => {
    setWarningOpened(false);
  };

  return (
    <Dialog
      onClose={closeCreation}
      open={userFormState === "create"}
      className="form-create"
      classes={{ paper: "user-form-dialog-paper" }}
    >
      <DialogTitle>
        <p>{t("users.new_user")}</p>

        <TeambookIcon className="form_close" name={icons.CROSS} onClick={closeCreation} />
      </DialogTitle>

      <DialogContent style={{ overflow: "unset" }}>
        <UsersData
          user={createdUser}
          viewType={"create"}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          billable={billable}
          setBillable={setBillable}
          role={role}
          setRole={setRole}
          timeZone={timeZone}
          setTimeZone={setTimeZone}
          tagName={tagName}
          setTagName={setTagName}
          userTeams={userTeams}
          setUserTeams={setUserTeams}
          closeErrorMessage={closeErrorMessage}
          errorField={errorField}
          errorMessage={errorMessage}
          roleOptions={roleOptions(t, currentUser)}
          userBillability={userBillability(t)}
          schedule={schedule}
          setSchedule={setSchedule}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          tab={tab}
          setTab={setTab}
          notificationSetting={notificationSetting}
          setNotificationSetting={setNotificationSetting}
          holidays={holidays}
          selectedHoliday={selectedHoliday}
          setSelectedHoliday={setSelectedHoliday}
          setNotes={editorRef}
          setUploadPhotoProps={setUploadPhotoProps}
          customWorkingHours={customWorkingHours}
          setCustomWorkingHours={setCustomWorkingHours}
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
        />
      </DialogContent>

      <DialogActions style={{ padding: "0px 40px 40px 40px" }}>
        <TeambookBlueButton
          text={t("create")}
          id="createUser"
          onClick={submitForm}
          className="user-form__edit-button"
        />
        <TeambookOutlinedButton onClick={closeCreation} className="new-user__close" text={t("cancel")} />
      </DialogActions>

      {warningOpened && (
        <WarningModal
          onNo={() => {
            setUserFormState("");
            setTab("general");
          }}
          onYes={() => {
            submitForm();
          }}
          closeModal={closeWarningModal}
        />
      )}
    </Dialog>
  );
};

NewUser.propTypes = {
  analyzeAllUsersResponse: PropTypes.func,
  setDisplayingActiveUsers: PropTypes.func,
};

export default NewUser;
