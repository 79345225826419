import { useEffect, useState } from "react";
import { getWeekNumber, OffsetDays } from "../../../../../lib/DateUtils";
import { useTranslation } from "react-i18next";
import { icons } from "../../../../default_images/IconsList";
import lsKeys from "../../../../default_values/defaultKeys";
import { DateTime } from "luxon";
import TeambookIconButton from "../../../../default_components/TeambookIconButton";
import PropTypes from "prop-types";

const WeekRow = ({ stats, sortOrder, setSortOrder }) => {
  const [weekColumns, setWeekColumns] = useState([]);
  const { t } = useTranslation();
  const [monthColumns, setMonthColumns] = useState([]);
  const lang = localStorage.getItem(lsKeys.LANGUAGE);

  useEffect(() => {
    let columns = [];
    let months = [];
    let startDate = new Date(stats.planningData.start_date);
    let currentDate = startDate;

    columns.push(
      <td
        className="compare-reporting__table-column"
        style={{
          height: 44,
          width: 90,
          minWidth: 90,
          maxWidth: 90,
        }}
      />
    );

    for (let i = 0; i < stats.planningData.amount_of_days; i++) {
      if (currentDate.getDate() === 10 || currentDate.getDate() === 25) {
        months.push(
          <td className="compare-reporting__table-column" style={{ height: 44, borderTop: "unset" }}>
            <p className="compare-reporting__week-text">{DateTime.fromJSDate(currentDate).setLocale(lang).monthLong}</p>
          </td>
        );
      } else {
        months.push(<td className="compare-reporting__table-column" style={{ borderTop: "unset" }}></td>);
      }

      if (currentDate.getDay() === 4) {
        columns.push(
          <td className="compare-reporting__table-column" style={{ height: 44 }}>
            <p className="compare-reporting__week-text">
              {t("dashboard.week")}
              {` ${getWeekNumber(currentDate)}`}
            </p>
          </td>
        );
      } else if (currentDate.getDay() === 0) {
        columns.push(
          <td className="compare-reporting__table-column" style={{ borderRight: "1px solid var(--stroke)" }} />
        );
      } else {
        columns.push(<td className="compare-reporting__table-column" />);
      }

      currentDate = OffsetDays(currentDate, 1);
    }

    columns.push(
      <td className="compare-reporting__totals-column">
        <p className="totals-div__fixed-text">{t("dashboard.totals")}</p>
      </td>
    );

    months.push(<td className="compare-reporting__totals-column" style={{ border: "unset" }}></td>);
    months.push(<td className="compare-reporting__totals-column" style={{ border: "unset" }}></td>);

    setMonthColumns(months);
    setWeekColumns(columns);
  }, [stats]);

  return (
    <>
      <tr
        className="compare-reporting__week-row"
        style={{
          height: 60,
          boxShadow: "0px 1px 0px 0px var(--stroke)",
        }}
      >
        <td className="compare-reporting__fixed-column" style={{ borderTop: "unset" }}>
          <div className="reporting__sort">
            <TeambookIconButton
              name={sortOrder === "a" ? icons.SORT_A : icons.SORT_Z}
              tooltipTitle={sortOrder === "a" ? t("dashboard.a_z") : t("dashboard.z_a")}
              onClick={() => setSortOrder(sortOrder === "a" ? "z" : "a")}
            />
          </div>
        </td>
        {monthColumns}
      </tr>

      <tr className="compare-reporting__week-row" style={{ top: 60 }}>
        <td className="compare-reporting__fixed-column" style={{ borderLeft: "1px solid var(--stroke" }} />

        {weekColumns}
      </tr>
    </>
  );
};

WeekRow.propTypes = {
  stats: PropTypes.object.isRequired,
  sortOrder: PropTypes.string.isRequired,
  setSortOrder: PropTypes.func.isRequired,
};

export default WeekRow;
