import { TeambookTeamsPicker } from "../../../default_components/TeambookDefaultPickers";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";
import { TeambookCheckbox } from "../../../default_components/TeambookCheckbox";
import RichTextEditor from "../../../default_components/rich_text_editor/RichTextEditor";
import "../../../organization_settings/settings.scss";
import { TeambookAuthorizationPicker } from "../../../default_components/TeambookAuthorizationPicker";
import Api from "../../../../Api";
import { useTeambookDataStore } from "../../../../stores/users";
import { shallow } from "zustand/shallow";
import TeambookDatePicker from "../../../default_components/teambook_date_selector/DatePicker";
import { DateTime } from "luxon";
import { components } from "react-select";
import PropTypes from "prop-types";

const phoneRegex = /^[+0-9][0-9]*$/;

const EditOrCreateFields = (
  name,
  value,
  setValue,
  type,
  id,
  options,
  teams,
  closeErrorMessage,
  startDate,
  defineDates,
  setDefineDates,
  placeholder,
  isDisabled,
  errorMessage,
  errorField
) => {
  const [setDepartments, departments, tags, setTags, allTags] = useTeambookDataStore(
    (state) => [state.setDepartments, state.departments, state.tags, state.setTags, state.tags],
    shallow
  );

  if (type === "teams") {
    return (
      <TeambookTeamsPicker
        width={260}
        value={value}
        onChange={(team) => {
          setValue(team);
          closeErrorMessage();
        }}
        teams={teams}
        type="multiple"
        closeMenuOnSelect={false}
        isSearchable={false}
        grayPlaceholder
        fieldName={name}
        errorMessage={errorMessage}
        errorField={errorField}
        field={id}
        closeErrorMessage={closeErrorMessage}
      />
    );
  } else if (type === "text") {
    return (
      <TeambookTextForm
        id={id}
        width={260}
        className="user-form__field-input"
        fieldValue={value}
        placeholder={placeholder}
        onChange={(text) => setValue(text.length > 0 ? (text[0]?.toUpperCase() || "") + text.slice(1) : "")}
        errorMessage={errorMessage}
        errorField={errorField}
        field={id}
        fieldName={name}
        closeErrorMessage={closeErrorMessage}
      />
    );
  } else if (type === "email") {
    return (
      <TeambookTextForm
        id={id}
        width={260}
        className="user-form__field-input"
        fieldValue={value}
        onChange={setValue}
        errorMessage={errorMessage}
        errorField={errorField}
        field={id}
        fieldName={name}
        closeErrorMessage={closeErrorMessage}
      />
    );
  } else if (type === "array") {
    return (
      <>
        <p className="user-form__field">{name}</p>

        <p className="user-form__value">
          <TeambookReactSelect
            width={260}
            value={value}
            onChange={(option) => setValue(option)}
            options={options}
            grayPlaceholder
          />
        </p>
      </>
    );
  } else if (type === "departments") {
    return (
      <>
        <p className="user-form__field">{name}</p>

        <p className="user-form__value">
          <TeambookReactSelect
            width={260}
            value={value}
            onChange={(option) => setValue(option)}
            options={options}
            grayPlaceholder
            onCreateOption={(option) => {
              Api.Departments.create(
                option,
                "#" +
                  Math.floor(Math.random() * 16777215)
                    .toString(16)
                    .toUpperCase()
              ).then((response) => {
                setDepartments([...departments, response.data.department]);
                setValue({
                  value: response.data.department.id,
                  name: response.data.department.name,
                  color: response.data.department.color,
                });
              });
            }}
            formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
            isSearchable={true}
            styles={{
              control: (base) => ({
                ...base,
                alignItems: "center",
                height: "40px",
                backgroundColor: "var(--background-1)",
              }),
              valueContainer: (base) => ({
                ...base,
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }),
              placeholder: (base) => ({
                ...base,
                color: "var(--text-2)",
                backgroundColor: "red",
              }),
            }}
            newComponents={{
              SingleValue: ({ data }) => {
                const department = departments.find((d) => d.id === data.value);

                return (
                  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <div
                      style={{
                        width: "5px",
                        height: "15px",
                        borderRadius: "2px",
                        backgroundColor: department ? department.color : "",
                      }}
                    />
                    <span>{data.name}</span>
                  </div>
                );
              },
              Option: ({ data, ...props }) => {
                if (data.__isNew__) {
                  return (
                    <components.Option {...props}>
                      <div>{data.label}</div>
                    </components.Option>
                  );
                }

                const department = departments.find((d) => d.id === data.value);
                return (
                  <components.Option {...props}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                      <div
                        style={{
                          width: "5px",
                          height: "15px",
                          borderRadius: "2px",
                          marginLeft: "5px",
                          backgroundColor: department ? department.color : "",
                        }}
                      />
                      <span>{data.name}</span>
                    </div>
                  </components.Option>
                );
              },
            }}
            isValidNewOption={(option) => option.length >= 3 && options.filter((o) => o.name === option).length === 0}
          />
        </p>
      </>
    );
  } else if (type === "role") {
    return (
      <>
        <p className="user-form__field">{name}</p>

        <p className="user-form__value">
          <TeambookAuthorizationPicker value={value} setValue={setValue} options={options} isDisabled={isDisabled} />
        </p>
      </>
    );
  } else if (type === "phone") {
    return (
      <>
        <p className="user-form__field">{name}</p>

        <TeambookTextForm
          id={id}
          width={260}
          className={"user-form__field-input"}
          fieldValue={value}
          onChange={(text) => (text.length > 0 ? (phoneRegex.test(text) ? setValue(text) : false) : setValue(text))}
        />
      </>
    );
  } else if (type === "tags") {
    return (
      <>
        <div className="user-form__tags-select">
          <p className="user-form__field">{name}</p>

          <TeambookReactSelect
            options={tags}
            width={260}
            isMulti
            value={value}
            onChange={(tag) => setValue(tag)}
            isSearchable={true}
            onCreateOption={(option) => {
              Api.Tags.create(option, ("#" + Math.floor(Math.random() * 16777215).toString(16)).toUpperCase()).then(
                (response) => {
                  setTags([...allTags, response.data]);
                  setValue([...value, response.data]);
                }
              );
            }}
            formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
            isValidNewOption={(option) => option.length >= 2}
            closeMenuOnSelect={false}
            type="multiple"
            styles={{
              multiValue: (base, state) => {
                return {
                  ...base,
                  backgroundColor: state.data.color + "66",
                  border: "2px solid " + state.data.color,
                  color: "var(--text-10) !important",
                };
              },
              multiValueLabel: (base) => ({
                ...base,
                color: "var(--text-10) !important",
              }),
            }}
            grayPlaceholder
          />
        </div>
      </>
    );
  } else if (type === "date") {
    let isDateDefined = false;

    if (id === "userEndDate") {
      isDateDefined = !!defineDates.endDate;
    } else {
      isDateDefined = !!defineDates.startDate;
    }

    return (
      <div className="form__date-field">
        <TeambookCheckbox
          checked={isDateDefined}
          onChange={() => {
            setDefineDates((pr) => {
              const updatedDates = { ...pr, [id === "userEndDate" ? "endDate" : "startDate"]: !isDateDefined };

              if (!isDateDefined && !value) {
                setValue(DateTime.now().toISO());
              }
              if (isDateDefined) {
                setValue(null);
              }
              return updatedDates;
            });
          }}
        />

        <p
          onClick={() => {
            setDefineDates((pr) => {
              const updatedDates = { ...pr, [id === "userEndDate" ? "endDate" : "startDate"]: !isDateDefined };

              if (!isDateDefined && !value) {
                setValue(DateTime.now().toISO());
              }
              if (isDateDefined) {
                setValue(null);
              }
              return updatedDates;
            });
          }}
          style={{ marginLeft: 10 }}
          className="user-form__field"
        >
          {name}
        </p>

        {isDateDefined && (
          <div style={{ marginLeft: 15 }}>
            <TeambookDatePicker
              onDayClick={(date) => setValue(date.toISO())}
              from={value ? DateTime.fromISO(value) : DateTime.now()}
              range={1}
              type="default"
              variant="inline"
              formatStart={"d MMM, yyyy"}
              width={86}
              height={24}
              errorMessage={errorMessage}
              errorField={errorField}
              name={id}
            />
          </div>
        )}
      </div>
    );
  } else if (type === "richText") {
    return (
      <>
        <RichTextEditor value={value || "<p></p>"} ref={setValue} />
      </>
    );
  }
};

EditOrCreateFields.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  setValue: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.array,
  tags: PropTypes.array,
  teams: PropTypes.array,
  closeErrorMessage: PropTypes.func,
  startDate: PropTypes.string,
  defineDates: PropTypes.object,
  setDefineDates: PropTypes.func,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorField: PropTypes.string,
  data: PropTypes.shape({
    value: PropTypes.any,
    name: PropTypes.string,
    __isNew__: PropTypes.bool,
    label: PropTypes.string,
  }),
};

export default EditOrCreateFields;
