import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import { TeambookCheckbox } from "../default_components/TeambookCheckbox";
import UsersList from "./sub_components/UserListGenerator/UsersList";
import CalendarInegrationDialog from "../default_components/teambook_calendar_integrations/CalendarInegrationDialog";
import { useState } from "react";
import { useProfileStore } from "../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";
import XLSExport from "../../lib/XLSExport";
import InfoTableColumnHeader from "../projects/sub_components/projects_table/InfoTableColumnHeader";
import { sortUsers } from "./sub_components/lib/sortUser";
import { useTeambookDataStore, useUsersVisibilityColumnsStore } from "../../stores/users";
import PropTypes from "prop-types";

const UsersTable = ({
  displayingActiveUsers,
  deleteUsers,
  activateUsers,
  displayedUsers,
  setDisplayedUsers,
  teams,
  departments,
  emptyFiltered,
}) => {
  const { t } = useTranslation();

  const [profile] = useProfileStore((state) => [state.profile], shallow);
  const [columnsVisibility] = useUsersVisibilityColumnsStore((state) => [state.columnsVisibility], shallow);
  const [setChosenUser, setUserFormState, selectedUsers, setSelectedUsers] = useTeambookDataStore(
    (state) => [state.setChosenUser, state.setUserFormState, state.selectedUsers, state.setSelectedUsers],
    shallow
  );

  const [calendarIntegrationOpen, setCalendarIntegrationOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("a");
  const [sortField, setSortField] = useState("");

  const isCurrentUserSelected = () => selectedUsers.map(({ id }) => id).includes(profile.id);
  const columnConfig = [
    {
      field: "last_name",
      title: t("users.full_name"),
      width: "column-width-3",
      visible: true,
    },
    {
      field: "email",
      title: t("users.email"),
      width: "column-width-2 mobile_hidden",
      visible: columnsVisibility["email"],
    },
    {
      field: "role",
      title: t("users.authorization"),
      width: "column-width-1",
      visible: columnsVisibility["role"],
    },
    {
      field: "billable",
      title: t("users.billable"),
      width: "column-width-1 mobile_hidden",
      visible: columnsVisibility["billable"],
    },
    {
      field: "department",
      title: t("users.department"),
      width: "column-width-2 mobile_hidden",
      visible: columnsVisibility["department"],
    },
    {
      field: "team_ids",
      title: t("users.teams"),
      width: "column-width-4 mobile_hidden",
      visible: columnsVisibility["teams"],
    },
    {
      field: "tags",
      title: t("users.tags"),
      width: "column-width-4 mobile_hidden",
      visible: columnsVisibility["tags"],
    },
    {
      field: "start_date",
      title: t("users.selected_fields.start_date"),
      width: "column-width-1 mobile_hidden",
      visible: columnsVisibility["start_date"],
    },
    {
      field: "end_date",
      title: t("users.selected_fields.end_date"),
      width: "column-width-1 mobile_hidden",
      visible: columnsVisibility["end_date"],
    },
  ];

  const exportUsers = () => {
    XLSExport.ExportUsers(selectedUsers);
    setSelectedUsers([]);
  };

  const selectAllUsers = () => {
    if (selectedUsers.length === displayedUsers.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(displayedUsers);
    }
  };

  const sortByField = (field, order) => {
    setSortField(field);

    if (order) {
      if (sortOrder === "a") {
        setSortOrder("z");
      } else {
        setSortOrder("a");
      }
      setDisplayedUsers(sortUsers(order, field, displayedUsers, teams, departments));
    } else {
      setDisplayedUsers(sortUsers(sortOrder, field, displayedUsers, teams, departments));
    }
  };

  const renderColumnHeader = (column) => {
    if (!column.visible) return null;

    return (
      <InfoTableColumnHeader
        key={column.field}
        sortingField={sortField}
        sortFunction={sortByField}
        sortingOrder={sortOrder}
        setSortOrder={setSortOrder}
        columnTitle={column.title}
        columnField={column.field}
        className={`users-page__user-list-header-value ${column.width}`}
      />
    );
  };

  const showUser = (user) => {
    setChosenUser(user);
    setUserFormState("show");
  };

  const selectUser = (user) => {
    if (selectedUsers.some(({ id }) => id === user.id)) {
      setSelectedUsers(selectedUsers.filter(({ id }) => id !== user.id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  return (
    <>
      <div className="users-page__users-buttons">
        {selectedUsers.length > 0 && (
          <p style={{ margin: "0px 0px 0px 54px", textAlign: "left" }}>
            {selectedUsers.length}&nbsp;
            {t(`users.selected_user${selectedUsers.length > 1 ? "s" : ""}_count`)}
          </p>
        )}

        {selectedUsers.length > 0 && (
          <TeambookIcon
            id="exportUsersPageButton"
            className="users-page__user-button"
            onClick={() => exportUsers()}
            alt="Export"
            name={icons.EXPORT_ARROW}
            tooltipTitle={t("dashboard.export_data")}
          />
        )}

        {displayingActiveUsers === false && !isCurrentUserSelected() && selectedUsers.length > 0 && (
          <TeambookIcon
            id="deleteUserPageButton"
            className="users-page__user-button"
            onClick={deleteUsers}
            alt="Delete"
            name={icons.TRASH}
            tooltipTitle={t("delete")}
          />
        )}

        {displayingActiveUsers && !isCurrentUserSelected() && selectedUsers.length > 0 && (
          <TeambookIcon
            id="deactivateUserPageButton"
            className="users-page__user-button"
            onClick={deleteUsers}
            alt="Delete"
            name={icons.TRASH}
            tooltipTitle={t("users.deactivate")}
          />
        )}

        {displayingActiveUsers === false && selectedUsers.length > 0 && (
          <TeambookIcon
            id="reactivateUserPageButton"
            className="users-page__user-button"
            onClick={activateUsers}
            name={icons.ADD_USER}
            tooltipTitle={t("users.reactivate")}
          />
        )}
      </div>

      <div className="users-page__users-content">
        <div className="users-page__user-list-header">
          <div className="user-list__fixed-field">
            <div id="selectAllUsers" className="user-block__select-checkbox" onClick={selectAllUsers}>
              <TeambookCheckbox
                type="checkbox"
                checked={selectedUsers.length === displayedUsers.length && selectedUsers.length > 0}
                onChange={selectAllUsers}
              />
            </div>

            {renderColumnHeader(columnConfig[0])}
          </div>

          {columnConfig.slice(1).map(renderColumnHeader)}
        </div>

        <UsersList
          users={displayedUsers}
          showUser={showUser}
          emptyFiltered={emptyFiltered}
          selectUser={selectUser}
          selectedUsers={selectedUsers}
        />

        {selectedUsers.length > 0 && calendarIntegrationOpen && (
          <CalendarInegrationDialog
            open={calendarIntegrationOpen}
            setOpen={setCalendarIntegrationOpen}
            user={selectedUsers[0]}
          />
        )}
      </div>
    </>
  );
};

UsersTable.propTypes = {
  displayingActiveUsers: PropTypes.bool,
  deleteUsers: PropTypes.func,
  activateUsers: PropTypes.func,
  displayedUsers: PropTypes.array,
  setDisplayedUsers: PropTypes.func,
  teams: PropTypes.array,
  departments: PropTypes.array,
  emptyFiltered: PropTypes.bool,
};

export default UsersTable;
