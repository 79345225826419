import { getPlannerOffsetX } from "../../../../lib/PlannerUtils";
import { useZoomStore } from "../../../../stores/planner";
import { shallow } from "zustand/shallow";
import PropTypes from "prop-types";

const ProjectRowWeek = ({ mouseDowned, mouseMoved, mouseUped, weekendsHidden, changeTooltip, index, project }) => {
  const userRole = localStorage.getItem("tb-role") || "regular";
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);

  const calculateWidth = () => {
    let width;

    if (zoom === 28) {
      width = 360;
    } else if (zoom === 14) {
      width = 700;
    } else {
      width = 100;
    }

    return {
      width: `${width}px`,
      borderTop: "2px solid " + project.color,
      borderBottom: "2px solid " + project.color,
    };
  };

  const calculateWeekendStyle = () => {
    if (weekendsHidden) {
      return {
        width: "20px",
        borderTop: "2px solid " + project.color,
        borderBottom: "2px solid " + project.color,
      };
    } else {
      if (zoom === 28) {
        return {
          width: "144px",
          borderTop: "2px solid " + project.color,
          borderBottom: "2px solid " + project.color,
        };
      } else if (zoom === 14) {
        return {
          width: "280px",
          borderTop: "2px solid " + project.color,
          borderBottom: "2px solid " + project.color,
        };
      } else {
        return {
          width: "40px",
          borderTop: "2px solid " + project.color,
          borderBottom: "2px solid " + project.color,
        };
      }
    }
  };

  return (
    <>
      <div
        id="createMilestone"
        className="project-row"
        style={calculateWidth()}
        onMouseDown={(e) => {
          if (e.button === 1) return;
          if (["contractor", "regular", "self_planner"].includes(userRole)) return;

          const rect = e.target.getBoundingClientRect();
          const y = e.clientY - rect.top; //y position within the element.

          mouseDowned(getPlannerOffsetX(e), y, index, project);
        }}
        onMouseMove={(e) => {
          if (["contractor", "regular", "self_planner"].includes(userRole)) return;

          mouseMoved(getPlannerOffsetX(e), project.id, index);
        }}
        onMouseUp={(e) => {
          if (e.button === 1) return;
          mouseUped();
        }}
        onDragOver={(e) => {
          changeTooltip({ open: false });
          e.preventDefault();
          e.stopPropagation();
        }}
      ></div>

      <div
        className="project-row__weekends"
        onMouseDown={(e) => {
          if (e.button === 1) return;
          if (["contractor", "regular"].includes(userRole)) return;

          const rect = e.target.getBoundingClientRect();
          const y = e.clientY - rect.top; //y position within the element.

          mouseDowned(getPlannerOffsetX(e), y, index, project);
        }}
        onMouseMove={(e) => {
          if (["contractor", "regular"].includes(userRole)) return;

          mouseMoved(getPlannerOffsetX(e), project.id, index);
        }}
        onMouseUp={(e) => {
          if (e.button === 1) return;
          mouseUped();
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={calculateWeekendStyle()}
      />
    </>
  );
};

ProjectRowWeek.propTypes = {
  mouseDowned: PropTypes.func.isRequired,
  mouseMoved: PropTypes.func.isRequired,
  mouseUped: PropTypes.func.isRequired,
  weekendsHidden: PropTypes.bool.isRequired,
  changeTooltip: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  project: PropTypes.object.isRequired,
};

export default ProjectRowWeek;
