import { WhiteTooltip } from "../default_components/Tooltips";
import lsKeys from "../default_values/defaultKeys";
import PropTypes from "prop-types";

export const AWS_URL = "https://d3pvelbecxpve1.cloudfront.net/icons_2";
// export const AWS_URL = "https://teambook-account-images-production.s3.amazonaws.com/icons_2";

export const ICON_COLOR = {
  DEFAULT: "default",
  ALTERNATIVE: "alternative",
  COMMON: "common",
};

// FOLDERS ARE FROZEN! YOU CANT ADD NEW FOLDERS HERE!!
export const ICON_FOLDERS = {
  LIGHT_DEFAULT: "light_default",
  LIGHT_ALT: "light_alternative",
  DARK_DEFAULT: "dark_default",
  DARK_ALT: "dark_alternative",
  COMMON: "common",
};

const COLOR_MAPPING = {
  light: {
    default: ICON_FOLDERS.LIGHT_DEFAULT,
    alternative: ICON_FOLDERS.LIGHT_ALT,
    common: ICON_FOLDERS.COMMON,
  },
  dark: {
    default: ICON_FOLDERS.DARK_DEFAULT,
    alternative: ICON_FOLDERS.DARK_ALT,
    common: ICON_FOLDERS.COMMON,
  },
};

export const TeambookIcon = ({
  name,
  color = ICON_COLOR.DEFAULT,
  onClick = () => {},
  tooltipTitle,
  placement,
  style = {},
  theme = localStorage.getItem(lsKeys.THEME),
  switchTheme = false,
  alt,
  iconClassName = "",
  ...props
}) => {
  const getTheme = () => {
    if (switchTheme) {
      const currentTheme = theme || "light";

      if (currentTheme === "light") {
        return "dark";
      } else {
        return "light";
      }
    } else {
      return theme || "light";
    }
  };

  const IMG_URL = `${AWS_URL}/${COLOR_MAPPING[getTheme()][color]}/${name}.svg`;

  if (!tooltipTitle) {
    return (
      <img
        style={{
          ...style,
        }}
        src={IMG_URL}
        alt={alt || name}
        onClick={onClick}
        {...props}
      />
    );
  }

  return (
    <WhiteTooltip maxWidth={400} title={tooltipTitle} placement={placement}>
      <img
        src={IMG_URL}
        style={{
          ...style,
        }}
        alt={alt || name}
        onClick={onClick}
        {...props}
        className={`${props.className || iconClassName}`}
      />
    </WhiteTooltip>
  );
};

TeambookIcon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
  tooltipTitle: PropTypes.string,
  placement: PropTypes.string,
  style: PropTypes.object,
  theme: PropTypes.string,
  switchTheme: PropTypes.bool,
  alt: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};
