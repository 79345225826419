import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import { icons } from "../../../default_images/IconsList";
import TeambookIconButton from "../../../default_components/TeambookIconButton";
import PropTypes from "prop-types";

const Client = ({ client, editClient, deleteClient, shareCalendar }) => {
  const { t } = useTranslation();

  const ColumnText = (text) => {
    const isShowToolTip = text?.length > 19;

    return (
      <WhiteTooltip title={isShowToolTip && text}>
        <p>{text || "-"}</p>
      </WhiteTooltip>
    );
  };

  return (
    <div className="clients-modal__row">
      {ColumnText(client.name)}
      {ColumnText(client.email)}
      {ColumnText(client.phone_number)}

      <div className="clients-modal__icons-container">
        <TeambookIconButton name={icons.PENCIL} onClick={() => editClient(client.id)} tooltipTitle={t("edit")} />

        <TeambookIconButton
          name={icons.TRASH}
          onClick={() => deleteClient(client.id, client.name)}
          tooltipTitle={t("delete")}
        />

        <TeambookIconButton
          name={icons.SHARE}
          onClick={() => shareCalendar(client.reference_id)}
          tooltipTitle={t("projects.copy_client_calendar")}
        />
      </div>
    </div>
  );
};

Client.propTypes = {
  client: PropTypes.object.isRequired,
  editClient: PropTypes.func.isRequired,
  deleteClient: PropTypes.func.isRequired,
  shareCalendar: PropTypes.func.isRequired,
};

export default Client;
