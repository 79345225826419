import { useEffect, useState } from "react";
import Api from "../../../../Api";
import { useTranslation } from "react-i18next";
import { phoneRegex } from "./ClientsDialog";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../../../stores/notificationStore";
import TeambookIconButton from "../../../default_components/TeambookIconButton";
import PropTypes from "prop-types";

const EditClientForm = ({ client, editClient, getAllClients, getAllProjects }) => {
  const { t } = useTranslation();
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [name, setName] = useState(client.name || "");
  const [email, setEmail] = useState(client.email || "");
  const [phone_number, setPhone_number] = useState(client.phone_number || "");
  const [errorMessage, setErrorMessage] = useState(null);
  const [wrongComponent, setWrongComponent] = useState(null);

  useEffect(() => {
    setErrorMessage(null);
    setWrongComponent(null);
  }, [name, email, phone_number]);

  const updateClient = () => {
    Api.Clients.update(client.id, {
      name,
      email,
      phone_number,
    })
      .then(() => {
        getAllClients();
        getAllProjects();
        editClient(null);
        setErrorMessage(null);
        setWrongComponent(null);
        setInAppNotification(t("notifications.projects.clients.edit"));
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error.message);
        setWrongComponent(error.response.data.error.field);
      });
  };

  const phoneOnChange = (event) => {
    const text = event.target.value;

    if (text.length > 0) {
      phoneRegex.test(text) && setPhone_number(text);
    } else {
      setPhone_number(text);
    }
  };

  const closeEditClient = () => {
    editClient(null);
  };

  return (
    <>
      <div className={`clients-modal__row wrong-${wrongComponent}`}>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Client Name" />
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
        <input type="text" value={phone_number} onChange={phoneOnChange} placeholder="Phone" />

        <div className="clients-modal__icons-container">
          <TeambookIconButton
            name={icons.CHECK}
            tooltipTitle={t("save")}
            onClick={updateClient}
            disable={errorMessage}
          />

          <TeambookIconButton
            name={icons.CROSS}
            tooltipTitle={t("cancel")}
            onClick={closeEditClient}
            disable={errorMessage}
          />
        </div>
      </div>

      {errorMessage && (
        <div className={`clients-modal__error-message wrong-${wrongComponent}`}>
          <TeambookIcon
            name={icons.WARNING}
            color="common"
            style={{
              marginTop: errorMessage.split(" ").length > 6 ? "8px" : "0px",
            }}
          />
          <p>{errorMessage}</p>
        </div>
      )}
    </>
  );
};

EditClientForm.propTypes = {
  client: PropTypes.object.isRequired,
  editClient: PropTypes.func.isRequired,
  getAllClients: PropTypes.func.isRequired,
  getAllProjects: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  phone_number: PropTypes.string.isRequired,
  setPhone_number: PropTypes.func.isRequired,
};

export default EditClientForm;
