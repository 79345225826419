import { create } from "zustand";

export const useUsersVisibilityColumnsStore = create((set) => ({
  columnsVisibility: {},
  setColumnsVisibility: (value) => set({ columnsVisibility: value }),
}));

export const useTeambookDataStore = create((set) => ({
  departments: undefined,
  setDepartments: (value) => set({ departments: value }),
  tags: [],
  setTags: (value) => set({ tags: value }),
  teams: undefined,
  setTeams: (value) => set({ teams: value }),
  users: [],
  setUsers: (value) => set({ users: value }),
  chosenUser: undefined,
  setChosenUser: (value) => set({ chosenUser: value }),
  tab: "general",
  setTab: (value) => set({ tab: value }),
  userFormState: undefined,
  setUserFormState: (value) => set({ userFormState: value }),
  selectedUsers: [],
  setSelectedUsers: (value) => set({ selectedUsers: value }),
}));
