import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useProfileStore } from "../../../stores/profileStore";
import { shallow } from "zustand/shallow";

// dyk -> did you know

const ROLE_KEY = "tb-role";
const CLOSING_COUNT_KEY = "dyk-closing-count";
const LAST_QUARTER_SHOW = "dyk-last-quarter-show";

const getQuarter = (date) => {
  let quarters = [...Array(4)].map((_, index) => {
    let quarterDate = DateTime.now().set({ month: 1, day: 15 });

    return {
      start: quarterDate.plus({ month: 3 * index }).ts,
      end: quarterDate.plus({ month: 3 * (index + 1) }).ts,
    };
  });

  return quarters.findIndex(({ start, end }) => start <= date.getTime() && date.getTime() < end) + 1 || 4;
};

const DidYouKnow = React.forwardRef(({}, ref) => {
  const { t } = useTranslation();

  const [profile] = useProfileStore((state) => [state.profile], shallow);

  React.useImperativeHandle(ref, () => {
    return {
      ReopenDidYouKnow: ReopenDidYouKnow,
    };
  });

  const createdAt = localStorage.getItem("created_at") || profile.created_at;

  const isOpenedThisQuarter = () => {
    if (getQuarter(new Date()) !== +localStorage.getItem(LAST_QUARTER_SHOW)) {
      localStorage.removeItem(CLOSING_COUNT_KEY);
      return false;
    }
  };

  const [currentInfo, setCurrentInfo] = useState(1);
  const [show, setShow] = useState(true);
  const role = localStorage.getItem(ROLE_KEY) || "regular";
  const today = new Date();
  const created_at = new Date(createdAt);
  const daysFromCreation = Math.floor((today - created_at) / 24 / 60 / 60 / 1000);
  const noMoreInfo = currentInfo > 10;
  const closingCount = !isOpenedThisQuarter()
    ? true
    : localStorage.getItem(CLOSING_COUNT_KEY) === null
    ? 0
    : localStorage.getItem(CLOSING_COUNT_KEY);

  const checkVisible = () => {
    if (
      !show ||
      noMoreInfo ||
      closingCount >= 2 ||
      daysFromCreation < 2 ||
      role === "regular" ||
      role === "contractor" ||
      role === "self_planner" ||
      getQuarter(new Date()) === +localStorage.getItem(LAST_QUARTER_SHOW)
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    setIsVisible(checkVisible());
  }, [show, noMoreInfo, daysFromCreation, role]);

  const [isVisible, setIsVisible] = useState(checkVisible());

  const ReopenDidYouKnow = () => {
    localStorage.removeItem("dyk-closing-count");
    localStorage.removeItem("dyk-next-show");
    localStorage.removeItem(LAST_QUARTER_SHOW);
    setCurrentInfo(1);
    setIsVisible(true);
  };

  if (noMoreInfo) {
    localStorage.setItem(LAST_QUARTER_SHOW, getQuarter(new Date()));
    localStorage.setItem(CLOSING_COUNT_KEY, 2);
  }

  // noMoreInfo - if user has viewed all data
  // closingCount - if user close window more than 2 times
  // daysFromCreation - displays if more than two days have passed since the creation
  // nextShowDate - if user has closed one time
  //TODO: Remove entirely
  return <div></div>;

  return (
    isVisible && (
      <div onClick={(e) => e.stopPropagation()} className="mobile_hidden did-you-know__card">
        <div>
          <span>
            <h3>{t("did_you_know.header")}</h3>
          </span>
          <span
            id={"closeDidYouKnowWindow"}
            className="did-you-know__close-button"
            onClick={() => {
              setShow(false);
              localStorage.setItem(CLOSING_COUNT_KEY, (+localStorage.getItem(CLOSING_COUNT_KEY) + 1).toString());

              localStorage.setItem(LAST_QUARTER_SHOW, getQuarter(new Date()));
              setIsVisible(false);
            }}
          />
        </div>
        <p>
          {t(`did_you_know.info_${currentInfo}`)}{" "}
          <a target="_blank" href={t(`did_you_know.link_${currentInfo}`)}>
            {t("did_you_know.more")}
          </a>
        </p>
        <span>
          <button
            onClick={() => {
              setCurrentInfo(currentInfo + 1);
            }}
          >
            <p style={{ margin: "0px", fontSize: "12px" }}>{t("did_you_know.next")}</p>
            <span className="arrow did-you-know__arrow" />
          </button>
        </span>
      </div>
    )
  );
});

export default DidYouKnow;
