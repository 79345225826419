import Header from "../header/Header";
import UsersFilterRow from "./sub_components/UsersFilterRow";
import TeambookDialogDangerAction from "../default_components/TeambookDialogDangerAction";
import UserForm from "./sub_components/UserForm";
import { SwitchToPaidPlanBanner } from "../default_components/SwitchToPaidPlanBanner";
import { useEffect, useState } from "react";
import filterUsers from "./sub_components/lib/filterUsers";
import { useTeambookFilter } from "../../stores/planner";
import { useTranslation } from "react-i18next";
import Api from "../../Api";
import { useProfileStore } from "../../stores/profileStore";
import { shallow } from "zustand/shallow";
import UsersTable from "./UsersTable";
import _deleteUsers from "./sub_components/lib/deleteUsers";
import _activateUsers from "./sub_components/lib/activateUsers";
import { useTeambookDataStore } from "../../stores/users";
import { useNotificationStore } from "../../stores/notificationStore";
import LoadingComponent from "../default_components/LoadingComponent";

const UsersPage = () => {
  const { t } = useTranslation();

  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const { filterValues, filterType } = useTeambookFilter();
  const [currentUser] = useProfileStore((state) => [state.profile], shallow);
  const [
    selectedUsers,
    setSelectedUsers,
    chosenUser,
    userFormState,
    setUserFormState,
    teams,
    setTeams,
    tags,
    setTags,
    departments,
    setDepartments,
    setUsers,
  ] = useTeambookDataStore(
    (state) => [
      state.selectedUsers,
      state.setSelectedUsers,
      state.chosenUser,
      state.userFormState,
      state.setUserFormState,
      state.teams,
      state.setTeams,
      state.tags,
      state.setTags,
      state.departments,
      state.setDepartments,
      state.setUsers,
    ],
    shallow
  );

  const [activeUsers, setActiveUsers] = useState();
  const [deactivatedUsers, setDeactivatedUsers] = useState();
  const [displayedUsers, setDisplayedUsers] = useState();
  const [displayingActiveUsers, setDisplayingActiveUsers] = useState(true);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [dangerDialogOpen, setDangerDialogOpen] = useState(false);
  const [dangerDialogProps, setDangerDialogProps] = useState({});
  const [emptyFiltered, setEmptyFiltered] = useState(false);

  useEffect(() => {
    if (!dataIsLoaded) return;

    const filteredUsers = filterUsers(
      displayingActiveUsers ? activeUsers : deactivatedUsers,
      filterType,
      filterValues,
      teams,
      tags,
      departments,
      t
    );

    setDisplayedUsers(filteredUsers);
    setEmptyFiltered(filterValues.length > 0 && filteredUsers.length === 0);
  }, [displayingActiveUsers, dataIsLoaded, filterValues, filterType, activeUsers, deactivatedUsers]);

  useEffect(() => {
    const usersAreReady = displayingActiveUsers ? activeUsers : deactivatedUsers;

    setDataIsLoaded(usersAreReady && teams && tags && departments);
  }, [displayingActiveUsers, activeUsers, teams, tags, departments]);

  useEffect(() => {
    getAllUsers();
    Api.Teams.all().then((response) => setTeams(response.data));
    Api.Tags.all().then((response) => setTags(response.data));
    Api.Departments.all().then((response) => setDepartments(response.data));
  }, []);

  const getActiveUsers = () => {
    Api.Users.active().then((response) => {
      setActiveUsers(response.data);
    });
  };

  const analyzeAllUsersResponse = (response) => {
    setActiveUsers(response.data.filter((user) => user.active));
    setDeactivatedUsers(response.data.filter((user) => !user.active));
  };

  const getAllUsers = () => {
    Api.Users.all().then((response) => {
      analyzeAllUsersResponse(response);
      setUsers(response.data);
    });
  };

  const copyCalendar = (user) => {
    navigator.clipboard.writeText(Api.Calendar.link((selectedUsers[0] || user)?.calendar_token));
    setInAppNotification(t("notifications.users.copy_calendar"));
  };

  const deleteUserRequest = () => {
    Api.Users.delete(chosenUser ? [chosenUser.id] : selectedUsers.map(({ id }) => id)).then(() => {
      useTeambookDataStore.getState().setSelectedUsers([]);
      useTeambookDataStore.getState().setUserFormState("");
      getAllUsers();
    });
  };

  const deactivateUserRequest = () => {
    Api.Users.deactivate(chosenUser ? [chosenUser.id] : selectedUsers.map(({ id }) => id)).then(() => {
      useTeambookDataStore.getState().setSelectedUsers([]);
      useTeambookDataStore.getState().setUserFormState("");
      getAllUsers();
    });
  };

  const activateUserRequest = () => {
    Api.Users.activate(chosenUser ? [chosenUser.id] : selectedUsers.map(({ id }) => id)).then(() => {
      useTeambookDataStore.getState().setSelectedUsers([]);
      useTeambookDataStore.getState().setUserFormState("");
      getAllUsers();
    });
  };

  const deleteUsers = () => {
    _deleteUsers(
      t,
      setDangerDialogOpen,
      setDangerDialogProps,
      chosenUser,
      selectedUsers,
      deactivateUserRequest,
      deleteUserRequest,
      displayingActiveUsers
    );
  };

  const activateUsers = () => {
    _activateUsers(t, setDangerDialogOpen, setDangerDialogProps, chosenUser, selectedUsers, activateUserRequest);
  };

  const changeDisplayingUsersType = (value) => {
    setDisplayingActiveUsers(value);
    setSelectedUsers([]);
  }

  return (
    <div className="users-page">
      <Header page="users" />

      {dataIsLoaded && displayedUsers ? (
        <>
          <UsersFilterRow
            users={displayingActiveUsers ? activeUsers : deactivatedUsers}
            openCreation={() => setUserFormState("create")}
            getActiveUsers={getActiveUsers}
            displayingActiveUsers={displayingActiveUsers}
            changeDisplayingUsersType={changeDisplayingUsersType}
          />

          {currentUser && <SwitchToPaidPlanBanner />}

          <TeambookDialogDangerAction open={dangerDialogOpen} setOpen={setDangerDialogOpen} {...dangerDialogProps} />

          {(chosenUser || userFormState === "create") && (
            <UserForm
              analyzeAllUsersResponse={analyzeAllUsersResponse}
              getAllUsers={getAllUsers}
              setDisplayingActiveUsers={setDisplayingActiveUsers}
              deleteUserFunction={deleteUsers}
              displayingActiveUsers={displayingActiveUsers}
              copyCalendar={copyCalendar}
              activateUsers={activateUsers}
            />
          )}

          <UsersTable
            displayingActiveUsers={displayingActiveUsers}
            deleteUsers={deleteUsers}
            activateUsers={activateUsers}
            displayedUsers={displayedUsers}
            setDisplayedUsers={setDisplayedUsers}
            teams={teams}
            departments={departments}
            emptyFiltered={emptyFiltered}
          />
        </>
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
};

export default UsersPage;
