import { useState, useRef } from "react";
import "../../../default_styles/tb-colors.css";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "./../../../default_components/Tooltips";
import TeambookColorPicker from "./../../../default_components/TeambookColorPicker";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import { icons } from "../../../default_images/IconsList";
import TeambookIconButton from "../../../default_components/TeambookIconButton";
import PropTypes from "prop-types";
function EditTag({
  tag,
  closeErrorMessage,
  updateTag,
  tagName,
  setTagName,
  tagColor,
  setTagColor,
  errorMessage,
  handleCloseEditing,
  errorField,
}) {
  const { t } = useTranslation();

  const [changeColorsOpened, setChangeColorsOpened] = useState(false);
  const anchorRef = useRef(null);

  if (tag.id === undefined) {
    return <div />;
  }

  return (
    <>
      <div className="tag-menu__tag-editing">
        <div style={{ width: 70, padding: "0px 23px" }}>
          <WhiteTooltip title={t("projects.change_color")}>
            <div
              className="tags-color-circle"
              style={{ border: `2px solid ${tagColor}`, backgroundColor: `${tagColor}66`, cursor: "pointer" }}
              onClick={() => setChangeColorsOpened(true)}
              ref={anchorRef}
            />
          </WhiteTooltip>
        </div>

        <TeambookColorPicker
          color={tagColor}
          setColor={setTagColor}
          changeColorsOpened={changeColorsOpened}
          setChangeColorsOpened={setChangeColorsOpened}
          anchorRef={anchorRef}
        />

        <div>
          <TeambookTextForm
            id={"tagName"}
            width={200}
            fieldValue={tagName}
            onChange={setTagName}
            autoFocus
            placeholder={t("users.tag_name")}
            errorField={errorField}
            errorMessage={errorMessage}
            closeErrorMessage={closeErrorMessage}
            field={"name"}
          />
        </div>

        <div className="tag-menu__tag-number">
          <p style={{ margin: 0 }}>{tag.tag_users.length}</p>
        </div>

        <div
          style={{
            paddingLeft: 68,
            display: "flex",
            gap: 20,
          }}
        >
          <TeambookIconButton name={icons.CHECK} tooltipTitle={t("save")} onClick={updateTag} id={"saveEditingTag"} />

          <TeambookIconButton name={icons.CROSS} tooltipTitle={t("close")} onClick={handleCloseEditing} />
        </div>
      </div>
    </>
  );
}

EditTag.propTypes = {
  tag: PropTypes.object.isRequired,
  closeErrorMessage: PropTypes.func.isRequired,
  updateTag: PropTypes.func.isRequired,
  tagName: PropTypes.string.isRequired,
  setTagName: PropTypes.func.isRequired,
  tagColor: PropTypes.string.isRequired,
  setTagColor: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  handleCloseEditing: PropTypes.func.isRequired,
  errorField: PropTypes.string,
};

export default EditTag;
