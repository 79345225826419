import { components } from "react-select";
import ReactSelect from "react-select/creatable";
import { ICON_COLOR, TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import PropTypes from "prop-types";

export const ReactSelectStyles = (height, width, grayPlaceholder) => {
  return {
    option: (base, data) => {
      return {
        ...base,
        background: data.isSelected
          ? "var(--stroke) !important"
          : data.isFocused
          ? "var(--background-2) !important"
          : "#transparent",
        color: data.isSelected ? "var(--selected-option-color)" : "var(--text-2)",
        fontSize: "14px !important",
        fontFamily: "Montserrat",
      };
    },

    menu: (base) => ({
      ...base,
      background: "var(--background-1)",
      width: width || "100%",
    }),

    input: (base) => ({
      ...base,
      color: "var(--text-2)",
    }),

    menuList: (base) => ({
      ...base,
      background: "var(--background-1)",
      border: "none",
      boxShadow: "var(--box-shadow)",
    }),

    control: (base, data) => {
      return {
        ...base,
        height: height || data.height,
        minHeight: height || data.height,
        maxHeight: height || data.height,
        width: width || data.width,
        minWidth: width || data.width,
        maxWidth: "100%",
        fontFamily: "Montserrat",
        background: "var(--background-1) !important",
        border: data.isDisabled ? "unset !important" : "1px solid var(--stroke) !important",
      };
    },

    multiValue: (base) => ({
      ...base,
      background: "var(--background-2)",
      fontFamily: "Montserrat",
    }),

    multiValueLabel: () => ({
      color: "var(--text-2) !important",
      fontSize: "12px",
      whiteSpace: "nowrap",
    }),

    singleValue: (base) => ({
      ...base,
      color: grayPlaceholder && "var(--text-2) !important",
    }),
  };
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <TeambookIcon
        name={icons.ARROW_DOWN}
        style={{
          cursor: "pointer",
          transform: props.selectProps.menuIsOpen ? "rotate(180deg)" : "unset",
        }}
      />
    </components.DropdownIndicator>
  );
};

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <TeambookIcon name={icons.CROSS} style={{ width: 14, height: 14 }} />
    </components.MultiValueRemove>
  );
};

export const TeambookReactSelect = ({
  nameStyles,
  fieldName,
  classNamePrefix = "tb-react-select",
  isSearchable = false,
  menuIsOpen,
  options,
  value,
  defaultValue,
  menuPlacement,
  onChange,
  id,
  height,
  width,
  type,
  newComponents,
  closeMenuOnSelect,
  isDisabled,
  placeholder,
  createOptionPosition = "",
  maxMenuHeight,
  formatCreateLabel = () => {},
  isValidNewOption = () => {},
  onCreateOption = () => {},
  styles,
  onBlur = () => {},
  menuPortalTarget = document.body,
  filterOption,
  grayPlaceholder,
  tabIndex,
  errorField = null,
  errorMessage,
  field,
  closeErrorMessage = () => {},
}) => {
  const hasError = errorMessage?.length > 0 && errorField === field;

  const emptyAriaLiveMessages = {
    guidance: () => "",
    onChange: () => "",
    onFilter: () => "",
    onFocus: () => "",
  };

  return (
    <div
      style={{ width: width, maxWidth: "100%" }}
      className={`tb-react-select__wrapper ${hasError ? "invalid-value" : ""}`}
    >
      {fieldName && (
        <p className="tb-react-select__field-name" style={{ fontSize: 14, marginBottom: 5, ...nameStyles }}>
          {fieldName}
        </p>
      )}
      <ReactSelect
        className="teambook-react-select"
        components={{
          DropdownIndicator,
          MultiValueRemove: MultiValueRemove,
          ...newComponents,
        }}
        menuPortalTarget={menuPortalTarget}
        classNamePrefix={type === "multiple" ? `${classNamePrefix}-multi` : `${classNamePrefix}`}
        isSearchable={isSearchable}
        menuIsOpen={menuIsOpen}
        options={options}
        value={value}
        styles={{
          ...ReactSelectStyles(height, width, grayPlaceholder),
          ...styles,
        }}
        getOptionValue={({ id, value }) => id || value}
        getOptionLabel={(object) =>
          object.label !== undefined ? object.label : object.name !== undefined ? object.name : object.title
        }
        maxMenuHeight={maxMenuHeight}
        defaultValue={defaultValue}
        menuPlacement={menuPlacement}
        onChange={(option, newValue) => {
          onChange(option, newValue);
          closeErrorMessage();
        }}
        onBlur={onBlur}
        id={id}
        isMulti={type === "multiple"}
        closeMenuOnSelect={closeMenuOnSelect}
        isDisabled={isDisabled}
        placeholder={placeholder}
        createOptionPosition={createOptionPosition}
        formatCreateLabel={formatCreateLabel}
        isValidNewOption={isValidNewOption}
        onCreateOption={onCreateOption}
        filterOption={filterOption}
        ariaLiveMessages={emptyAriaLiveMessages}
        tabIndex={tabIndex}
      />

      {hasError && (
        <div className="select-form__error-field">
          <TeambookIcon color={ICON_COLOR.COMMON} name={icons.WARNING} />
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

DropdownIndicator.propTypes = {
  selectProps: PropTypes.object,
};

MultiValueRemove.propTypes = {
  selectProps: PropTypes.object,
};

TeambookReactSelect.propTypes = {
  nameStyles: PropTypes.object,
  fieldName: PropTypes.string,
  classNamePrefix: PropTypes.string,
  isSearchable: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  menuPlacement: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  type: PropTypes.string,
  newComponents: PropTypes.object,
  closeMenuOnSelect: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  createOptionPosition: PropTypes.string,
  maxMenuHeight: PropTypes.number,
  formatCreateLabel: PropTypes.func,
  isValidNewOption: PropTypes.func,
  onCreateOption: PropTypes.func,
  styles: PropTypes.object,
  onBlur: PropTypes.func,
  menuPortalTarget: PropTypes.object,
  filterOption: PropTypes.func,
  grayPlaceholder: PropTypes.bool,
  tabIndex: PropTypes.number,
  errorField: PropTypes.string,
  errorMessage: PropTypes.string,
  field: PropTypes.string,
  closeErrorMessage: PropTypes.func,
};
