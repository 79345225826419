import { Dialog, DialogContent } from "@mui/material";
import {ICON_COLOR, TeambookIcon} from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const MobileContent = ({ register }) => {
  const { t } = useTranslation();
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);

  const continueRegister = () => {
    setIsButtonsDisabled(true);
    register(null).then(() => {
      setIsButtonsDisabled(false);
    });
  };

  return (
    <Dialog className="register-pop-up__container" fullWidth={true} open fullScreen>
      <DialogContent className="register-pop-up__content mobile-register-modal">
        <div className="register-mobile__header">
          <TeambookIcon name={icons.TB_LOGO} color={ICON_COLOR.ALTERNATIVE} />

          <TeambookIcon name={icons.CROSS} onClick={continueRegister} />
        </div>

        <p className="register-mobile__main-text">Unlock Teambook potential on Desktop</p>
        <p className="register-mobile__secondary-text">Mobile version has limited functionality</p>

        <TeambookIcon className="register-mobile__icon" name={icons.REGISTER_MOBILE} />

        <TeambookBlueButton
          disabled={isButtonsDisabled}
          onClick={continueRegister}
          text={t("got_it")}
          style={{ width: "80%" }}
          className="got-it__button"
        />
      </DialogContent>
    </Dialog>
  );
};

export default MobileContent;
