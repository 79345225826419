import { WhiteTooltip } from "../../../default_components/Tooltips";
import { SliceStringWithDots } from "../../../../lib/StringFormat";

const deleteUsers = (
  t,
  setDangerDialogOpen,
  setDangerDialogProps,
  user,
  selectedUsers,
  deactivateUserRequest,
  deleteUserRequest,
  displayingActiveUsers
) => {
  const getConfirmationText = (userList) => {
    const confirmationData = {
      actionText: displayingActiveUsers ? t("users.deactivate_confirmation_1") : t("users.delete_confirmation_1"),
      actionText2: displayingActiveUsers ? t("users.deactivate_confirmation_2") : t("users.delete_confirmation_2"),
      multipleActionText: displayingActiveUsers
        ? t("users.multiple_deactivate_confirmation_1")
        : t("users.multiple_delete_confirmation_1"),
      multipleActionText2: displayingActiveUsers
        ? t("users.multiple_deactivate_confirmation_2")
        : t("users.multiple_delete_confirmation_2"),
    };

    return userList.length === 1 ? (
      <>
        <p>
          {confirmationData.actionText}
          <b>{userList[0].name}</b>
          {confirmationData.actionText2}
        </p>
      </>
    ) : (
      <>
        <p>
          {confirmationData.multipleActionText}
          <WhiteTooltip
            placement="top"
            title={userList
              .slice(0, 2)
              .map(({ name }) => SliceStringWithDots(name, 16))
              .toString()}
          >
            <b style={{ cursor: "pointer" }}>
              {t("users.selected")} {` ${userList.length}`}
            </b>
          </WhiteTooltip>
          {confirmationData.multipleActionText2}
        </p>
      </>
    );
  };

  const deletionData = {
    actionRequest: displayingActiveUsers ? deactivateUserRequest : deleteUserRequest,
    buttonText: displayingActiveUsers ? t("users.deactivate") : t("Delete"),
    contentText: getConfirmationText(user ? [user] : selectedUsers, displayingActiveUsers),
  };

  if (user || selectedUsers.length) {
    setDangerDialogOpen(true);
    setDangerDialogProps(deletionData);
  }
};

export default deleteUsers;
