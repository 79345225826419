import { useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import TeambookTextForm from "../../TeambookTextForm";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { useTranslation } from "react-i18next";
import Api from "../../../../Api";
import { useTeambookFilter } from "../../../../stores/planner";
import TeambookIconButton from "../../TeambookIconButton";
import PropTypes from "prop-types";

const ApplyFilterModal = ({ setApplyFilterModalOpen, filterPage }) => {
  const { t } = useTranslation();
  const [filterOptions, setFilterOptions] = useState([]);
  const [editSavedFilterId, setEditSavedFilterId] = useState(null);
  const [editValueName, setEditValueName] = useState(null);

  const { setFilterValue } = useTeambookFilter();

  useEffect(() => {
    fetchFilters();
  }, []);

  const applyFilter = (filter) => {
    setFilterValue(JSON.parse(filter));
    setApplyFilterModalOpen(false);
  };

  const fetchFilters = () => {
    Api.Users.get_saved_filters(filterPage).then((res) => {
      setFilterOptions(res.data);
    });
  };

  const openEditName = (option) => {
    setEditValueName(option.name);
    setEditSavedFilterId(option.id);
  };

  const saveChanges = () => {
    Api.Users.update_saved_filter(editSavedFilterId, editValueName).then(() => {
      setEditSavedFilterId(null);
      setEditValueName(null);
      fetchFilters();
    });
  };

  const deleteSavedFilter = (filterId) => {
    Api.Users.delete_saved_filter(filterId).then(() => {
      fetchFilters();
    });
  };

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="tb-default-dialog leave-modal__dialog"
      onClose={() => setApplyFilterModalOpen(false)}
    >
      <DialogTitle>
        <div className="flex">
          <p>{t("planning.apply_saved_filter")}</p>
        </div>

        <TeambookIcon name={icons.CROSS} onClick={() => setApplyFilterModalOpen(false)} />
      </DialogTitle>

      <DialogContent style={{ margin: "20px 0px" }}>
        {filterOptions.length === 0 && <p>{t("planning.no_filters")}</p>}

        {filterOptions.map((option) =>
          editSavedFilterId !== option.id ? (
            <div key={option.id} className="apply-filter-modal__option" style={{ alignItems: "center" }}>
              <p onClick={() => applyFilter(option.filter)}>{option.name}</p>

              <div className="flex" style={{ gap: 20 }}>
                <div onClick={() => openEditName(option)}>
                  <TeambookIconButton name={icons.PENCIL} tooltipTitle={t("edit")} />
                </div>

                <div onClick={() => deleteSavedFilter(option.id)}>
                  <TeambookIconButton name={icons.TRASH} tooltipTitle={t("delete")} />
                </div>
              </div>
            </div>
          ) : (
            <div key={option.id} className="apply-filter-modal__option">
              <TeambookTextForm
                fieldValue={editValueName}
                onChange={setEditValueName}
                style={{ margin: "0 0 11px 5px" }}
              />

              <div className="flex" style={{ gap: 20 }}>
                <div onClick={saveChanges}>
                  <TeambookIconButton name={icons.CHECK} tooltipTitle={t("save")} />
                </div>

                <div onClick={() => setEditSavedFilterId(null)}>
                  <TeambookIconButton name={icons.CROSS} tooltipTitle={t("cancel")} />
                </div>
              </div>
            </div>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};

ApplyFilterModal.propTypes = {
  setApplyFilterModalOpen: PropTypes.func.isRequired,
  filterPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ApplyFilterModal;
