import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../dashboard.scss";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DownloadReportingWindow from "./DownloadReportingWindow";
import TeambookFilterComponent from "../../default_components/teambook_filter/TeambookFilterComponent";
import lsKeys from "../../default_values/defaultKeys";
import { TeambookGroupDropdown } from "../../default_components/TeambookDropdown";
import { icons } from "../../default_images/IconsList";
import TeambookDatePicker from "../../default_components/teambook_date_selector/DatePicker";
import { useDashboardStore } from "../../../stores/dashboard";
import { shallow } from "zustand/shallow";
import { AnalyticsSelect } from "../sub_components/AnalyticsSelect";
import { useAccountStore } from "../../../stores/accountStore";
import { TodayButton } from "../../default_components/TodayButton";
import CapacityVisualSettings from "../../capacity_planning/CapacityVisualSettings";
import { WhiteTooltip } from "../../default_components/Tooltips";
import TeambookIconButton from "../../default_components/TeambookIconButton";
import PropTypes from "prop-types";

const ReportingControlBar = ({
  from,
  setFrom,
  selectedTeams,
  splitBy,
  setSplitBy,
  type,
  activeUsers,
  clients,
  activeProjects,
  tags,
  teams,
  tasks,
  selectedViewType,
  setSelectedViewType,
  setReportingPage,
  reporting_page,
  slaveDataType,
  range,
  setRange,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [downloadWindowOpen, setDownloadWindowOpen] = useState(false);
  const [setDashboardType] = useDashboardStore((state) => [state.setDashboardType], shallow);
  const [visualSettingsOpened, setVisualSettingsOpened] = useState(false);

  const [account] = useAccountStore((state) => [state.account], shallow);

  const reportingLinks = [
    {
      group_value: "default",
      group: t("header.planning"),
      elements: [
        {
          name: t("dashboard.by_projects"),
          param: "project",
          icon: icons.BLUE_PROJECTS,
        },
        {
          name: t("dashboard.by_users"),
          param: "user",
          icon: icons.USERS,
        },
        {
          name: t("dashboard.overview"),
          param: "overview",
          icon: icons.REPORTING,
        },
      ],
    },
    {
      group_value: "capacity",
      group: t("dashboard.capacity"),
      elements: [
        {
          name: t("dashboard.by_users"),
          param: "capacity_user",
          icon: icons.USERS,
        },
        {
          name: t("dashboard.by_projects"),
          param: "capacity_project",
          icon: icons.BLUE_PROJECTS,
        },
      ],
    },
    {
      group_value: "actuals",
      group: t("header.actuals"),
      elements: [
        {
          name: t("dashboard.by_projects"),
          param: "actuals_project",
          icon: icons.BLUE_PROJECTS,
        },
        {
          name: t("dashboard.by_users"),
          param: "actuals_user",
          icon: icons.USERS,
        },
        {
          name: t("dashboard.overview"),
          param: "actuals_overview",
          icon: icons.REPORTING,
        },
      ],
    },
    {
      group_value: "comparison",
      group: t("dashboard.compare"),
      elements: [
        {
          name: t("dashboard.by_projects"),
          param: "comparison_project",
          icon: icons.BLUE_PROJECTS,
        },
        {
          name: t("dashboard.by_users"),
          param: "comparison_user",
          icon: icons.USERS,
        },
        {
          name: t("dashboard.by_tasks"),
          param: "comparison_task",
          icon: icons.TASKS, //change
        },
      ],
    },
  ];

  if ([9, 591, 755, 272, 1130].includes(account?.id) || process.env.REACT_APP_ENV === "staging") {
    reportingLinks[0].elements.push({
      name: t("dashboard.satriun_overview"),
      param: "sat_overview",
      icon: icons.REPORTING,
    });

    reportingLinks[2].elements.push({
      name: t("dashboard.satriun_overview"),
      param: "sat_overview",
      icon: icons.REPORTING,
    });
  }

  const closeVisualSettings = () => {
    setVisualSettingsOpened(false);
  };

  const openVisualSettings = () => {
    setVisualSettingsOpened(true);
  };

  const moveToDashboard = () => {
    let dashboardType;

    switch (type) {
      case "capacity":
        localStorage.setItem(lsKeys.DASHBOARD_RANGE, 3);
        dashboardType = "capacity";
        break;
      case "comparison":
        localStorage.setItem(lsKeys.DASHBOARD_RANGE, 1);
        dashboardType = "compare";
        break;
      default:
        localStorage.setItem(lsKeys.DASHBOARD_RANGE, 1);
        dashboardType = type;
        break;
    }

    setDashboardType(dashboardType);

    navigate("/dashboard");
  };

  const filterPage = {
    user: "reportingUsers",
    project: "reportingProjects",
    capacity_project: "reportingCapacityProjects",
    capacity_user: "reportingCapacityUsers",
    capacity_tag: "reportingCapacityTags",
    comparison_project: "reportingComparisonProject",
    comparison_user: "reportingComparisonUser",
    comparison_task: "reportingComparisonTasks",
    overview: "reportingOverview",
    sat_overview: "reportingSatOverview",
    actuals_user: "reportingActualsUsers",
    actuals_project: "reportingActualsProjects",
    actuals_overview: "reportingActualsOverview",
  };

  return (
    <div className="reporting__control-bar control-bar flex">
      <div className="dashboard__control-bar-datepicker">
        <TodayButton
          setDate={(date) => {
            if (type === "capacity") {
              setFrom({ start: date, end: date.endOf("month") });
            } else {
              switch (range) {
                case 1:
                  setFrom({ start: date.startOf("month"), end: date.endOf("month") });
                  return;
                case 2:
                  setFrom({
                    start: date.startOf("month"),
                    end: date.plus({ month: 2 }).endOf("month"),
                  });
                  return;

                default:
                  setFrom({ start: date.startOf("month"), end: date.endOf("month") });
              }
            }
          }}
        />

        <TeambookDatePicker
          from={from.start}
          to={from.end}
          formatStart={+range === 1 ? "LLL yyyy" : "LLL"}
          formatEnd="d LLL yyyy"
          setRange={setRange}
          range={range}
          setFrom={setFrom}
          style={{ height: 42 }}
          type={type === "capacity" ? "default" : "range"}
          highlight={type === "capacity" ? "today" : "selected"}
          selectOnArrowClick={false}
          onDayClick={(date) => {
            switch (range) {
              case 1:
                setFrom(() => ({
                  start: date.startOf("month"),
                  end: date.endOf("month"),
                }));
                return;
              case 2:
                setFrom(() => ({
                  start: date.startOf("month"),
                  end: date.plus({ month: 2 }).endOf("month"),
                }));
                return;

              default:
            }
          }}
        />
      </div>

      <div className="reporting-control__filter-text-field">
        <TeambookFilterComponent
          users={activeUsers}
          clients={clients}
          projects={activeProjects}
          tags={tags}
          tasks={tasks}
          teams={teams}
          filterPage={filterPage[splitBy]}
        />
      </div>

      <FormControl style={{ marginLeft: "auto" }}>
        <TeambookGroupDropdown
          options={reportingLinks}
          value={type}
          pageSplit={splitBy}
          onSelect={setSplitBy}
          setReportingPage={setReportingPage}
          reportingPage={reporting_page}
        />
      </FormControl>

      <div style={{ display: "flex", gap: 40 }}>
        <div className="reporting-control-bar__action-buttons">
          {["capacity_user", "capacity_tag"].includes(splitBy) && (
            <WhiteTooltip
              title={splitBy === "capacity_user" ? t("dashboard.split_by_tags") : t("dashboard.split_by_users")}
            >
              <div>
                <TeambookIconButton
                  name={splitBy === "capacity_user" ? icons.TAGS : icons.SINGLE_USER}
                  onClick={() => setSplitBy(splitBy === "capacity_user" ? "capacity_tag" : "capacity_user")}
                />
              </div>
            </WhiteTooltip>
          )}

          <WhiteTooltip title={t("dashboard.export_data")}>
            <div>
              <TeambookIconButton name={icons.EXPORT_ARROW} onClick={() => setDownloadWindowOpen(true)} />
            </div>
          </WhiteTooltip>
        </div>

        {type === "capacity" && (
          <TeambookIconButton
            name={icons.EYE_OPENED}
            onClick={openVisualSettings}
            id={"settings"}
            className={"mobile_hidden"}
            tooltipTitle={t("planning.control_panel.visual_settings")}
          />
        )}

        <AnalyticsSelect currentPage="reporting" moveToDashboard={moveToDashboard} />
      </div>

      <CapacityVisualSettings
        open={visualSettingsOpened}
        closeWindow={closeVisualSettings}
        selectedViewType={selectedViewType}
        setSelectedViewType={setSelectedViewType}
        page="reporting"
      />

      {downloadWindowOpen && (
        <DownloadReportingWindow
          splitBy={splitBy}
          setDownloadWindowOpen={setDownloadWindowOpen}
          date={from.start}
          teams={selectedTeams}
          type={type}
          slaveDataType={slaveDataType}
        />
      )}
    </div>
  );
};

ReportingControlBar.propTypes = {
  from: PropTypes.object.isRequired,
  setFrom: PropTypes.func.isRequired,
  selectedTeams: PropTypes.array.isRequired,
  splitBy: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  slaveDataType: PropTypes.string.isRequired,
  setReportingPage: PropTypes.func.isRequired,
  reporting_page: PropTypes.number.isRequired,
  clients: PropTypes.array.isRequired,
  activeProjects: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  selectedViewType: PropTypes.string.isRequired,
  setSelectedViewType: PropTypes.func.isRequired,
  setVisualSettingsOpened: PropTypes.func.isRequired,
  range: PropTypes.number.isRequired,
  setRange: PropTypes.func.isRequired,
  setSplitBy: PropTypes.func.isRequired,
  activeUsers: PropTypes.array.isRequired,
};
export default ReportingControlBar;
