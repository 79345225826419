import { isToday } from "date-fns";
import { DaysOfTheWeek } from "../../../../../lib/DateUtils";
import PropTypes from "prop-types";

const DaysRow = ({ date, days }) => {
  let weekDays = DaysOfTheWeek("Monday");

  return (
    <div className="actuals__by-project__days-row">
      <div className="actuals__by-project__days-row__spacer" />

      <div className="actuals__by-project__days-row__days">
        {Array.from({ length: days }).map((_, index) => {
          const day = date.plus({ days: index });
          const isWeekend = day.weekday === 6 || day.weekday === 7;

          return (
            <div
              key={`day-${index}`}
              className={`actuals__by-project__days-row__day ${
                isToday(day.toJSDate()) ? "days-row__today" : ""
              } second-row ${isWeekend ? "weekend" : ""} ${index === 0 ? "first-column" : ""}`}
            >
              <p className="actuals__by-project__days-row__day-text" id={`days-row-text-${index}`}>
                {weekDays[day.weekday - 1]} {day.day}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

DaysRow.propTypes = {
  date: PropTypes.shape({
    weekday: PropTypes.number.isRequired,
    plus: PropTypes.func.isRequired,
    toJSDate: PropTypes.func.isRequired,
  }).isRequired,
  days: PropTypes.number.isRequired,
};

export default DaysRow;
