import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../Api";
import { recalculateProjectCapacityArray } from "../lib/recalculateCapacityArray";
import lsKeys from "../../default_values/defaultKeys";
import { ICON_COLOR, TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { useProfileStore } from "../../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../stores/accountStore";
import { calculateDaysInMonth } from "../lib/calculateHeatStyles";

const ProjectsBlock = ({
  project,
  date,
  capacityReservations,
  user,
  updateUserReservations,
  jumpToProject,
  selectedViewType,
  isLastProject,
}) => {
  const { t } = useTranslation();
  const [calculatedDurations, setCalculatedDurations] = useState([]);

  const [profile] = useProfileStore((state) => [state.profile], shallow);
  const [account] = useAccountStore((state) => [state.account], shallow);

  const fullAccessRoles = ["admin", "planner"];
  const role = localStorage.getItem(lsKeys.ROLE);

  useEffect(() => {
    setCalculatedDurations(recalculateProjectCapacityArray(date, capacityReservations, project, selectedViewType));
  }, [capacityReservations, date, project]);

  const updateInputValue = (newValue, index) => {
    if (account.subscription.subscription_id === "Free" && account.subscription.projects_available === 10) {
      return;
    }

    let newCalculated = [...calculatedDurations];

    newCalculated[index] = newValue;

    setCalculatedDurations(newCalculated);
  }

  const updateReservation = (newValue, index) => {
    if (account.subscription.subscription_id === "Free" && account.subscription.projects_available === 10) {
      return;
    }

    const oldValue = capacityReservations
      .find((ur) => ur.user_id === user.id && ur.project_id === project.id && ur.date === date.plus({ months: index }).toISODate())
      ?.days_reserved || 0;

    if (parseInt(newValue) === parseInt(oldValue)) return;

    Api.CapacityReservations.create({
      user_id: user.id,
      project_id: project.id,
      days_reserved: newValue,
      date: date.plus({ months: index }).toISODate(),
    }).then(() => {
      updateUserReservations();
    });
  };

  const handleFocus = (event) => event.target.select();

  const userHasAccessForInput = () => {
    return fullAccessRoles.includes(role) || (role === "self_planner" && profile.id === user.id);
  };

  return (
    <div className="flex project-row">
      <div style={{ borderBottomLeftRadius: isLastProject ? 7 : 0 }} className="users-block__project-info-main">
        <div
          className="flex arrow-name"
          onClick={() => jumpToProject(project.id)}
          title={t("planning.capacity.go_to_project")}
        >
          <div style={{ background: project.color }} className="project-list__project-color" />

          <p>{project.name}</p>

          <TeambookIcon
            className="cursor-pointer"
            name={icons.ARROW_DOWN}
            color={ICON_COLOR.ALTERNATIVE}
            style={{ transform: "rotate(-90deg)", margin: "0px -7px 0px auto" }}
          />
        </div>
      </div>

      {[...Array(selectedViewType)].map((x, i, arr) => {
        const userTotalDays = calculateDaysInMonth(date.plus({ months: i }), user, account);
        const monthCapacity = parseInt(calculatedDurations[i]);

        return (
          <div
            style={{
              borderBottomRightRadius: isLastProject && arr.length - 1 === i && 7,
              borderRight:
                arr.length - 1 === i
                  ? "1px solid var(--stroke)"
                  : date.plus({ months: i }).month === 12
                  ? "1px solid var(--text-12)"
                  : "1px solid var(--stroke)",
            }}
            className="table-cell"
          >
            {userHasAccessForInput() ? (
              <input
                onChange={(event) => updateInputValue(event.target.value >= 0 ? event.target.value : 0, i)}
                onBlur={(event) => updateReservation(event.target.value >= 0 ? event.target.value : 0, i)}
                onWheel={(e) => e.target.blur()}
                value={monthCapacity ? monthCapacity : userTotalDays === 0 ? "" : 0}
                placeholder={userTotalDays === 0 ? "-" : "0"}
                style={{
                  width: "100%",
                  borderLeft: i === 0 && "1px solid transparent",
                  borderBottomRightRadius: isLastProject && arr.length - 1 === i && 7,
                }}
                type={"number"}
                onFocus={handleFocus}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => e.preventDefault()}
              />
            ) : (
              <p>{parseInt(calculatedDurations[i])}</p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ProjectsBlock;
