import { WhiteTooltip } from "../../../default_components/Tooltips";
import { useTranslation } from "react-i18next";
import { isoCodes } from "../../../default_values/defaultDateFormats";
import { useTeambookDataStore } from "../../../../stores/users";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../../stores/accountStore";

const linkToAnchor = (text) => {
  let urlRegex = /(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%.]+/gi;
  return (text = text.replace(
    urlRegex,
    (url) => '<a rel="noopener noreferrer" href="http://' + url + '">' + url + "</a>"
  ));
};

const ShowFields = (name, value, type, userTeams) => {
  const { t } = useTranslation();
  const [user] = useTeambookDataStore((state) => [state.chosenUser], shallow);
  const [account] = useAccountStore((state) => [state.account], shallow);
  const [tags] = useTeambookDataStore((state) => [state.tags], shallow);

  if (type === "teams") {
    return (
      <>
        <p className="user-form__field">{name}</p>

        <div className="edit-form__teams">
          {[...userTeams].slice(0, 3).map((team) => (
            <WhiteTooltip key={`team-${team?.id}`} title={team?.name}>
              <div key={`team-div-${team?.id}`} className="user-form__team">
                <p>{team?.name}</p>
              </div>
            </WhiteTooltip>
          ))}

          {userTeams.length - 3 > 0 && (
            <WhiteTooltip
              title={
                <div
                  style={{
                    flexWrap: "nowrap",
                    maxHeight: 250,
                    overflow: "auto",
                  }}
                  className="tooltip_teams"
                >
                  {[...userTeams].slice(3, userTeams.length).map((team) => (
                    <div key={`tooltip-team-${team?.id}`} style={{ minHeight: 24 }} className="user-form__team">
                      <p>{team?.name}</p>
                    </div>
                  ))}
                </div>
              }
            >
              <div className="user-form__team extra_teams">+{userTeams.length - 3}</div>
            </WhiteTooltip>
          )}
        </div>
      </>
    );
  } else if (type === "text") {
    return (
      <>
        <p className="user-form__field ">{name}</p>

        <p className="user-form__value">{value}</p>
      </>
    );
  } else if (type === "email") {
    return (
      <>
        <p className="user-form__field">{name}</p>
        <p className="user-form__value">{user.email || "-"}</p>
      </>
    );
  } else if (type === "array") {
    return (
      <>
        <p className="user-form__field">{name}</p>
        <p className="user-form__value">{value || "-"}</p>
      </>
    );
  } else if (type === "departments") {
    const [departments] = useTeambookDataStore((state) => [state.departments], shallow);
    const department = departments.find((d) => d.name === value);

    return (
      <>
        <p className="user-form__field">{name}</p>
        <div className="user-form__department-name">
          {department && (
            <div className="user-form__department-box" style={{ backgroundColor: department.color }}></div>
          )}
          <p className="user-form__value">{value || "-"}</p>
        </div>
      </>
    );
  } else if (type === "role") {
    return (
      <>
        <p className="user-form__field">{name}</p>
        <p className="user-form__value">{value || "-"}</p>
      </>
    );
  } else if (type === "phone") {
    return (
      <>
        <p className="user-form__field">{name}</p>
        <p className="user-form__value">{value || "-"}</p>
      </>
    );
  } else if (type === "tags") {
    return (
      <>
        <p className="user-form__field">{t("users.tags")}</p>

        <div className="edit-form__teams">
          {[...user.tags]
            .filter((tag) => tags.some((t) => t.id === tag.id))
            .slice(0, 3)
            .map(({ id, name, color }) => (
              <div
                key={`tag-${id}`}
                style={{
                  backgroundColor: color + "66",
                  border: "2px solid " + color,
                  color: "var(--text-10)",
                  whiteSpace: "nowrap",
                  maxHeight: "26px",
                }}
                className="user-form__team tag"
              >
                <p>{name}</p>
              </div>
            ))}

          {user.tags.length - 3 > 0 && (
            <WhiteTooltip
              title={
                <div
                  style={{
                    flexWrap: "nowrap",
                    maxHeight: 250,
                    overflow: "auto",
                  }}
                  className="tooltip_teams"
                >
                  {[...user.tags].slice(3, user.tags.length).map((tag) => (
                    <div
                      key={`tooltip-tag-${tag.id}`}
                      style={{ backgroundColor: tag.color + "66", border: "2px solid " + tag.color, minHeight: 24 }}
                      className="user-form__team"
                    >
                      <p>{tag?.name}</p>
                    </div>
                  ))}
                </div>
              }
            >
              <div className="user-form__team extra_teams">+{user.tags.length - 3}</div>
            </WhiteTooltip>
          )}

          {user.tags.length === 0 && "-"}
        </div>
      </>
    );
  } else if (type === "date") {
    return (
      <>
        <p className="user-form__field ">{name}</p>

        <p className="user-form__value">
          {value ? new Date(value).toLocaleDateString(isoCodes(account.date_format)) : "-"}
        </p>
      </>
    );
  } else if (type === "richText") {
    return (
      <>
        <p className="user-form__field ">{name}</p>

        <p className="user-form__value">
          <span
            style={{
              wordBreak: "break-word",
              textAlign: "left",
            }}
            dangerouslySetInnerHTML={{
              __html: linkToAnchor(!value || value === "" ? "<p>-</p>" : value) || "<p>-</p>",
            }}
          />
        </p>
      </>
    );
  }
};

export default ShowFields;
