import React, { useState } from "react";
import TeambookTextForm from "../../default_components/TeambookTextForm";
import Api from "../../../Api";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import { useTranslation } from "react-i18next";
import { ResetPasswordDialog } from "./ResetPasswordDialog";
import { EmailChangingModal } from "./modals/EmailChangingModal";
import { EmailFinishChangingModal } from "./modals/EmailFinishChangingModal";
import { PasswordChangingModal } from "./modals/PasswordChangingModal";
import { useProfileStore } from "../../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../../stores/notificationStore";

const EmailPasswordUpdateForm = (props) => {
  const [user, fetchProfile] = useProfileStore((state) => [state.profile, state.fetchProfile], shallow);
  const [newEmail, setNewEmail] = useState("");
  const [emailChangingMenuOpen, setEmailChangingMenuOpen] = useState(false);
  const [tokenMenuOpen, setTokenMenuOpen] = useState(false);
  const [code, setCode] = useState("");
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConf, setNewPasswordConf] = useState("");
  const [passwordChangingMenuOpen, setPasswordChangingMenuOpen] = useState(false);
  const [passwordRulesVisible, setPasswordRulesVisible] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confNewPasswordShown, setConfNewPasswordShown] = useState(false);

  const { t } = useTranslation();

  const closeEmailSwitchModal = () => {
    setTokenMenuOpen(false);
    setCode("");
  };

  const openEmailChangingModal = () => {
    setEmailChangingMenuOpen(true);
  };

  const openPasswordChangingModal = () => {
    Api.Users.validate_password(oldPassword)
      .then(() => {
        setPasswordChangingMenuOpen(true);
        setErrorMessage(null);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error);
        setErrorField("old_password");
      });
  };

  const closeEmailChangingModal = () => {
    setEmailChangingMenuOpen(false);
    closeErrorMessage();
  };

  const closePasswordChangingModal = () => {
    setPasswordChangingMenuOpen(false);
    setNewPassword("");
    setNewPasswordConf("");
    setErrorMessage(null);
    setPasswordRulesVisible(false);
  };

  const startEmailSwitchRequest = () => {
    Api.Users.initiate_email_switch(user.id, {
      new_email: newEmail,
    })
      .then((response) => analyzeUpdateResponse(response))
      .catch((error) => {
        setErrorMessage(error.response.data.error.message);
        setErrorField(error.response.data.error.field);
      });
  };

  const finishEmailSwitchRequest = () => {
    Api.Users.finish_email_switch(user.id, {
      new_email: newEmail,
      email_token: code,
    })
      .then((response) => analyzeEmailUpdated(response))
      .catch((error) => {
        setErrorMessage(error.response.data.error);
        setErrorField(error.response.data.fields.field);
      });
    closeErrorMessage();
  };

  const updateUserPassword = () => {
    let letterRegex = /.*[^0-9].*/;
    let numberRegex = /.*[0-9].*/;
    let lengthPassword = newPassword.length >= 8;
    let letterRule = letterRegex.test(newPassword);
    let numberRule = numberRegex.test(newPassword);

    const isPasswordCorrect = lengthPassword && letterRule && numberRule;

    if (isPasswordCorrect) {
      if (newPassword === newPasswordConf) {
        Api.Users.update(user.id, {
          password: {
            old_password: oldPassword,
            new_password: newPassword,
          },
        })
          .then((response) => analyzeUpdatePasswordResponse(response))
          .catch((error) => {
            setErrorMessage(error?.response?.data?.error?.message);
            setErrorField(error?.response?.data?.error?.field);
          });
      } else {
        setErrorMessage(t("profile.password.confirmation_error"));
        setErrorField("confirmation_password");
      }
    } else {
      setErrorMessage("Please check your password to fit all rules.");
      setErrorField("confirmation_password");
    }
  };

  const closeResetPasswordDialog = () => {
    setForgotPasswordOpen(false);
  };

  const openForgotPasswordDialog = () => {
    Api.Auth.forgot_password(user.email).then(() => {
      setForgotPasswordOpen(true);
    });
  };

  const analyzeUpdateResponse = (response) => {
    setTokenMenuOpen(true);
    closeErrorMessage();
    closeEmailChangingModal();
  };

  const analyzeUpdatePasswordResponse = (response) => {
    closePasswordChangingModal();
    setOldPassword("");
    setPasswordRulesVisible(false);
    setNewPassword("");
    setNewPasswordConf("");
    fetchProfile();
    closeErrorMessage();
    setInAppNotification(t("notifications.profile.email_password.password_update"));
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
    setErrorField(null);
  };

  const analyzeEmailUpdated = (response) => {
    setCode("");
    setNewEmail("");
    setTokenMenuOpen(false);
    fetchProfile();
    setInAppNotification(t("notifications.profile.email_password.email_update"));
  };

  const switchEmailModals = () => {
    startEmailSwitchRequest();
  };

  return (
    <div className="credentials-form">
      <div className="credentials__email">
        <div className="credentials__email-info">
          <p className="email-info__title">{t("profile.email.current_email")}</p>
          <p className="email-info__mail">{user.email}</p>
        </div>

        <TeambookBlueButton
          onClick={openEmailChangingModal}
          variant="contained"
          text={t("profile.email.change_email")}
        />
      </div>

      <div className="credentials__password">
        <div className="credentials__password-info">
          <TeambookTextForm
            fieldName={t("profile.password.current_password")}
            onChange={setOldPassword}
            fieldValue={oldPassword}
            isPassword={true}
            showPass={passwordShown}
            setShowPass={() => setPasswordShown(!passwordShown)}
            errorField={errorField}
            errorMessage={errorMessage}
            field={"old_password"}
            inputBottomOffset={"20px"}
          />
          <p className="forgot-password-text" onClick={openForgotPasswordDialog}>
            {t("auth.forgot_password")}
          </p>

          {forgotPasswordOpen && (
            <ResetPasswordDialog closeResetPasswordDialog={closeResetPasswordDialog} email={user.email} />
          )}
        </div>

        <TeambookBlueButton
          onClick={openPasswordChangingModal}
          variant="contained"
          text={t("profile.password.change_password")}
        />
      </div>

      <PasswordChangingModal
        passwordChangingMenuOpen={passwordChangingMenuOpen}
        closePasswordChangingModal={closePasswordChangingModal}
        setNewPassword={setNewPassword}
        newPassword={newPassword}
        newPasswordShown={newPasswordShown}
        setNewPasswordShown={setNewPasswordShown}
        setPasswordRulesVisible={setPasswordRulesVisible}
        setNewPasswordConf={setNewPasswordConf}
        newPasswordConf={newPasswordConf}
        confNewPasswordShown={confNewPasswordShown}
        setConfNewPasswordShown={setConfNewPasswordShown}
        passwordRulesVisible={passwordRulesVisible}
        errorMessage={errorMessage}
        errorField={errorField}
        closeErrorMessage={closeErrorMessage}
        updateUserPassword={updateUserPassword}
      />

      <EmailChangingModal
        closeEmailChangingModal={closeEmailChangingModal}
        emailChangingMenuOpen={emailChangingMenuOpen}
        setNewEmail={setNewEmail}
        newEmail={newEmail}
        errorMessage={errorMessage}
        errorField={errorField}
        closeErrorMessage={closeErrorMessage}
        switchEmailModals={switchEmailModals}
      />
      <EmailFinishChangingModal
        tokenMenuOpen={tokenMenuOpen}
        closeEmailSwitchModal={closeEmailSwitchModal}
        newEmail={newEmail}
        setCode={setCode}
        code={code}
        errorMessage={errorMessage}
        errorField={errorField}
        closeErrorMessage={closeErrorMessage}
        finishEmailSwitchRequest={finishEmailSwitchRequest}
      />
    </div>
  );
};

export default EmailPasswordUpdateForm;
