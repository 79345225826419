import { useTranslation } from "react-i18next";
import { FullDaysOfTheWeek } from "../../../../../lib/DateUtils";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";
import { isToday, isWeekend } from "date-fns";
import CreateLogWindow from "../month/CreateLogWindow";
import EditTimeLoggingWindow from "../month/EditTimeLoggingWindow";
import $ from "jquery";
import { useAccountStore } from "../../../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import PropTypes from "prop-types";

export const Week = ({
  startDate,
  logs,
  projects,
  createOccupation,
  createActivity,
  occupations,
  activities,
  updateActuals,
  changeContextMenu,
  copyLogRequest,
  copyEnabled,
  showWeekendLogs,
  changeTooltip,
}) => {
  const { t } = useTranslation();

  const [createWindowOpened, setCreateWindowOpened] = useState(false);
  const [editWindowOpened, setEditWindowOpened] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [editingLog, setEditingLog] = useState(null);
  const [creationDate, setCreationDate] = useState(null);
  const [maximumDurationForWeek, setMaximumDurationForWeek] = useState(480);

  const [account] = useAccountStore((state) => [state.account], shallow);

  useEffect(() => {
    const weekSpreadOfDurations = [0, 1, 2, 3, 4, 5, 6].map((weekday) => {
      return logs
        .filter((log) => {
          return (
            DateTime.fromISO(log.date) >= startDate.plus({ day: weekday }) &&
            DateTime.fromISO(log.date) <= startDate.plus({ day: weekday })
          );
        })
        .reduce((prev, cur) => prev + cur.duration, 0);
    });

    setMaximumDurationForWeek(Math.max(...weekSpreadOfDurations));
  }, []);

  const projectFor = (log) => {
    return projects.find((p) => p.id === log.project_id);
  };

  const startLogCreation = (date) => {
    if (!copyEnabled) {
      setCreateWindowOpened(true);
      setCreationDate(date);
    }
  };

  const openLog = (log) => {
    setEditingLog(log);
    setEditWindowOpened(true);
  };

  return (
    <div className="actuals__week-view-container">
      <div className="week-view__total-cell">
        <p className="week-view__day-header">{t("actuals.total")}</p>

        <div className="week-view__total-value">
          <p>
            {logs
              .filter((log) => DateTime.fromISO(log.date).startOf("week").ts === startDate.startOf("week").ts)
              .reduce((ps, a) => ps + a.duration, 0) / 60}
            h
          </p>
        </div>
      </div>

      <div className="week-view__days-container flex">
        {FullDaysOfTheWeek(account.first_week_day, showWeekendLogs).map((day, i) => {
          const logDay = startDate.plus({ day: i });
          const dayLogs = logs.filter((log) => {
            return log.date === logDay.toISODate();
          });

          const dayLogsDuration = dayLogs.reduce((prev, cur) => prev + cur.duration, 0);

          const getHeight = (log) => {
            return (log.duration / Math.max(maximumDurationForWeek, 480)) * 100;
          };

          const getEmptyHeight = () => {
            return (
              ((Math.max(maximumDurationForWeek, 480) - dayLogs.reduce((prev, log) => prev + log.duration, 0)) /
                Math.max(maximumDurationForWeek, 480)) *
              100
            );
          };

          const dayWidth = {
            maxWidth: (showWeekendLogs ? 100 / 7 : 100 / 5).toString() + "%",
            width: (showWeekendLogs ? 100 / 7 : 100 / 5).toString() + "%",
            minWidth: (showWeekendLogs ? 100 / 7 : 100 / 5).toString() + "%",
          };

          return (
            <div
              className="week-view__day-cell"
              style={dayWidth}
              weekend={isWeekend(logDay.toJSDate()) && "true"}
              today={isToday(logDay.toJSDate() || isSelected) && "true"}
            >
              <p className="week-view__day-header">{day}</p>

              <div className="week-view__day-data" onClick={() => copyLogRequest(logDay)}>
                <div className="week-view__day-data-row">
                  <div className="week-view__day">
                    <p>{startDate.plus({ day: i }).toJSDate().getDate()}</p>
                  </div>
                  <div className="week-view__day-duration">
                    <p>{dayLogsDuration / 60}h</p>
                  </div>
                </div>

                <div className="week-view__day-logs">
                  {dayLogs.map((log) => (
                    <div
                      onClick={() => openLog(log)}
                      style={{
                        border: "1px solid " + projectFor(log).color,
                        height: getHeight(log) + "%",
                        backgroundColor: log.approved ? projectFor(log).color : "unset",
                        borderStyle: log.created_with === "ui" ? "solid" : "dashed",
                        borderWidth: log.created_with === "ui" ? "1px" : "2px",
                      }}
                      key={log.id}
                      className="week-view__day-log pointer"
                      onMouseEnter={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        let elemOffset = $(e.currentTarget);
                        changeTooltip({
                          open: true,
                          bookingDuration: log.duration,
                          comment: log.comment,
                          projectName: projectFor(log)?.name || "",
                          projectCode: projectFor(log)?.code || "",
                          occupation: occupations.find((occupation) => occupation.id === log.task_id)?.name || "",
                          activity: activities.find((activity) => activity.id === log.payroll_item_id)?.name || "",
                          positionX: elemOffset.offset().left + elemOffset.width() / 2,
                          positionY: elemOffset.offset().top,
                        });
                      }}
                      onMouseLeave={() => {
                        changeTooltip({ open: false });
                      }}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        changeContextMenu({
                          open: true,
                          pos: { x: e.pageX, y: e.pageY },
                          log: log,
                        });
                      }}
                    >
                      <div className="week-view__log-data">
                        <p className="week-view__log-project-name">{projectFor(log).name}</p>
                        <p className="week-view__log-duration">{log.duration / 60}h</p>
                      </div>
                    </div>
                  ))}

                  <div
                    className="actual-log-empty-space"
                    onClick={() => startLogCreation(logDay)}
                    style={{ height: `${getEmptyHeight()}%` }}
                  />
                </div>

                <div onClick={() => startLogCreation(logDay)} className="week-view__create-log-button pointer">
                  <TeambookIcon name={icons.ADD} style={{ width: 20, height: 20 }} />
                  <p>{t("actuals.log_time")}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {createWindowOpened && (
        <CreateLogWindow
          setIsSelected={setIsSelected}
          projects={projects}
          updateActuals={updateActuals}
          date={creationDate}
          activities={activities}
          tasks={occupations}
          setOpen={setCreateWindowOpened}
        />
      )}

      {editWindowOpened && (
        <EditTimeLoggingWindow
          setIsSelected={setIsSelected}
          projects={projects}
          editingLog={editingLog}
          updateActuals={updateActuals}
          activities={activities}
          occupations={occupations}
          createActivity={createActivity}
          createOccupation={createOccupation}
          setOpen={setEditWindowOpened}
        />
      )}
    </div>
  );
};

Week.propTypes = {
  startDate: PropTypes.instanceOf(DateTime), // Assuming it's a Date object
  logs: PropTypes.array,
  projects: PropTypes.array,
  createOccupation: PropTypes.func,
  createActivity: PropTypes.func,
  occupations: PropTypes.array,
  activities: PropTypes.array,
  updateActuals: PropTypes.func,
  changeContextMenu: PropTypes.func,
  copyLogRequest: PropTypes.func,
  copyEnabled: PropTypes.bool,
  showWeekendLogs: PropTypes.bool,
  changeTooltip: PropTypes.func,
};
