import { WhiteTooltip } from "../../../default_components/Tooltips";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Api from "../../../../Api";
import CreatePayroll from "./modals/CreatePayroll";
import EditPayroll from "./modals/EditPayroll";
import DeletePayroll from "./modals/DeletePayroll";
import { alphabeticalSort } from "../lib/alpha_sort";
import { icons } from "../../../default_images/IconsList";
import TeambookIconButton from "../../../default_components/TeambookIconButton";

const PayrollItems = () => {
  const { t } = useTranslation();

  const [payrollItems, setPayrollItems] = useState([]);
  const [openPayrollCreation, setOpenPayrollCreation] = useState(false);
  const [openPayrollEditing, setOpenPayrollEditing] = useState(false);
  const [openPayrollDeletion, setOpenPayrollDeletion] = useState(false);
  const [selectedPayroll, setSelectedPayroll] = useState({});

  useEffect(() => {
    getPayrolls();
  }, []);

  const getPayrolls = () => {
    Api.PayrollItems.get().then((response) => setPayrollItems(alphabeticalSort(response.data, "a")));
  };

  const openCreation = () => {
    setOpenPayrollCreation(true);
  };

  const openEditing = (payroll) => {
    setOpenPayrollEditing(true);
    setSelectedPayroll(payroll);
  };

  const openDeleting = (payroll) => {
    setOpenPayrollDeletion(true);
    setSelectedPayroll(payroll);
  };

  return (
    <>
      <div className="time-off__control-row" style={{ marginTop: "80px" }}>
        <p className="tb__text__light-black">{t("settings.tasks.payroll_management")}</p>

        <WhiteTooltip title={t("settings.tasks.create_payroll")}>
          <div onClick={openCreation}>
            <TeambookIconButton name={icons.ADD} isGray />
          </div>
        </WhiteTooltip>
      </div>

      <div className="time-off__scroll-component">
        {payrollItems.map((payrollItem) => (
          <div className="time-off__block" key={payrollItem.name + payrollItem.id} aria-label="blockHoliday">
            <div className="time-off__description">
              <p>{payrollItem.name}</p>
            </div>

            <div className="time-off__block-content">
              <WhiteTooltip title={t("edit")}>
                <div onClick={() => openEditing(payrollItem)}>
                  <TeambookIconButton name={icons.PENCIL} />
                </div>
              </WhiteTooltip>

              <WhiteTooltip title={t("delete")}>
                <div onClick={() => openDeleting(payrollItem.id)}>
                  <TeambookIconButton name={icons.TRASH} />
                </div>
              </WhiteTooltip>
            </div>
          </div>
        ))}
      </div>

      {openPayrollCreation && (
        <CreatePayroll open={openPayrollCreation} setOpen={setOpenPayrollCreation} getPayrolls={getPayrolls} />
      )}

      {openPayrollEditing && (
        <EditPayroll
          open={openPayrollEditing}
          setOpen={setOpenPayrollEditing}
          getProjects={getPayrolls}
          selectedTimeOff={selectedPayroll}
        />
      )}

      {openPayrollDeletion && (
        <DeletePayroll
          open={openPayrollDeletion}
          onCloseSelectedModal={() => setOpenPayrollDeletion(false)}
          getPayrolls={getPayrolls}
          selectedPayroll={selectedPayroll}
        />
      )}
    </>
  );
};

export default PayrollItems;
