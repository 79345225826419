import { useState } from "react";
import { useTranslation } from "react-i18next";
import TeambookFilterComponent from "../default_components/teambook_filter/TeambookFilterComponent";
import { icons } from "../default_images/IconsList";
import CapacitySplitSelector from "./lib/CapacitySplitSelector";
import XLSExport from "../../lib/XLSExport";
import { TeambookMonthPicker } from "../default_components/TeambookMonthPicker";
import { useCapacityStore } from "../../stores/capacity";
import { shallow } from "zustand/shallow";
import CapacityVisualSettings from "./CapacityVisualSettings";
import { TeambookTeamsPicker } from "../default_components/TeambookDefaultPickers";
import { TodayButton } from "../default_components/TodayButton";
import TeambookIconButton from "../default_components/TeambookIconButton";
import PropTypes from "prop-types";

const ControlBar = ({
  selectedDate,
  setSelectedDate,
  changeSelectedType,
  selectedType,
  users,
  teams,
  projects,
  tags,
  clients,
  businessUnits,
  managers,
  setSelectedViewType,
  selectedViewType,
  copyPlanner,
  revertAction,
}) => {
  const { t } = useTranslation();
  const [visualSettingsOpened, setVisualSettingsOpened] = useState(false);

  const [showTimeOffs, setShowTimeOffs] = useCapacityStore(
    (state) => [state.showTimeOffs, state.setShowTimeOffs],
    shallow
  );

  const openVisualSettings = () => {
    setVisualSettingsOpened(true);
  };

  const closeVisualSettings = () => {
    setVisualSettingsOpened(false);
  };

  const exportCapacity = () => {
    XLSExport.ExportCapacity({
      from: selectedDate.startOf("month"),
      to: selectedDate.startOf("month").plus({ year: 2 }),
    });
  };

  const capacityFilterPage = {
    user: "userCapacity",
    project: "projectCapacity",
  };

  return (
    <div className="capacity__control-bar">
      <div className="capacity__control-bar__splitter" style={{ flex: 1 }}>
        <TodayButton page="capacity" setDate={setSelectedDate} />

        <TeambookMonthPicker width={142} currentDate={selectedDate} setCurrentDate={setSelectedDate} />

        <div className="planner-control__filter-text-field capacity mobile_hidden">
          <TeambookFilterComponent
            users={users}
            clients={clients}
            projects={projects}
            tags={tags}
            teams={teams.map((team) => ({
              ...team,
              team_users: users.filter((user) => team.team_users.includes(user.id)),
            }))}
            managers={managers}
            businessUnits={businessUnits}
            filterPage={capacityFilterPage[selectedType]}
          />
        </div>

        <div className="control-bar__left-side">
          {teams.length > 1 && (
            <div className="disabled">
              <TeambookTeamsPicker
                disabled
                classNamePrefix={"tb-react-planner-selector"}
                teams={teams}
                value={teams[0]}
                onChange={() => {}}
                width={128}
                height={40}
                isSearchable={false}
              />
            </div>
          )}
          <div style={{ marginLeft: "auto" }} className="planner-control-bar__action-buttons">
            <TeambookIconButton name={icons.REVERT} onClick={() => revertAction()} />

            {copyPlanner && selectedType === "user" && (
              <TeambookIconButton name={icons.COPY} tooltipTitle={t("actuals.copy_planner")} onClick={copyPlanner} />
            )}

            <TeambookIconButton name={icons.PRINT} className="disabled" onClick={exportCapacity} />
          </div>

          <TeambookIconButton
            name={icons.EYE_OPENED}
            tooltipTitle={t("planning.control_panel.visual_settings")}
            onClick={openVisualSettings}
            id={"capacity-visual-settings"}
          />

          <CapacitySplitSelector
            className="mobile_hidden"
            pageName={t("planning.select_by_capacity")}
            onSelect={changeSelectedType}
            selectedType={selectedType}
          />
        </div>

        <CapacityVisualSettings
          open={visualSettingsOpened}
          showTimeOff={showTimeOffs}
          setShowTimeOff={setShowTimeOffs}
          selectedViewType={selectedViewType}
          setSelectedViewType={setSelectedViewType}
          closeWindow={closeVisualSettings}
        />
      </div>
    </div>
  );
};

ControlBar.propTypes = {
  selectedDate: PropTypes.object.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  changeSelectedType: PropTypes.func.isRequired,
  selectedType: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  businessUnits: PropTypes.array.isRequired,
  managers: PropTypes.array.isRequired,
  setSelectedViewType: PropTypes.func.isRequired,
  selectedViewType: PropTypes.string.isRequired,
  copyPlanner: PropTypes.func,
  revertAction: PropTypes.func,
};

export default ControlBar;
