import { useTranslation } from "react-i18next";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Api from "../../../../../Api";
import TeambookTextForm from "../../../../default_components/TeambookTextForm";
import TeambookBlueButton from "../../../../default_components/TeambookBlueButton";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../../../../stores/notificationStore";
import PropTypes from "prop-types";

export default function EditTask({ getProjects, open, setOpen, selectedTimeOff }) {
  const { t } = useTranslation();
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [name, setName] = useState(selectedTimeOff.name);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);

  const handleSubmit = () => {
    Api.Tasks.update(selectedTimeOff.id, name)
      .then(() => {
        handleClose();
        setErrorMessage(null);
        setInAppNotification(t("notifications.settings.timeoff_holidays.time_off.edit"));
      })
      .catch((error) => analyzeCreationError(error));
  };

  const handleClose = () => {
    getProjects();

    setOpen(false);
    setName("");
    setErrorMessage(null);
    setErrorField(null);
  };

  const analyzeCreationError = (error) => {
    setErrorMessage(error.response.data.error.message);
    setErrorField(error.response.data.error.field);
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
    setErrorField(null);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"xs"}
      className="tb-default-dialog"
    >
      <DialogTitle id="form-dialog-title">
        <p> {t("settings.tasks.edit_task")}</p>
        <TeambookIcon name={icons.CROSS} onClick={handleClose} />
      </DialogTitle>
      <DialogContent>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TeambookTextForm
              id={"nameTimeOff"}
              fieldName={t("settings.time_off.to_name")}
              onChange={(text) => setName(text.length > 0 ? text[0].toUpperCase() + text.slice(1) : "")}
              type={"text"}
              fieldValue={name}
              width={"100%"}
              errorField={errorField}
              errorMessage={errorMessage}
              field={"name"}
              closeErrorMessage={closeErrorMessage}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <TeambookBlueButton text={t("save")} onClick={handleSubmit} id="saveEditTimeOffButton" />
      </DialogActions>
    </Dialog>
  );
}

EditTask.propTypes = {
  getProjects: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedTimeOff: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
