import React, { useEffect, useState } from "react";
import Api from "../../Api";
import "./register.scss";
import "../default_styles/tb-colors.css";
import { Navigate, useParams } from "react-router-dom";
import TeambookTextForm from "../default_components/TeambookTextForm";
import TeambookBlueButton from "../default_components/TeambookBlueButton";
import { useTranslation } from "react-i18next";
import { languages } from "../default_values/Languages";
import LightblueCheck from "../default_images/lightblue_check.svg";
import { TeambookCheckbox } from "../default_components/TeambookCheckbox";
import PasswordRules from "./PasswordRules";
import { useProfileStore } from "../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../stores/accountStore";
import { ICON_COLOR, TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";

const RegisterInvited = () => {
  const { token, lang } = useParams();
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);

  const [acceptedMarketing, setAcceptedMarketing] = useState(false);
  const { t, i18n } = useTranslation();
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordRulesVisible, setPasswordRulesVisible] = useState(false);
  const [fetchProfile] = useProfileStore((state) => [state.fetchProfile], shallow);
  const [fetchAccount] = useAccountStore((state) => [state.fetchAccount], shallow);

  useEffect(() => {
    if (lang && languages.includes(lang)) {
      localStorage.setItem("tb-lang", lang);
      i18n.changeLanguage(lang || "en");
    }

    if (localStorage.getItem("tb-lang")) {
      i18n.changeLanguage(localStorage.getItem("tb-lang") || "en");
    }
  }, []);

  const closeErrorMessage = () => {
    setErrorMessage(null);
    setErrorField(null);
  };

  const submit = () => {
    let letterRegex = /.*[^0-9].*/;
    let numberRegex = /.*[0-9].*/;
    let lengthPassword = password.length >= 8;
    let letterRule = letterRegex.test(password);
    let numberRule = numberRegex.test(password);

    if (lengthPassword && letterRule && numberRule) {
      Api.Users.finish_registration(token, password, acceptedMarketing)
        .then((response) => {
          fetchAccount();
          fetchProfile();
          setRedirect(true);
        })
        .catch((error) => {
          setErrorMessage(error.response.data.error.message);
          setErrorField("password");
        });
    }
  };

  const switchPasswordShow = () => {
    setPasswordShown(!passwordShown);
  };

  const PasswordRulesShow = () => {
    setPasswordRulesVisible(true);
  };

  if (redirect) {
    return <Navigate to="/planners" />;
  }

  const proposals = [t("auth.proposal_1"), t("auth.proposal_2"), t("auth.proposal_3")];

  return (
    <div className="register">
      <div className="register__left-side mobile_hidden">
        <div className="register__proposal">
          <p className="if">{t("auth.if").toUpperCase()}</p>

          <div className="register__proposals">
            {proposals.map((text) => (
              <div
                style={{
                  display: "flex",
                  userSelect: "none",
                  marginBottom: 13,
                  gap: 10,
                }}
              >
                <img src={LightblueCheck} />
                <p style={{ margin: 0 }}> {text}</p>
              </div>
            ))}
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
            <p style={{ marginBottom: 5 }} className="then">
              {t("auth.then").toUpperCase()}
            </p>
            <p style={{ margin: 0 }}>{t("auth.proposal_4")}</p>
          </div>
        </div>
        <div className="register__left-side-images">
          <img src={process.env.PUBLIC_URL + "/logo.png"} className="register__tb-logo" />

          <TeambookIcon
            name={icons.NEW_REGISTER_BG}
            color={ICON_COLOR.ALTERNATIVE}
            className="register__left-side-image"
            style={{
              left: 0,
              right: 0,
              margin: "0 auto",
            }}
          />
        </div>
      </div>
      <div className="register__right-side">
        <img
          src={process.env.PUBLIC_URL + "/logo.png"}
          className="login__tb-logo desktop_hidden"
          style={{ top: 40, left: 30, position: "absolute" }}
        />

        <div className="register__form" style={{ top: 180 }}>
          <p className="register__input-side__register-text">{t("auth.finish_sign")}</p>

          <div
            style={{
              display: "flex",
              position: "relative",
              margin: "30px 0px 6px 0px",
            }}
          >
            <TeambookTextForm
              fieldName={t("auth.password")}
              onChange={(text) => setPassword(text)}
              fieldValue={password}
              width={"100%"}
              onFocus={PasswordRulesShow}
              isPassword={true}
              setShowPass={switchPasswordShow}
              showPass={passwordShown}
              errorField={errorField}
              errorMessage={errorMessage}
              field={"password"}
              closeErrorMessage={closeErrorMessage}
            />

            {/* <img
              alt={"eye"}
              style={{
                position: "absolute",
                bottom: 18,
                right: 15,
                cursor: "pointer",
              }}
              onClick={switchPasswordShow}
              src={EyeImage}
            /> */}
          </div>

          <PasswordRules password={password} visible={passwordRulesVisible} />

          <p
            style={{
              fontSize: 12,
              marginRight: 10,
              whiteSpace: "nowrap",
              margin: "30px 0px 10px 0px",
              color: "var(--text-2)",
            }}
            onChange={() => setAcceptedMarketing(!acceptedMarketing)}
          >
            {t("auth.by_clicking_1")}&nbsp;
            <a href={t("auth.link_1")} target="_blank" rel="noopener noreferrer">
              {t("auth.by_clicking_2")}
            </a>
            &nbsp;{t("auth.and")}&nbsp;
            <br />
            <a href={t("auth.link_2")} target="_blank" rel="noopener noreferrer">
              {t("auth.by_clicking_3")}
            </a>
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 13,
              marginBottom: 10,
            }}
          >
            <TeambookCheckbox
              checked={acceptedMarketing}
              onChange={() => setAcceptedMarketing(!acceptedMarketing)}
              id="marketing-accept-checkbox"
            />
            <p
              onClick={() => setAcceptedMarketing(!acceptedMarketing)}
              style={{
                fontSize: 12,
                margin: 0,
                userSelect: "none",
                color: "var(--text-2)",
              }}
            >
              {t("auth.accept_marketing")}
            </p>
          </div>

          <TeambookBlueButton style={{ width: "100%" }} text={t("auth.sign_up")} onClick={submit} />
        </div>
      </div>
      {/* <div className="register__input-side">
        <div className="register__input-side__logo">
          <img
            alt={"logo"}
            src={process.env.PUBLIC_URL + "/logo.png"}
            className="logo"
          />
        </div>

        <RegisterComponent>
          <RegisterForm className="invitation_input">
            <RegisterText>{t("auth.finish_sign")}</RegisterText>

            <div>
              <TeambookTextForm
                fieldName={t("auth.password")}
                onChange={(text) => setPassword(text)}
                type={"password"}
                fieldValue={password}
                width={"100%"}
              />
            </div>

            {errorMessage !== null ? (
              <FormErrorMessage
                text={errorMessage}
                closeErrorMessage={closeErrorMessage}
                style={{ marginBottom: "20px" }}
              />
            ) : null}

            <RegisterCheckboxComponent
              style={{
                display: "flex",
                margin: "16px 0",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div style={{ display: "flex" }}>
                <p style={{ fontSize: "14px", marginRight: "10px" }}>
                  {t("auth.by_clicking_1")}
                </p>
                <a
                  href={t("auth.link_1")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("auth.by_clicking_2")}
                </a>
              </div>
              <div style={{ display: "flex" }}>
                <p style={{ margin: "0 10px 0 0" }}>{t("auth.and")}</p>
                <a
                  href={t("auth.link_2")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("auth.by_clicking_3")}
                </a>
              </div>
            </RegisterCheckboxComponent>

            <RegisterCheckboxComponent>
              <Checkbox
                checked={acceptedMarketing}
                onChange={(value) => setAcceptedMarketing(value.target.checked)}
                color="primary"
              />
              <p
                onClick={() => {
                  setAcceptedMarketing(!acceptedMarketing);
                }}
                style={{ fontSize: "14px" }}
              >
                {t("auth.accept_marketing")}
              </p>
            </RegisterCheckboxComponent>

            <TeambookBlueButton text={t("auth.sign_up")} onClick={submit} />
          </RegisterForm>
        </RegisterComponent>
      </div>

      <div className="register__bg-image mobile_hidden" /> */}
    </div>
  );
};

export default RegisterInvited;
