import React from "react";
import NotEnoughData from "../../default_images/need_more_data.svg";
import { useTranslation } from "react-i18next";
import { ICON_COLOR, TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const NoBookingsFiltered = () => {
  const { t } = useTranslation();

  return (
    <div className="no-bookings-filtered">
      <TeambookIcon name={icons.EMPTY_FILTER_IMAGE} color={ICON_COLOR.ALTERNATIVE} />

      <p>{t("users.users_not_found")}</p>
    </div>
  );
};

export default NoBookingsFiltered;
