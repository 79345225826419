import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import CopyPlannerWindow from "./CopyPlannerWindow";
import { WhiteTooltip } from "../../default_components/Tooltips";
import BulkApproveWindow from "../approval/BulkApproveWindow";
import UploadActualsDialog from "../approval/UploadActualsDialog";
import TeambookFilterComponent from "../../default_components/teambook_filter/TeambookFilterComponent";
import TeambookDatePicker from "../../default_components/teambook_date_selector/DatePicker";
import { icons } from "../../default_images/IconsList";
import { ActualsSelect } from "./ActualsSelect";
import { TeambookTeamsPicker } from "../../default_components/TeambookDefaultPickers";
import ActualsVisualSettings from "./ActualsVisualSettings";
import { ActualsContext } from "../context/ActualsContext";
import { shallow } from "zustand/shallow";
import { useProfileStore } from "../../../stores/profileStore";
import { TodayButton } from "../../default_components/TodayButton";
import { useActualsStore } from "../../../stores/actualsStore";
import TeambookIconButton from "../../default_components/TeambookIconButton";
import PropTypes from "prop-types";

const ActualsControlBar = ({
  setDate,
  date,
  approvalMode,
  reportedHours,
  approvedHours,
  updateActuals,
  teams = [],
  users = [],
  cancelCopy = () => {},
  projects,
  splitBy,
  tags,
  setActualsZoom,
  disableTooltip,
  setDisableTooltip,
  showWeekendLogs,
  setShowWeekendLogs,
}) => {
  const { t } = useTranslation();
  const { zoom } = useContext(ActualsContext);

  const [copyPlannerOpened, setCopyPlannerOpened] = useState(false);
  const [bulkApproveOpen, setBulkApproveOpen] = useState(false);
  const [uploadLogsOpen, setUploadLogsOpen] = useState(false);
  const [visualSettingsOpened, setVisualSettingsOpened] = useState(false);

  const [profile] = useProfileStore((state) => [state.profile], shallow);

  const calculateWorkingHours = () => {
    if (profile.custom_working_hours) return profile.custom_working_hours;

    const firstDateOfMonth = date.startOf("month");
    let workingHoursInMonth = 0;

    const daysInCalc = zoom === "week" ? 7 : date.endOf("month").day;

    for (let i = 0; i < daysInCalc; i++) {
      const dateSchedule = profile.schedule[firstDateOfMonth.plus({ day: i }).weekday % 7];

      workingHoursInMonth += dateSchedule[0] + dateSchedule[2];
    }

    return workingHoursInMonth / 60;
  };

  const calculateControlBarStyle = () => {
    if (!approvalMode) {
      return {
        padding: "10px 30px 10px 30px",
      };
    } else {
      return {
        padding: "10px 30px 10px 30px",
      };
    }
  };

  const openVisualSettings = () => {
    setVisualSettingsOpened(true);
  };

  const closeVisualSettings = () => {
    setVisualSettingsOpened(false);
  };

  return (
    <div style={calculateControlBarStyle()} className="actuals__control-bar">
      {!approvalMode ? (
        <>
          <div style={{ marginRight: "12px" }}>
            <TodayButton actualsZoom={zoom} page="actuals_timesheet" setDate={setDate} />
          </div>

          <TeambookDatePicker
            from={date}
            to={zoom === "week" ? date.endOf("week") : undefined}
            formatStart={"MMM yyyy"}
            onDayClick={(date) => {
              setDate(date.startOf(zoom));
            }}
            onArrowClicks={{
              left: () => {
                if (zoom === "month") {
                  setDate(date.minus({ month: 1 }));
                } else {
                  setDate(date.minus({ week: 1 }));
                }
                cancelCopy();
              },
              right: () => {
                if (zoom === "month") {
                  setDate(date.plus({ month: 1 }));
                } else {
                  setDate(date.plus({ week: 1 }));
                }
                cancelCopy();
              },
            }}
            range={1}
            closeOnSelect={true}
            setFrom={setDate}
            type="default"
            highlight={zoom === "week" ? "week" : "today"}
            disabledRanges={["1_year"]}
          />

          <div className="actuals__stats">
            <div className="actuals__monthly actuals__stat">
              <p className="actuals__hours">{calculateWorkingHours()} h</p>
              <p className="actuals_stat-type">{t("actuals.monthly_working")}</p>
            </div>
            <div className="actuals__reported actuals__stat">
              <p className="actuals__hours">{reportedHours} h</p>
              <p className="actuals_stat-type">{t("actuals.reported")}</p>
            </div>
            <div className="actuals__approved actuals__stat">
              <p className="actuals__hours">{approvedHours} h</p>
              <p className="actuals_stat-type">{t("actuals.approved")}</p>
            </div>
          </div>

          <div style={{ marginLeft: "auto", flex: "unset" }} className="actuals__approval-block">
            {useActualsStore.getState().timeLoggingMode !== "default" && (
              <WhiteTooltip
                title={t("planning.add_projects")}
                style={{ marginLeft: "auto" }}
                className="actuals__download-button"
              >
                <div>
                  <TeambookIconButton
                    name={icons.ADD_PLUS}
                    onClick={() => {
                      useActualsStore.getState().setTimesheetAddProjectOpen(true);
                    }}
                  />
                </div>
              </WhiteTooltip>
            )}

            <WhiteTooltip
              title={t("planning.control_panel.revert_action")}
              style={{ marginLeft: "auto" }}
              className="actuals__download-button"
            >
              <div>
                <TeambookIconButton name={icons.REVERT} className="disabled" onClick={() => {}} />
              </div>
            </WhiteTooltip>

            <WhiteTooltip title={t("actuals.copy_planner")} className="actuals__download-button">
              <div>
                <TeambookIconButton name={icons.COPY} onClick={() => setCopyPlannerOpened(true)} />
              </div>
            </WhiteTooltip>

            <WhiteTooltip title={t("actuals.bulk_approve")} className="actuals__download-button">
              <div>
                <TeambookIconButton name={icons.PRINT} className="disabled" onClick={() => {}} />
              </div>
            </WhiteTooltip>

            <WhiteTooltip title={t("planning.control_panel.visual_settings")}>
              <div style={{ marginLeft: 40 }}>
                <TeambookIconButton
                  name={icons.EYE_OPENED}
                  id={"actuals-visual-settings"}
                  onClick={openVisualSettings}
                />
              </div>
            </WhiteTooltip>
          </div>

          <ActualsSelect style={{ marginLeft: 40 }} approvalPage={approvalMode} />

          {copyPlannerOpened && (
            <CopyPlannerWindow
              setCopyPlannerOpened={setCopyPlannerOpened}
              date={date}
              updateActuals={updateActuals}
              teams={teams}
              users={users}
            />
          )}
        </>
      ) : (
        <>
          <div style={{ marginRight: 40 }} className="actuals__arrow-block actuals__approval-arrow-block">
            <TodayButton setDate={setDate} />

            <TeambookDatePicker
              from={date}
              formatStart={"MMM yyyy"}
              onDayClick={(date) => {
                setDate(date.startOf("month"));
              }}
              onArrowClicks={{
                left: () => {
                  setDate(date.minus({ month: 1 }));
                  cancelCopy();
                },
                right: () => {
                  setDate(date.plus({ month: 1 }));
                  cancelCopy();
                },
              }}
              range={1}
              setFrom={setDate}
              closeOnSelect={true}
              type="default"
              height={40}
              highlight="today"
              disabledRanges={["1_year"]}
            />
          </div>

          <div className="actuals__filter-component" style={{ marginRight: 40 }}>
            <TeambookFilterComponent
              {...{
                projects: splitBy === "by_user" ? [] : projects,
                users: splitBy === "by_user" ? users : [],
                teams: splitBy === "by_user" ? teams : [],
                tags: splitBy === "by_user" ? tags : [],
              }}
              filterPage={"actuals"}
            />
          </div>

          <div className="actuals__approval-block">
            {teams.length > 1 && (
              <div style={{ marginRight: 40 }} className="disabled">
                <TeambookTeamsPicker
                  disabled
                  classNamePrefix={"tb-react-planner-selector"}
                  teams={teams}
                  value={teams[0]}
                  onChange={() => {}}
                  width={128}
                  height={40}
                  isSearchable={false}
                />
              </div>
            )}

            <WhiteTooltip className="actuals__download-button" title={t("planning.control_panel.revert_action")}>
              <div style={{ marginLeft: "auto" }}>
                <TeambookIconButton
                  name={icons.REVERT}
                  className="disabled"
                  onClick={() => setCopyPlannerOpened(true)}
                />
              </div>
            </WhiteTooltip>

            <WhiteTooltip title={t("actuals.copy_planner")} className="actuals__download-button">
              <div>
                <TeambookIconButton
                  name={icons.COPY}
                  id="actuals-copy-planner"
                  onClick={() => setCopyPlannerOpened(true)}
                />
              </div>
            </WhiteTooltip>

            <WhiteTooltip title={t("actuals.bulk_approve")} className="actuals__download-button">
              <div>
                <TeambookIconButton
                  name={icons.CHECK}
                  id="actuals-bulk-approve"
                  onClick={() => setBulkApproveOpen(true)}
                />
              </div>
            </WhiteTooltip>

            <WhiteTooltip title={t("actuals.upload_logs")} className="actuals__download-button">
              <div style={{ marginRight: 40 }}>
                <TeambookIconButton name={icons.UPLOAD} onClick={() => setUploadLogsOpen(true)} />
              </div>
            </WhiteTooltip>

            <ActualsSelect approvalPage={approvalMode} />
          </div>
        </>
      )}

      {bulkApproveOpen && (
        <BulkApproveWindow
          users={users}
          teams={teams}
          date={date}
          setBulkApproveOpen={setBulkApproveOpen}
          updateActuals={updateActuals}
        />
      )}

      {copyPlannerOpened && (
        <CopyPlannerWindow
          setCopyPlannerOpened={setCopyPlannerOpened}
          date={date}
          updateActuals={updateActuals}
          teams={teams}
          users={users}
          approvalMode={approvalMode}
        />
      )}

      {uploadLogsOpen && <UploadActualsDialog setUploadLogsOpen={setUploadLogsOpen} updateActuals={updateActuals} />}

      <ActualsVisualSettings
        open={visualSettingsOpened}
        closeWindow={closeVisualSettings}
        setActualsZoom={setActualsZoom}
        disableTooltip={disableTooltip}
        setDisableTooltip={setDisableTooltip}
        showWeekendLogs={showWeekendLogs}
        setShowWeekendLogs={setShowWeekendLogs}
      />
    </div>
  );
};

ActualsControlBar.propTypes = {
  setDate: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  approvalMode: PropTypes.bool.isRequired,
  reportedHours: PropTypes.number.isRequired,
  approvedHours: PropTypes.number.isRequired,
  updateActuals: PropTypes.func.isRequired,
  teams: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  cancelCopy: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired,
  splitBy: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  setActualsZoom: PropTypes.func.isRequired,
  disableTooltip: PropTypes.bool.isRequired,
  setDisableTooltip: PropTypes.func.isRequired,
  showWeekendLogs: PropTypes.bool.isRequired,
  setShowWeekendLogs: PropTypes.func.isRequired,
};

export default ActualsControlBar;
