import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Api from "../../../../Api";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import GetAllMilestones from "../../requests/GetAllMilestones";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/fr";
import { getUtcTime, ToDateString } from "../../../../lib/DateUtils";
import { DialogTitle } from "@mui/material";
import lsKeys from "../../../default_values/defaultKeys";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { icons } from "../../../default_images/IconsList";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { DateTime } from "luxon";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../../stores/accountStore";
import TeambookDatePicker from "../../../default_components/teambook_date_selector/DatePicker";

const MilestoneEditing = ({ milestone, setEditingMilestone, setMilestones }) => {
  const { t } = useTranslation();

  const canEditMilestone = !["regular", "self_planner", "contractor"].includes(localStorage.getItem(lsKeys.ROLE));
  const [milestoneName, setMilestoneName] = useState(milestone.name);
  const [milestoneStartDate, setMilestoneStartDate] = useState(DateTime.fromISO(milestone.start_date));
  const [milestoneEndDate, setMilestoneEndDate] = useState(DateTime.fromISO(milestone.end_date));
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const isCorrectDates = milestoneStartDate <= milestoneEndDate;

  const [account] = useAccountStore((state) => [state.account], shallow);

  const closeErrorMessage = () => {
    setErrorMessage(null);
    setErrorField(null);
  };

  if (account) {
    moment.updateLocale("en", {
      week: {
        dow: account.first_week_day === "Monday" ? 1 : 0,
      },
    });
  }

  const updateMilestone = () => {
    if (isCorrectDates) {
      setErrorMessage(null);
      setErrorField(null);
      if (milestoneName.length < 3 || milestoneName.length > 30) {
        setErrorMessage("Milestone name must be 3 to 30 characters long.");
        setErrorField("name");
      } else {
        Api.Milestones.update({
          id: milestone.id,
          name: milestoneName,
          start_date: milestoneStartDate,
          end_date: milestoneEndDate,
        })
          .then(() => {
            GetAllMilestones(setMilestones);
            setEditingMilestone(null);
          })
          .catch((error) => {
            setErrorMessage(error.response.data.error);
            setErrorField(null);
          });
      }
    }
  };

  const deleteMilestone = () => {
    Api.Milestones.delete(milestone.id).then(() => {
      GetAllMilestones(setMilestones);
      setEditingMilestone(null);
    });
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        setEditingMilestone(null);
        setErrorMessage(null);
        setErrorField(null);
      }}
      aria-labelledby="deactivation-dialog"
      fullWidth={true}
      sx={{
        "& .MuiDialog-paper": {
          width: 490,
          height: 294,
        },
      }}
      className="tb-default-dialog milestone-modal"
    >
      <DialogTitle id="add-user-dialog">
        <p>{t("planning.milestones.edit")}</p>
        <TeambookIcon
          name={icons.CROSS}
          onClick={() => {
            setEditingMilestone(null);
            setErrorMessage(null);
            setErrorField(null);
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div className="milestone-editing__editing">
          <TeambookTextForm
            disabled={!canEditMilestone}
            id="editingMilestoneName"
            fieldName={t("planning.milestones.name")}
            onChange={(text) => setMilestoneName(text)}
            type={"text"}
            fieldValue={milestoneName}
            width={"78%"}
            placeholder={"Milestone #1"}
            errorField={errorField}
            errorMessage={errorMessage}
            closeErrorMessage={closeErrorMessage}
            field={"name"}
          />
        </div>

        <div className="milestone-editing__info">
          <div className="milestone-editing__textfield">
            <div className="milestone-date-picker__container">
              <p>{t("projects.start_date")}</p>
              <TeambookDatePicker
                onDayClick={(date) => setMilestoneStartDate(date)}
                from={milestoneStartDate}
                range={1}
                type="default"
                variant="inline"
                formatStart={"d MMM, yyyy"}
                width={86}
                height={24}
              />
            </div>

            <div className="milestone-date-picker__container">
              <p>{t("projects.end_date")}</p>
              <TeambookDatePicker
                onDayClick={(date) => setMilestoneEndDate(date)}
                from={milestoneEndDate}
                range={1}
                type="default"
                variant="inline"
                formatStart={"d MMM, yyyy"}
                width={86}
                height={24}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ gap: 20 }}>
        {canEditMilestone && (
          <>
            <TeambookBlueButton text={t("save")} disabled={!isCorrectDates} onClick={updateMilestone} />

            <TeambookOutlinedButton text={t("delete")} onClick={deleteMilestone} />
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MilestoneEditing;
