import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "../../../Api";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import ControlPanel from "../ControlPanel";
import { DateTime } from "luxon";
import { ICON_COLOR, TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TeambookTextForm from "../../default_components/TeambookTextForm";
import MuiDatePicker from "../../default_components/MuiDatePicker";
import TeambookOutlinedButton from "../../default_components/TeambookOutlinedButton";

const ADMIN_TOKEN_KEY = "tb-admin-token";

export default function AnnualInvoicing() {
  const [createModalOpened, setCreateModalOpened] = useState(false);
  const [creationAccountId, setCreationAccountId] = useState();
  const [creationDate, setCreationDate] = useState(DateTime.now());
  const [annualInvoicing, setAnnualInvoicing] = useState([]);

  const adminToken = localStorage.getItem(ADMIN_TOKEN_KEY);
  const WIDTH = "20%";

  useEffect(() => {
    fetchAnnualBilling();
  }, []);

  const fetchAnnualBilling = () => {
    Api.Administrators.get_annual_invoices(localStorage.getItem(ADMIN_TOKEN_KEY)).then((res) => {
      setAnnualInvoicing(res.data);
    });
  };

  const updateBillingDate = (accountId) => {
    Api.Administrators.update_annual_invoice(accountId, adminToken).then(() => {
      fetchAnnualBilling();
    });
  };

  const deleteAnnualInvoice = (accountId) => {
    Api.Administrators.delete_annual_invoice(accountId, adminToken).then(() => {
      fetchAnnualBilling();
    });
  };

  const createAnnualInvoice = () => {
    Api.Administrators.create_annual_invoice(creationAccountId, creationDate, adminToken).then((response) => {
      fetchAnnualBilling();
      setCreateModalOpened(false);
      setCreationAccountId(null);
      setCreationDate(DateTime.now());
    });
  };

  return (
    <table
      style={{
        width: "99.5%",
        margin: "70px 5px",
        textAlign: "left",
      }}
      border="1px solid black"
    >
      <tr style={{ textAlign: "center" }}>
        <th style={{ width: WIDTH, padding: 10 }}>Account ID</th>
        <th style={{ width: WIDTH, padding: 10 }}>Account Name</th>
        <th style={{ width: WIDTH, padding: 10 }}>Plan</th>
        <th style={{ width: WIDTH, padding: 10 }}>Next billing cycle</th>
        <th style={{ width: WIDTH, padding: 10 }}></th>
      </tr>

      {annualInvoicing.map((annoualInvoice) => {
        return (
          <tr>
            <td style={{ width: WIDTH, padding: 10 }}>{annoualInvoice.account.id}</td>
            <td style={{ width: WIDTH, padding: 10 }}>{annoualInvoice.account.name}</td>
            <td style={{ width: WIDTH, padding: 10 }}>{annoualInvoice.account.plan_name} </td>
            <td style={{ width: WIDTH, padding: 10 }}>{annoualInvoice.billing_date}</td>
            <td style={{ width: WIDTH, padding: 10 }}>
              <TeambookBlueButton
                text={"Next billing cycle"}
                onClick={() => updateBillingDate(annoualInvoice.account.id)}
              />

              <div onClick={() => deleteAnnualInvoice(annoualInvoice.account.id)} className="teambook-icon-frame">
                <TeambookIcon
                  tooltipTitle={"DELETE"}
                  alt="delete log"
                  color={ICON_COLOR.ALTERNATIVE}
                  name={icons.TRASH}
                />
              </div>
            </td>
          </tr>
        );
      })}

      <tr>
        <TeambookBlueButton onClick={() => setCreateModalOpened(true)} text={"Create new annual record"} />
      </tr>

      <Dialog open={createModalOpened} onClose={() => setCreateModalOpened(false)}>
        <DialogTitle>New Annual Billing</DialogTitle>
        <DialogContent>
          <p>id:</p>
          <input type="number" onChange={(e) => setCreationAccountId(e.target.value)} />

          <MuiDatePicker
            variant="inline"
            format={"d MMM, yyyy"}
            value={creationDate}
            onChange={(date) => setCreationDate(date)}
            sx={{ width: "150px", input: { padding: "0px" } }}
            className="mui-date-picker"
          />
        </DialogContent>
        <DialogActions>
          <TeambookOutlinedButton onClick={() => setCreateModalOpened(false)} text={"Cancel"} />
          <TeambookBlueButton onClick={createAnnualInvoice} text={"Create"} />
        </DialogActions>
      </Dialog>
    </table>
  );
}
