import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "../../default_components/Tooltips";
import { icons } from "../../default_images/IconsList";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import lsKeys from "../../default_values/defaultKeys";

import { Link } from "react-router-dom";

export const AnalyticsSelect = ({ currentPage, moveToReporting = () => {}, moveToDashboard }) => {
  const role = localStorage.getItem(lsKeys.ROLE);
  const { t } = useTranslation();

  return (
    <div className={"planner-type-select__shown-component pointer"}>
      <div
        onClick={moveToDashboard}
        style={{
          background: currentPage === "analytics" ? "var(--stroke)" : "unset",
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
        }}
        className="planner-type-select__dropdown-option"
      >
        <Link to={"/dashboard"} style={{ textDecoration: "none" }}>
          <TeambookIcon
            style={{ width: "unset !important", height: "unset !important" }}
            name={icons.DASHBOARD}
            tooltipTitle={t("header.dashboard")}
          />
        </Link>
      </div>

      <div
        style={{
          background: currentPage === "reporting" ? "var(--stroke)" : "unset",
          borderLeft: "1px solid var(--stroke)",
        }}
        className="planner-type-select__dropdown-option"
        onClick={moveToReporting}
      >
        {/* <WhiteTooltip > */}
        <TeambookIcon
          style={{ width: 22, height: 22 }}
          name={icons.REPORTING}
          tooltipTitle={t("dashboard.reporting")}
        />
        {/* </WhiteTooltip> */}
      </div>
    </div>
  );
};
