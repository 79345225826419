import { TeambookIcon } from "../default_images/TeambookIcon";
import PropTypes from "prop-types";

const TeambookIconButton = ({
  onClick,
  style,
  className,
  name,
  iconColor = "default",
  tooltipTitle,
  isGray = false,
  id,
}) => {
  return (
    <button
      className={`teambook-icon-button ${isGray ? "teambook-icon-button__gray" : ""} ${className}`}
      onClick={onClick}
      style={{
        ...style,
      }}
      id={id}
    >
      <TeambookIcon color={iconColor} name={name} tooltipTitle={tooltipTitle} alt={name} />
    </button>
  );
};

TeambookIconButton.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  tooltipTitle: PropTypes.string,
  isGray: PropTypes.bool,
  id: PropTypes.string,
};

export default TeambookIconButton;
