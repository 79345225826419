import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import { useTranslation } from "react-i18next";

export const EmailFinishChangingModal = ({
  tokenMenuOpen,
  closeEmailSwitchModal,
  newEmail,
  setCode,
  code,
  errorMessage,
  errorField,
  closeErrorMessage,
  finishEmailSwitchRequest,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={tokenMenuOpen}
      onClose={closeEmailSwitchModal}
      aria-labelledby="email-token-dialog"
      className="tb-default-dialog credentials__email-switch__modal"
    >
      <DialogTitle id="email-token-dialog">
        <p> {t("profile.email.finish_email_switch")}</p>
        <TeambookIcon name={icons.NEW_CROSS} onClick={closeEmailSwitchModal} />
      </DialogTitle>
      <DialogContent style={{ paddingBottom: 20 }}>
        <DialogContentText>
          <p className="first-text">
            {t("profile.email.text_1")}
            <b className="first-bolder-text">{newEmail}</b>.
          </p>
          <p className="second-text">{t("profile.email.text_2")}</p>
        </DialogContentText>
        <DialogContentText>
          <TeambookTextForm
            fieldName={t("profile.email.code")}
            onChange={setCode}
            fieldValue={code}
            type={"text"}
            width={"43%"}
            errorField={errorField}
            errorMessage={errorMessage}
            closeErrorMessage={closeErrorMessage}
            field={"token"}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <TeambookBlueButton onClick={finishEmailSwitchRequest} variant="contained" text={t("submit")} />
      </DialogActions>
    </Dialog>
  );
};
