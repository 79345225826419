import React, { useState } from "react";
import TimeZones from "../../../default_values/TimeZones";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import { useTranslation } from "react-i18next";
import ShowSettings from "./ShowSettings";
import Api from "../../../../Api";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";
import { LanguagePicker } from "../../../default_components/LanguagePicker";
import { InputNumber } from "../../../default_components/InputNumber";
import TeambookRadioButton from "../../../default_components/TeambookRadioButton";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { useAccountStore } from "../../../../stores/accountStore";
import { useNotificationStore } from "../../../../stores/notificationStore";
import { shallow } from "zustand/shallow";

const formatValues = [
  {
    label: "DD/MM/YYYY",
    value: 0,
  },
  {
    label: "MM/DD/YYYY",
    value: 1,
  },
  {
    label: "YYYY/MM/DD",
    value: 2,
  },
];

const EditSettings = ({}) => {
  const { t } = useTranslation();
  const [account, fetchAccount] = useAccountStore((state) => [state.account, state.fetchAccount]);
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [defaultBookingTime, setDefaultBookingTime] = useState(account.default_booking_duration / 60);
  const [timeZone, setTimeZone] = useState(account.time_zone);
  const [firstWeekDay, setFirstWeekDay] = useState(account.first_week_day);
  const [dateFormat, setDateFormat] = useState(account.date_format);
  const [lang, setLang] = useState(account.language || "en");
  const [editing, setEditing] = useState(false);

  const [accountName, setAccountName] = useState(account.name);
  const [authType, setAuthType] = useState(account.two_factor_enabled || false);
  const [loginOption, setLoginOption] = useState(account.mandatory_login_option);
  const [capacityAutoSync, setCapacityAutoSync] = useState(account.capacity_auto_sync || false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const changeLanguage = (lng) => {
    Api.Account.update({
      account: {
        name: account.name,
        default_booking_duration: account.default_booking_duration,
        time_zone: account.time_zone,
        first_week_day: account.first_week_day,
        language: lng,
        date_format: dateFormat,
      },
    })
      .then((response) => {
        fetchAccount();
        setLang(response.data.language);
      })
      .catch((error) => console.log(error));
  };

  const submitValues = () => {
    Api.Account.update({
      account: {
        name: accountName,
        default_booking_duration: defaultBookingTime * 60,
        time_zone: timeZone,
        first_week_day: firstWeekDay,
        date_format: dateFormat,
        two_factor_enabled: authType,
        schedule: account.schedule,
        capacity_auto_sync: capacityAutoSync,
        mandatory_login_option: loginOption,
      },
    })
      .then((response) => {
        fetchAccount();
        setEditing(false);
        setInAppNotification(t("notifications.settings.general"));
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error.message);
        setErrorField("name");
      });
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
    setErrorField(null);
  };

  if (!editing) {
    return <ShowSettings setEditing={setEditing} />;
  }

  return (
    <div className="settings__edit-container">
      <div className="settings__edit-block">
        <div className="settings__edit-value">
          <TeambookTextForm
            fieldName={t("settings.general_info.account_name") + "*"}
            onChange={setAccountName}
            fieldValue={accountName}
            id={"accountName"}
            type={"text"}
            width={"320px"}
            style={{ marginTop: 0 }}
            errorField={errorField}
            errorMessage={errorMessage}
            field={"name"}
            closeErrorMessage={closeErrorMessage}
          />
        </div>

        <div className="settings__edit-value">
          <div>
            <p className="settings__edit-header__text">{t("settings.language.name")}</p>
            <LanguagePicker changeLanguage={changeLanguage} style={{ gap: 15 }} currentLang={lang} />
          </div>
        </div>

        <div className="settings__edit-value">
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            <p className="settings__edit-header__text">{t("settings.general_info.two_factor")}</p>

            {[
              { enabled: true, label: "On" },
              { enabled: false, label: "Off" },
            ].map((auth_type) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 5,
                  }}
                >
                  <TeambookRadioButton
                    onCheck={() => setAuthType(auth_type.enabled)}
                    checked={authType === auth_type.enabled}
                  />
                  <p onClick={() => setAuthType(auth_type.enabled)} style={{ marginBottom: 0, userSelect: "none" }}>
                    {auth_type.label}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="settings__edit-value">
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            <p className="settings__edit-header__text">{t("settings.general_info.login_option")}</p>

            {[
              { value: null, label: t("any") },
              { value: "azure", label: "Azure SSO" },
              { value: "google", label: "Google SSO" },
            ].map((option) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 5,
                  }}
                >
                  <TeambookRadioButton
                    onCheck={() => setLoginOption(option.value)}
                    checked={loginOption === option.value}
                  />
                  <p onClick={() => setLoginOption(option.value)} style={{ marginBottom: 0, userSelect: "none" }}>
                    {option.label}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="settings__edit-block">
        <div className="settings__edit-value">
          <p>{t("settings.general_info.default_booking_hours")}</p>
          <InputNumber
            setValue={setDefaultBookingTime}
            min={0.25}
            max={16}
            step={0.5}
            value={defaultBookingTime}
            id={"defaultBookingTime"}
            type={"number"}
            style={{ width: "130px" }}
            roundValue={{
              isRoundValue: true,
              roundStep: 4,
            }}
          />
        </div>

        <div className="settings__edit-value">
          <div>
            <p className="settings__edit-header__text">{t("settings.general_info.default_time_zone")}</p>

            <TeambookReactSelect
              id="timeZone"
              options={TimeZones.timeZonesArray}
              onChange={(time_zone) => setTimeZone(time_zone.value)}
              value={TimeZones.timeZonesArray.find((time_zone) => time_zone.value === timeZone)}
              height={44}
              width="280px"
              isSearchable={true}
              grayPlaceholder
            />
          </div>
        </div>

        <div className="settings__edit-value">
          <div>
            <p className="settings__edit-header__text">{t("settings.general_info.date_format")}</p>

            {formatValues.map((format) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 5,
                  }}
                >
                  <TeambookRadioButton
                    onCheck={() => setDateFormat(format.value)}
                    checked={dateFormat === format.value}
                  />
                  <p onClick={() => setDateFormat(format.value)} style={{ marginBottom: 0, userSelect: "none" }}>
                    {format.label}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="settings__edit-value">
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
            }}
          >
            <p className="settings__edit-header__text">{t("settings.general_info.capacity_auto_update")}</p>

            {[
              { enabled: true, label: "On" },
              { enabled: false, label: "Off" },
            ].map((sync_type) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 5,
                  }}
                >
                  <TeambookRadioButton
                    onCheck={() => setCapacityAutoSync(sync_type.enabled)}
                    checked={capacityAutoSync === sync_type.enabled}
                  />

                  <p
                    onClick={() => setCapacityAutoSync(sync_type.enabled)}
                    style={{ marginBottom: 0, userSelect: "none" }}
                  >
                    {sync_type.label}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          display: "flex",
          right: 125,
          bottom: 20,
          gap: 20,
        }}
      >
        <TeambookOutlinedButton
          onClick={() => {
            setEditing(false);
            setAccountName(account.name);
            setDefaultBookingTime(account.default_booking_duration / 60);
            setTimeZone(account.time_zone);
            setFirstWeekDay(account.first_week_day);
            setDateFormat(account.date_format);
            setLang(account.language);
            closeErrorMessage();
          }}
          text={t("cancel")}
        />

        <TeambookBlueButton onClick={submitValues} variant="contained" text={t("save")} />
      </div>
    </div>
  );
};

export default EditSettings;
