import { useTranslation } from "react-i18next";
import { icons } from "../default_images/IconsList";
import { WhiteTooltip } from "./Tooltips";
import { useZoomStore } from "../../stores/planner";
import { OffsetDays } from "../../lib/DateUtils";
import { DateTime } from "luxon";
import { offsetWeeksForPlanner } from "../planner/lib/plannerFunctions";
import TeambookIconButton from "./TeambookIconButton";
import PropTypes from "prop-types";

export const TodayButton = ({ setDate, page = "default", actualsZoom }) => {
  const { t } = useTranslation();
  const { zoom } = useZoomStore();

  const calculateToday = {
    default: () => DateTime.now().startOf("month"),
    planner: () => OffsetDays(new Date(), -1 * offsetWeeksForPlanner(zoom) * 7),
    capacity: () => DateTime.now().startOf("month"),
    actuals_timesheet: () => DateTime.now().startOf(actualsZoom),
  };

  return (
    <WhiteTooltip title={t("today")}>
      <div>
        <TeambookIconButton name={icons.TODAY} onClick={() => setDate(calculateToday[page]())} />
      </div>
    </WhiteTooltip>
  );
};

TodayButton.propTypes = {
  setDate: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  actualsZoom: PropTypes.string,
};
