import { useEffect, useState } from "react";
import Api from "../../../Api";
import UsersBlock from "./UsersBlock";
import DatesRow from "./DatesRow";
import { useTeambookFilter } from "../../../stores/planner";
import { calculateUsersFilters } from "../lib/calculateFilters";
import { useTranslation } from "react-i18next";
import CopyPlannerDialog from "./CopyPlannerDialog";
import NoBookingsFiltered from "../../planner/default_components/NoBookingsFiltered";
import { useCapacityStore } from "../../../stores/capacity";
import { shallow } from "zustand/shallow";
import PropTypes from "prop-types";

const UserPlanning = ({
  date,
  users,
  projects,
  teams,
  tags,
  jumpToProject,
  selectedUser,
  selectedViewType,
  copyPlannerOpen,
  setCopyPlannerOpen,
}) => {
  const { filterValues, filterType } = useTeambookFilter();
  const { t } = useTranslation();

  const [bookings, setBookings] = useState([]);

  const [capacityReservations, setCapacityReservations] = useCapacityStore(
    (state) => [state.userCapacity, state.setUserCapacity],
    shallow
  );
  const [usersShown, setUsersShown] = useState(users);
  const [emptyFiltered, setEmptyFiltered] = useState(usersShown.length === 0);

  useEffect(() => {
    setEmptyFiltered(usersShown.length === 0);
  }, [usersShown]);

  useEffect(() => {
    updateCapacityReservations(users);
  }, [date, users]);

  useEffect(() => {
    if (users.length > 0) {
      Api.Bookings.get(
        users.map((u) => u.id),
        date.toISODate(),
        date.plus({ months: selectedViewType }).toISODate()
      ).then((res) => {
        setBookings(res.data);
      });
    }
  }, [users, date]);

  useEffect(() => {
    if (users.length === 0) return;

    const filteredUsers = calculateUsersFilters(
      users.filter((u) => u.active),
      tags,
      filterValues,
      filterType,
      t
    );

    if (selectedUser && filteredUsers.find((user) => user.id === selectedUser)) {
      setUsersShown([
        [...filteredUsers].filter((user) => user.id === selectedUser)[0],
        ...[...filteredUsers].filter((user) => user.id !== selectedUser),
      ]);
    } else {
      setUsersShown(filteredUsers);
    }
  }, [users, filterValues, filterType, selectedUser]);

  const updateCapacityReservations = (users) => {
    if (users?.length > 0) {
      Api.CapacityReservations.get({
        user_ids: users.map((u) => u.id),
        start_date: date.toISODate(),
        end_date: date.plus({ months: selectedViewType }).toISODate(),
      }).then((res) => {
        const keptReservations = capacityReservations.filter((cr) => !users.map((u) => u.id).includes(cr.user_id));
        setCapacityReservations([...keptReservations, ...res.data]);
      });
    }
  };

  return (
    <div className="capacity__component">
      {emptyFiltered && filterValues.length > 0 ? (
        <NoBookingsFiltered />
      ) : (
        <>
          <DatesRow date={date} selectedViewType={selectedViewType} />

          {usersShown.map((user, i, arr) => (
            <UsersBlock
              key={`user-${user.id}-${bookings.length}`}
              user={user}
              bookings={bookings.filter((b) => b.user_id === user.id)}
              projects={projects}
              date={date}
              capacityReservations={capacityReservations.filter((cr) => cr.user_id === user.id)}
              jumpToProject={jumpToProject}
              selectedViewType={selectedViewType}
              usersShown={usersShown}
              isLastUser={i === arr.length - 1}
            />
          ))}
        </>
      )}

      {copyPlannerOpen && (
        <CopyPlannerDialog
          users={users}
          teams={teams}
          date={date}
          setDialogOpened={setCopyPlannerOpen}
          updateReservations={updateCapacityReservations}
        />
      )}
    </div>
  );
};

UserPlanning.propTypes = {
  date: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  jumpToProject: PropTypes.func.isRequired,
  selectedUser: PropTypes.number,
  selectedViewType: PropTypes.number.isRequired,
  copyPlannerOpen: PropTypes.bool.isRequired,
  setCopyPlannerOpen: PropTypes.func.isRequired,
};

export default UserPlanning;
