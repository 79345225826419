import { DAY_VIEW_HEIGHT, DAY_VIEW_WIDTH } from "../../../../lib/PlannerUtils";
import { useBookingsCreatingStore, useSelectedProjectStore } from "../../../../stores/planner";
import { shallow } from "zustand/shallow";
import PropTypes from "prop-types";
const DayViewCreation = ({ hour }) => {
  const { selectedProject, selectedTimeOff, projectsType } = useSelectedProjectStore();
  const [bookingsDuration] = useBookingsCreatingStore((state) => [state.bookingsDuration], shallow);

  const currentProject = projectsType === "default" ? selectedProject : selectedTimeOff;

  const calculateLeftOffset = () => {
    return hour * DAY_VIEW_WIDTH;
  };

  const calculateHeight = () => {
    return DAY_VIEW_HEIGHT;
  };

  const calculateWidth = () => {
    return bookingsDuration * DAY_VIEW_WIDTH;
  };

  return (
    <div
      style={{
        width: `${calculateWidth()}px`,
        left: `${calculateLeftOffset()}px`,
        height: `${calculateHeight()}px`,
        top: `0px`,
        backgroundColor: currentProject?.color,
        position: "absolute",
      }}
    ></div>
  );
};

DayViewCreation.propTypes = {
  hour: PropTypes.number.isRequired,
};

export default DayViewCreation;
