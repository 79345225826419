import "./default_styles.scss";
import { useRef, useState } from "react";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import PropTypes from "prop-types";
import { ClickAwayListener } from "@mui/material";

const DEFAULT_ROUND_VALUE = {
  isRoundValue: false,
  roundStep: 0,
};

export const InputNumber = ({
  value,
  setValue,
  max,
  min,
  step = 0.5,
  style,
  roundValue = DEFAULT_ROUND_VALUE,
  onKeyDown = () => {},
  showDropdown = false,
  dropdownOptions = [],
  arrowSize = 24,
}) => {
  const ref = useRef();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className={`input-number__container ${showDropdown ? "input-number__container--dropdown" : ""}`}>
      <input
        onClick={() => {
          if (showDropdown) {
            setDropdownOpen(!dropdownOpen);
          }
        }}
        onKeyDown={onKeyDown}
        onMouseDown={(e) => {
          if (showDropdown) {
            e.preventDefault();
          }
        }}
        ref={ref}
        style={style}
        max={max}
        min={min}
        step={step}
        value={value}
        onChange={() => {
          const val = ref.current.value;

          setValue(val);
        }}
        onBlur={() => {
          const val = +ref.current.value;

          if (val < min) {
            setValue(min);
          } else if (val > max) {
            setValue(max);
          } else if (roundValue.isRoundValue) {
            setValue(Number(Math.round(val * roundValue.roundStep) / roundValue.roundStep).toString());
          }
        }}
        type="number"
        className="input-number__field"
      />
      <div className="input-number__arrows">
        <TeambookIcon
          name={icons.ARROW_UP}
          style={{
            width: arrowSize,
            height: arrowSize,
          }}
          onClick={() => {
            const val = +ref.current.value;

            if (val + step <= max) {
              setValue(val + step);
            } else {
              setValue(val);
            }
          }}
        />
        <TeambookIcon
          name={icons.ARROW_DOWN}
          style={{
            width: arrowSize,
            height: arrowSize,
          }}
          onClick={() => {
            const val = +ref.current.value;

            if (val - step >= min) {
              setValue(val - step);
            } else {
              setValue(val);
            }
          }}
        />
      </div>
      {dropdownOpen && (
        <div
          className="input-number__dropdown"
          style={{
            top: 0,
            left: ref.current.offsetLeft,
            width: ref.current.offsetWidth,
          }}
        >
          <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
            <div className="input-number__dropdown__items">
              {dropdownOptions.map((option, index) => (
                <div
                  className="input-number__dropdown__item"
                  key={index}
                  onClick={() => {
                    setValue(option.value);
                    setDropdownOpen(false);
                  }}
                >
                  <p>{option.value || ""}</p>
                </div>
              ))}
            </div>
          </ClickAwayListener>
        </div>
      )}
    </div>
  );
};

InputNumber.propTypes = {
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  showDropdown: PropTypes.bool,
  roundValue: PropTypes.object,
  onKeyDown: PropTypes.func,
  style: PropTypes.object,
  arrowSize: PropTypes.number,
  dropdownOptions: PropTypes.array,
};
