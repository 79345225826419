import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TeambookRedButton from "../../../default_components/TeambookRedButton";
import { useTranslation } from "react-i18next";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";

export const DeleteTaskWarning = ({ close, onYes = () => {}, onNo = () => {}, task }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={true} onClose={close} className="tb-default-dialog">
      <DialogTitle style={{ padding: 0 }}>
        <p>{t("dashboard.task_deletion")}</p>

        <TeambookIcon name={icons.CROSS} onClick={close} />
      </DialogTitle>

      <DialogContent
        style={{
          height: 210,
          width: 600,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TeambookIcon
          style={{ width: 60, height: 60, margin: "20px auto" }}
          name={icons.WARNING_TRIANGLE}
          color="common"
        />

        <div className="delete-task-confirmation__text">
          <p style={{ margin: 0 }}>{t("dashboard.task_deletion_confirmation")}</p>

          <div className="flex">
            <p className="delete-task-confirmation__task-name">{task.name}</p>
            <p>?</p>
          </div>
        </div>
      </DialogContent>

      <DialogActions style={{ gap: 20 }}>
        <TeambookRedButton
          onClick={() => {
            onYes(task.value);
            close();
          }}
          text={t("delete")}
          underlineText={false}
        />
        <TeambookOutlinedButton
          onClick={() => {
            onNo();
            close();
          }}
          text={t("cancel")}
        />
      </DialogActions>
    </Dialog>
  );
};
