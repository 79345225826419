import $ from "jquery";

const removeHTMLTags = (str) => {
  return str.replace(/(<([^>]+)>)/gi, " ");
};

const positionStyles = {
  left: {
    triangle: { left: "18px", right: "auto", transform: "none" },
    triangleBg: { left: "18px", right: "auto", transform: "scale(1.11)" },
    stub: { left: "18px", right: "auto" },
  },
  right: {
    triangle: { left: "auto", right: "18px", transform: "none" },
    triangleBg: { left: "auto", right: "18px", transform: "scale(1.11)" },
    stub: { left: "auto", right: "18px" },
  },
  center: {
    triangle: { left: "50%", right: "auto", transform: "translateX(-50%)" },
    triangleBg: { left: "50%", right: "auto", transform: "translateX(-50%) scale(1.11)" },
    stub: { left: "50%", right: "auto", transform: "translateX(-50%)" },
  },
};

const applyPositionStyles = ($triangle, $triangleBg, $stub, position) => {
  const styles = positionStyles[position] || positionStyles.center;
  $triangle.css(styles.triangle);
  $triangleBg.css(styles.triangleBg);
  $stub.css(styles.stub);
};

const TooltipChanger = (newTooltip) => {
  const $tooltip = $("#tooltip");
  const $triangle = $tooltip.find(".tooltip-component__triangle");
  const $triangleBg = $tooltip.find(".tooltip-component__triangle_bg");
  const $stub = $tooltip.find(".tooltip-component__stub");

  const tooltipTopOffset = newTooltip.positionY - 245 < 98 ? 80 : newTooltip.positionY - 245;

  $tooltip.css({
    display: newTooltip.open ? "block" : "none",
    top: `${tooltipTopOffset}px`,
    left: `${newTooltip.positionX}px`,
  });

  applyPositionStyles($triangle, $triangleBg, $stub, newTooltip.position);

  if (newTooltip.open) {
    $("#tooltip-projectName").text(newTooltip.projectName || "");
    $("#tooltip-bookingDuration").text(newTooltip.bookingDuration ? `${newTooltip.bookingDuration / 60}h` : "");

    const $comment = $("#tooltip-comment");
    const commentText = newTooltip.comment ? removeHTMLTags(newTooltip.comment) : "";
    $comment.text(commentText);
    $comment.closest(".tooltip-component__comment").css("display", commentText ? "flex" : "none");

    const $team = $("#tooltip-team");
    const teamText = newTooltip.team?.name || "";
    $team.text(teamText);
    $team.closest(".tooltip-component__team").css("display", teamText ? "flex" : "none");

    const $clientName = $("#tooltip-clientName");
    const clientText = newTooltip.clientName || "";
    $clientName.text(clientText);
    $clientName.closest(".tooltip-component__client-name").css("display", clientText ? "flex" : "none");

    const taskText = newTooltip.task?.id ? newTooltip.task.name : "";
    const $taskName = $("#tooltip-taskName");
    $taskName.text(taskText);
    $taskName.closest(".tooltip-component__task-name").css("display", taskText ? "flex" : "none");
  }
};

export default TooltipChanger;
