import { useEffect, useState } from "react";
import { getWeekNumber, OffsetDays } from "../../../../../lib/DateUtils";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import lsKeys from "../../../../default_values/defaultKeys";
import { icons } from "../../../../default_images/IconsList";
import TeambookIconButton from "../../../../default_components/TeambookIconButton";
import PropTypes from "prop-types";
const WeekRow = ({ stats, sortOrder, setSortOrder, setSlaveDataType, splitBy, slaveDataType }) => {
  const [weekColumns, setWeekColumns] = useState([]);
  const [monthColumns, setMonthColumns] = useState([]);
  const lang = localStorage.getItem(lsKeys.LANGUAGE);
  const { t } = useTranslation();

  const dividingButton = () => {
    if (slaveDataType === "default") {
      return (
        <TeambookIconButton
          name={icons.TASKS}
          tooltipTitle={t("dashboard.divide_by_tasks")}
          onClick={() => setSlaveDataType("tasks")}
        />
      );
    } else {
      return splitBy === "user" ? (
        <TeambookIconButton
          name={icons.PROJECT}
          tooltipTitle={t("dashboard.divide_by_projects")}
          onClick={() => setSlaveDataType("default")}
        />
      ) : (
        <TeambookIconButton
          name={icons.SINGLE_USER}
          tooltipTitle={t("dashboard.divide_by_users")}
          onClick={() => setSlaveDataType("default")}
        />
      );
    }
  };

  useEffect(() => {
    let columns = [];
    let months = [];
    let startDate = new Date(stats.start_date);
    let currentDate = startDate;

    for (let i = 0; i < stats.amount_of_days; i++) {
      if (currentDate.getDate() === 10 || currentDate.getDate() === 25) {
        months.push(
          <td className="week-row__table-column" style={{ height: 44, borderTop: "unset" }}>
            <p className="week-text">{DateTime.fromJSDate(currentDate).setLocale(lang).monthLong}</p>
          </td>
        );
      } else {
        months.push(<td className="week-row__table-column" style={{ borderTop: "unset" }} />);
      }

      if (currentDate.getDay() === 4) {
        columns.push(
          <td className="week-row__table-column" style={{ height: 44 }}>
            <p className="week-text">
              {t("dashboard.week")}
              {` ${getWeekNumber(currentDate)}`}
            </p>
          </td>
        );
      } else if (currentDate.getDay() === 0) {
        columns.push(<td className="week-row__table-column" style={{ borderRight: "1px solid var(--stroke)" }} />);
      } else {
        columns.push(<td className="week-row__table-column" />);
      }

      currentDate = OffsetDays(currentDate, 1);
    }

    columns.push(
      <td className="totals-columns">
        <p className="totals-div__fixed-text">{t("dashboard.totals")}</p>
      </td>
    );

    months.push(<td className="totals-columns" style={{ border: "unset" }}></td>);

    setWeekColumns(columns);
    setMonthColumns(months);
  }, [stats]);

  return (
    <>
      <tr
        style={{
          height: 60,
          borderRight: "1px solid var(--stroke)",
        }}
        className="reporting-week-row"
      >
        <td className="week-row__fixed-column">
          <div className="reporting__sort">
            <TeambookIconButton
              name={sortOrder === "a" ? icons.SORT_A : icons.SORT_Z}
              tooltipTitle={sortOrder === "a" ? t("dashboard.a_z") : t("dashboard.z_a")}
              onClick={() => setSortOrder(sortOrder === "a" ? "z" : "a")}
            />
            {dividingButton()}
          </div>
        </td>
        {monthColumns}
      </tr>
      <tr className="reporting-week-row" style={{ top: 60 }}>
        <td
          className="week-row__fixed-column"
          style={{ borderLeft: "1px solid var(--stroke)", borderTop: "1px solid var(--stroke)" }}
        />

        {weekColumns}
      </tr>
    </>
  );
};

WeekRow.propTypes = {
  stats: PropTypes.object.isRequired,
  sortOrder: PropTypes.string.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  setSlaveDataType: PropTypes.func.isRequired,
  splitBy: PropTypes.string.isRequired,
  slaveDataType: PropTypes.string.isRequired,
};

export default WeekRow;
