import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import { useTranslation } from "react-i18next";

export const EmailChangingModal = ({
  closeEmailChangingModal,
  emailChangingMenuOpen,
  setNewEmail,
  newEmail,
  errorMessage,
  errorField,
  closeErrorMessage,
  switchEmailModals,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={emailChangingMenuOpen}
      onClose={() => {
        closeEmailChangingModal();
        setNewEmail("");
      }}
      aria-labelledby="email-token-dialog"
      className="tb-default-dialog credentials__email-changing__modal"
    >
      <DialogTitle id="email-token-dialog">
        <p> {t("profile.email.email_changing")}</p>
        <TeambookIcon
          name={icons.NEW_CROSS}
          onClick={() => {
            closeEmailChangingModal();
            setNewEmail("");
          }}
        />
      </DialogTitle>
      <DialogContent style={{ paddingBottom: 20 }}>
        <DialogContentText>
          <p>{t("profile.email.enter_new_mail")}</p>
        </DialogContentText>
        <TeambookTextForm
          fieldName={t("profile.email.new_email")}
          onChange={setNewEmail}
          fieldValue={newEmail}
          id={"newEmail"}
          type={"text"}
          width={"270px"}
          errorField={errorField}
          errorMessage={errorMessage}
          closeErrorMessage={closeErrorMessage}
          field={"new_email"}
        />
      </DialogContent>
      <DialogActions>
        <TeambookBlueButton onClick={switchEmailModals} variant="contained" text={t("submit")} />
      </DialogActions>
    </Dialog>
  );
};
