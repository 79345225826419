import { WhiteTooltip } from "../../../default_components/Tooltips";
import { SliceStringWithDots } from "../../../../lib/StringFormat";

const activateUsers = (t, setDangerDialogOpen, setDangerDialogProps, user, selectedUsers, activateUserRequest) => {
  setDangerDialogOpen(true);

  setDangerDialogProps({
    actionRequest: activateUserRequest,
    buttonText: t("users.reactivate"),
    contentText:
      selectedUsers.length === 1 || user ? (
        <>
          <p>
            {t("users.activate_confirmation_1")}
            <b>{selectedUsers[0]?.name || user?.name || ""}</b>
            {t("users.activate_confirmation_2")}
          </p>
        </>
      ) : (
        <>
          <p>
            {t("users.multiple_activate_confirmation_1")}
            <WhiteTooltip
              placement="top"
              title={selectedUsers
                .slice(0, 2)
                .map(({ name }) => SliceStringWithDots(name, 16))
                .toString()}
            >
              <b style={{ cursor: "pointer" }}>
                {t("users.selected")}
                {selectedUsers.length}
              </b>
            </WhiteTooltip>
            {t("users.multiple_activate_confirmation_2")}
          </p>
        </>
      ),
  });
};

export default activateUsers;
