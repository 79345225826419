import { getNumberOfDays, getCellWidthForZoom, OffsetDays } from "../../../../lib/DateUtils";
import { GROUPED_PLANNER_USER_WIDTH, getPlannerOffsetX } from "../../../../lib/PlannerUtils";
import { useDateStore, useMilestonesStore, useZoomStore } from "../../../../stores/planner";
import { shallow } from "zustand/shallow";
import PropTypes from "prop-types";
import { forwardRef, useImperativeHandle, useState } from "react";

export const GroupedCreationMilestone = forwardRef(
  ({ weekendsHidden, milestoneProject, projectMouseMoved, projectMouseUped, groupClients, index }, ref) => {
    const milestoneOrder = useMilestonesStore.getState().creationOrder;

    const userRole = localStorage.getItem("tb-role") || "regular";
    const [zoom] = useZoomStore((state) => [state.zoom], shallow);
    const [date] = useDateStore((state) => [state.date], shallow);
    const [dateStart, setDateStart] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);

    useImperativeHandle(ref, () => ({
      setDateStart,
      setDateEnd,
      dateStart,
      dateEnd,
    }));

    const calculateLeftOffset = () => {
      if (!dateStart || !dateEnd) return 0;

      let dateOffset = getNumberOfDays(date, dateStart.toJSDate());
      let finalOffset = GROUPED_PLANNER_USER_WIDTH;

      if (groupClients) {
        finalOffset += 50;
      }

      for (let i = 0; i < dateOffset; i++) {
        let calculatedDate = OffsetDays(date, i).getDay();
        let isWeekend = weekendsHidden && (calculatedDate === 0 || calculatedDate === 6);
        finalOffset += isWeekend ? 10 : getCellWidthForZoom(zoom);
      }

      return finalOffset;
    };

    const calculateWidth = () => {
      if (!dateStart || !dateEnd) return 0;

      let width = 0;

      for (let i = 0; i <= getNumberOfDays(dateStart.toJSDate(), dateEnd.toJSDate()); i++) {
        let calculatedDate = OffsetDays(dateStart.toJSDate(), i).getDay();
        let isWeekend = weekendsHidden && (calculatedDate === 0 || calculatedDate === 6);
        width += isWeekend ? 10 : getCellWidthForZoom(zoom);
      }

      return width;
    };

    const calculateOffsetTop = () => {
      const constantTopOffset = milestoneOrder === 0 ? 0 : 20;
      return constantTopOffset + 5;
    };

    return (
      <div
        ref={ref}
        className="milestone"
        id="milestone-creation"
        style={{
          width: `${calculateWidth()}px`,
          left: `${calculateLeftOffset()}px`,
          top: `${calculateOffsetTop()}px`,
          backgroundColor: milestoneProject.color,
        }}
        onMouseMove={(e) => {
          if (["contractor", "regular"].includes(userRole)) return;

          projectMouseMoved(getPlannerOffsetX(e), milestoneProject.id, index);
        }}
        onMouseUp={(e) => {
          if (e.button === 1) return;
          projectMouseUped();
        }}
      />
    );
  }
);

GroupedCreationMilestone.displayName = "GroupedCreationMilestone";

GroupedCreationMilestone.propTypes = {
  dateStart: PropTypes.object.isRequired,
  dateEnd: PropTypes.object.isRequired,
  weekendsHidden: PropTypes.bool.isRequired,
  milestoneProject: PropTypes.object.isRequired,
  projectMouseMoved: PropTypes.func.isRequired,
  projectMouseUped: PropTypes.func.isRequired,
  groupClients: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
};
