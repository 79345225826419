import DialogContent from "@mui/material/DialogContent";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import Countries from "../../../default_values/Countries";
import FormErrorMessage from "../../../default_components/FormErrorMessage";
import DialogActions from "@mui/material/DialogActions";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../../Api";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";
import { DialogTitle } from "@mui/material";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { useAccountStore } from "../../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import TeambookRadioButton from "../../../default_components/TeambookRadioButton";

const CustomerCreationDialog = ({ closeModal, setCustomerCreated, setUserData, createManualSubscription }) => {
  const { t } = useTranslation();
  const [account, fetchAccount] = useAccountStore((state) => [state.account, state.fetchAccount], shallow);

  const [customerFirstName, setCustomerFirstName] = useState("");
  const [customerLastName, setCustomerLastName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerCountry, setCustomerCountry] = useState("");
  const [customerVat, setCustomerVat] = useState("");
  const [customerAccountName, setCustomerAccountName] = useState(account.name);
  const [customerCityName, setCustomerCityName] = useState("");
  const [customerStreetAddress, setCustomerStreetAddress] = useState("");
  const [customerPostalCode, setCustomerPostalCode] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [registrationKind, setRegistrationKind] = useState("regular");

  const CountriesArray = Countries.map((country) => ({
    ...country,
    value: country.code3,
  }));

  const createCustomer = () => {
    if (
      customerFirstName === "" ||
      customerLastName === "" ||
      customerEmail === "" ||
      customerCountry === "" ||
      customerAccountName === "" ||
      customerCityName === "" ||
      customerStreetAddress === "" ||
      customerPostalCode === ""
    ) {
      setErrorMessage(t("settings.subscription.set_every_field"));
    } else {
      Api.Billing.create_customer(
        customerFirstName,
        customerLastName,
        customerEmail,
        customerCountry.code,
        customerVat,
        customerCityName,
        customerStreetAddress,
        customerPostalCode,
        customerAccountName
      )
        .then((response) => {
          if (registrationKind === "regular") {
            fetchAccount();
            setUserData({
              email: customerEmail,
              firstName: customerFirstName,
              lastName: customerLastName,
            });
            setCustomerCreated(true);
          } else {
            createManualSubscription();
            closeModal();
          }
        })
        .catch((error) => {
          setErrorMessage(error.response.data.error);
        });
    }
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
  };

  return (
    <>
      <DialogTitle id="add-user-dialog">
        <p>{t("settings.subscription.create_billing_info")}</p>

        <TeambookIcon
          name={icons.CROSS}
          onClick={closeModal}
          style={{
            height: 24,
            width: 24,
          }}
        />
      </DialogTitle>

      <DialogContent style={{ margin: "20px 0px" }}>
        <div className="row subscription__customer-info subscription-kind">
          <div className="flex">
            <TeambookRadioButton
              checked={registrationKind === "regular"}
              onCheck={() => setRegistrationKind("regular")}
            />
            <p onClick={() => setRegistrationKind("regular")}> {t("settings.subscription.subscription_by_card")}</p>
          </div>

          <div className="flex">
            <TeambookRadioButton
              checked={registrationKind === "manual"}
              onCheck={() => setRegistrationKind("manual")}
            />
            <p onClick={() => setRegistrationKind("manual")}> {t("settings.subscription.subscription_by_transfer")}</p>
          </div>
        </div>

        <div className="row subscription__customer-info">
          <div className="col-md-6">
            <TeambookTextForm
              fieldName={t("auth.first_name")}
              onChange={setCustomerFirstName}
              fieldValue={customerFirstName}
              type={"text"}
            />
          </div>

          <div className="col-md-6">
            <TeambookTextForm
              fieldName={t("auth.last_name")}
              onChange={setCustomerLastName}
              fieldValue={customerLastName}
              type={"text"}
            />
          </div>
        </div>

        <div className="row subscription__customer-info">
          <div className="col-md-6">
            <TeambookTextForm
              fieldName={t("auth.email")}
              onChange={setCustomerEmail}
              fieldValue={customerEmail}
              type={"text"}
            />
          </div>

          <div className="col-md-6">
            <TeambookTextForm
              fieldName={t("auth.company_name")}
              onChange={setCustomerAccountName}
              fieldValue={customerAccountName}
              type={"text"}
            />
          </div>
        </div>

        <div className="row subscription__customer-info">
          <div className="col-md-4">
            <TeambookTextForm
              fieldName={t("settings.subscription.street_address")}
              onChange={setCustomerStreetAddress}
              fieldValue={customerStreetAddress}
              type={"text"}
            />
          </div>

          <div className="col-md-4">
            <TeambookTextForm
              fieldName={t("settings.subscription.postal_code")}
              onChange={setCustomerPostalCode}
              fieldValue={customerPostalCode}
              type={"text"}
            />
          </div>

          <div className="col-md-4">
            <TeambookTextForm
              fieldName={t("settings.subscription.city_name")}
              onChange={setCustomerCityName}
              fieldValue={customerCityName}
              type={"text"}
            />
          </div>
        </div>

        <div style={{ marginTop: 15, marginBottom: 15 }} className="row subscription__customer-info">
          <div className="col-md-6">
            <TeambookReactSelect
              fieldName={t("auth.country")}
              height={43}
              options={CountriesArray}
              onChange={(country) => {
                setCustomerCountry(country);
              }}
              value={CountriesArray.find((value) => value.code === customerCountry)}
              isSearchable={true}
              grayPlaceholder
            />
          </div>

          <div className="col-md-6">
            <p style={{ fontSize: "14px", marginBottom: 5 }}>{t("settings.subscription.vat_number")}</p>
            <TeambookTextForm onChange={setCustomerVat} fieldValue={customerVat} type={"text"} style={{ margin: 0 }} />
          </div>
        </div>

        {errorMessage !== null && <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />}

        {customerCountry.code === "CH" && <p className="vat-text">{t("settings.subscription.vat_text")}</p>}
      </DialogContent>

      <DialogActions>
        <TeambookBlueButton text={t("submit")} onClick={createCustomer} />
      </DialogActions>
    </>
  );
};

export default CustomerCreationDialog;
