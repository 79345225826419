import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { components } from "react-select";
import PropTypes from "prop-types";

const FilterAccessories = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <TeambookIcon
        alt="filter"
        tooltipTitle={t("planning.add_filter")}
        className="planner-control__filter-icon"
        name={icons.FILTER}
      />

      <components.ValueContainer {...props}>{props.children}</components.ValueContainer>
    </>
  );
};

FilterAccessories.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FilterAccessories;
