import { useEffect, useState } from "react";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import { useTranslation } from "react-i18next";
import Api from "../../../../Api";
import ActionsHoliday from "./modals/ActionsHoliday";
import { icons } from "../../../default_images/IconsList";
import PropTypes from "prop-types";
import TeambookIconButton from "../../../default_components/TeambookIconButton";

const Holidays = ({ users }) => {
  const { t } = useTranslation();

  const [holidays, setHolidays] = useState([]);
  const [selectedModal, setSelectedModal] = useState({
    name: "",
    props: {},
  });

  useEffect(() => {
    getHolidays();
  }, []);

  const getHolidays = () => {
    Api.Integrations.Holidays.get().then((response) => setHolidays(response.data));
  };

  const openCreation = () => {
    setSelectedModal({ name: "CREATION", props: {} });
  };

  const openEditing = (holiday) => {
    setSelectedModal({ name: "EDITING", props: { holiday } });
  };

  const openDeleting = (holidayId) => {
    setSelectedModal({ name: "DELETING", props: { holidayId } });
  };

  const onCloseSelectedModal = () => {
    setSelectedModal({
      name: "",
      props: {},
    });
  };

  return (
    <>
      <div className="time-off__control-row">
        <p className="tb__text__light-black">{t("settings.time_off.holidays")}</p>

        <WhiteTooltip title={t("settings.time_off.create_holiday")}>
          <div onClick={openCreation}>
            <TeambookIconButton name={icons.ADD} isGray />
          </div>
        </WhiteTooltip>
      </div>

      <div className="time-off__scroll-component" style={{maxHeight: 425}}>
        {holidays.map((holiday) => (
          <div className="time-off__block" key={holiday.name + holiday.id} aria-label="blockHoliday">
            <div className="time-off__description col-md-4">
              <p>{holiday.name}</p>
            </div>

            <div style={{ display: "flex", alignItems: "center" }} className="col-md-3">
              <p style={{ margin: "auto 0" }}>{`${holiday.users.length} ${t("settings.time_off.holiday_people")}`}</p>
            </div>

            <div className="time-off__block-content">
              <WhiteTooltip title={t("edit")}>
                <div onClick={() => openEditing(holiday)}>
                  <TeambookIconButton name={icons.PENCIL} />
                </div>
              </WhiteTooltip>

              <WhiteTooltip title={t("delete")}>
                <div onClick={() => openDeleting(holiday.id)}>
                  <TeambookIconButton name={icons.TRASH} />
                </div>
              </WhiteTooltip>
            </div>
          </div>
        ))}
      </div>

      <ActionsHoliday
        selectedModal={selectedModal}
        onCloseSelectedModal={onCloseSelectedModal}
        getHolidays={getHolidays}
        users={users}
        holidays={holidays}
      />
    </>
  );
};

Holidays.propTypes = {
  users: PropTypes.array.isRequired,
};

export default Holidays;
