import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import TeambookTextForm from "../../default_components/TeambookTextForm";
import { useTranslation } from "react-i18next";
import Api from "../../../Api";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import PasswordRules from "../../register/PasswordRules";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { useNotificationStore } from "../../../stores/notificationStore";
import { shallow } from "zustand/shallow";

export const ResetPasswordDialog = ({ closeResetPasswordDialog, email }) => {
  const [resetPasswordCode, setResetPasswordCode] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [resetPasswordConf, setResetPasswordConf] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const [passwordRulesVisible, setPasswordRulesVisible] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confPassShow, setConfPassShow] = useState(false);
  const { t } = useTranslation();
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);

  const closeErrorMessage = () => {
    setErrorMessage(null);
    setErrorField(null);
  };

  const verifyPasswordReset = () => {
    setErrorField(null);
    setErrorMessage(null);

    let letterRegex = /.*[^0-9].*/;
    let numberRegex = /.*[0-9].*/;
    let lengthPassword = resetPassword.length >= 8;
    let letterRule = letterRegex.test(resetPassword);
    let numberRule = numberRegex.test(resetPassword);

    if (resetPassword !== resetPasswordConf) {
      setErrorMessage("Passwords do not match.");
      setErrorField("password_confirmation");
      return;
    }

    if (!lengthPassword || !letterRule || !numberRule) {
      setErrorMessage("Please check your password to fit all rules.");
      setErrorField("password");
      return;
    }

    Api.Auth.update_password(resetPasswordCode, resetPassword)
      .then(() => {
        setInAppNotification(t("notifications.profile.email_password.password_update"));
        closeResetPasswordDialog();
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error.message);
        setErrorField("code");
      });
  };

  return (
    <Dialog className="tb-default-dialog" onClose={closeResetPasswordDialog} open={true}>
      <DialogTitle>
        <p>{t("auth.reset_password")}</p>
        <TeambookIcon name={icons.CROSS} onClick={closeResetPasswordDialog} />
      </DialogTitle>

      <DialogContent style={{ width: 400, margin: "20px 0px" }}>
        <p style={{ fontSize: "14px" }}>
          {t("profile.email.send_pass_code_1")}
          <b>{email}</b>
          {t("profile.email.send_pass_code_2")}
        </p>

        <TeambookTextForm
          fieldName={t("auth.code")}
          fieldValue={resetPasswordCode}
          onChange={setResetPasswordCode}
          errorField={errorField}
          errorMessage={errorMessage}
          field={"code"}
          inputBottomOffset={"20px"}
          closeErrorMessage={closeErrorMessage}
        />

        <TeambookTextForm
          fieldName={t("profile.password.new_password")}
          fieldValue={resetPassword}
          onChange={setResetPassword}
          onFocus={() => setPasswordRulesVisible(true)}
          onBlur={() => setPasswordRulesVisible(false)}
          isPassword={true}
          showPass={passwordShow}
          setShowPass={() => setPasswordShow(!passwordShow)}
          errorField={errorField}
          errorMessage={errorMessage}
          inputBottomOffset={"20px"}
          field={"password"}
          closeErrorMessage={closeErrorMessage}
        />

        <PasswordRules password={resetPassword} visible={passwordRulesVisible} />

        <TeambookTextForm
          fieldName={t("profile.password.confirm_new_password")}
          fieldValue={resetPasswordConf}
          onChange={setResetPasswordConf}
          isPassword={true}
          showPass={confPassShow}
          setShowPass={() => setConfPassShow(!confPassShow)}
          errorField={errorField}
          errorMessage={errorMessage}
          field={"password_confirmation"}
          closeErrorMessage={closeErrorMessage}
        />
      </DialogContent>
      <DialogActions>
        <TeambookBlueButton text={t("submit")} onClick={verifyPasswordReset} />
      </DialogActions>
    </Dialog>
  );
};
