import { create } from "zustand";

export const useCapacityStore = create((set) => ({
  showTimeOffs: true,
  setShowTimeOffs: (newValue) => set({ showTimeOffs: newValue }),
  userCapacity: [],
  setUserCapacity: (newValue) => set({ userCapacity: newValue }),
  projectCapacity: [],
  setProjectCapacity: (newValue) => set({ projectCapacity: newValue }),
}));
