import { useEffect, useState } from "react";
import { SliceStringWithDots } from "../../../../lib/StringFormat";
import { GetDaysInZoom } from "../../../../lib/DateUtils";
import ProjectRowWeek from "./ProjectRowWeek";
import Milestone from "./Milestone";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import { useTranslation } from "react-i18next";
import { GROUPED_PLANNER_USER_WIDTH, getPlannerOffsetX } from "../../../../lib/PlannerUtils";
import { GroupedCreationMilestone } from "./GroupedCreationMilestone";
import { useZoomStore } from "../../../../stores/planner";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { shallow } from "zustand/shallow";
import lsKeys from "../../../default_values/defaultKeys";
import PropTypes from "prop-types";

const ProjectGroup = ({
  project,
  weekendsHidden,
  changeTooltip,
  projectMouseDowned,
  projectMouseMoved,
  projectMouseUped,
  index,
  automaticMilestones,
  milestones,
  openEditMilestone,
  openAddUserToGroup,
  style,
  groupedProjectUsers,
  bookingFormat,
  groupClients,
  showClientDivider,
  setMilestones,
  milestoneCreationRef,
}) => {
  const { t } = useTranslation();
  const userRole = localStorage.getItem("tb-role") || "regular";
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);

  const [columns, setColumns] = useState([]);
  const [milestonesObjects, setMilestonesObjects] = useState([]);
  let days = GetDaysInZoom(zoom);

  useEffect(() => {
    let projectRow = [];
    let milestonesRow = [];

    for (let i = 0; i < days / 7; i++) {
      projectRow.push(
        <ProjectRowWeek
          mouseDowned={projectMouseDowned}
          mouseMoved={projectMouseMoved}
          mouseUped={projectMouseUped}
          weekendsHidden={weekendsHidden}
          changeTooltip={changeTooltip}
          index={index}
          project={project}
        />
      );
    }

    milestones.forEach((milestone) => {
      milestonesRow.push(
        <Milestone
          groupClients={groupClients}
          milestone={milestone}
          project={project}
          weekendsHidden={weekendsHidden}
          projectMouseMoved={projectMouseMoved}
          openEditMilestone={openEditMilestone}
          row={milestone.order}
          milestones={milestones}
          setMilestones={setMilestones}
        />
      );
    });

    automaticMilestones.forEach((milestone) => {
      milestonesRow.push(
        <Milestone
          groupClients={groupClients}
          milestone={milestone}
          project={project}
          weekendsHidden={weekendsHidden}
          projectMouseMoved={projectMouseMoved}
          openEditMilestone={() => {}}
          row={milestone.order}
          setMilestones={setMilestones}
        />
      );
    });

    setMilestonesObjects(milestonesRow);
    setColumns(projectRow);
  }, [project, milestones]);

  const calculateHintLeftOffset = () => {
    return groupClients ? "225px" : "175px";
  };

  return (
    <div className="project-group__group-row" style={{ ...style }}>
      {groupClients && (
        <div
          className="project-group__client-holder"
          style={{
            borderTop: index !== 0 && showClientDivider && "1px solid var(--stroke)",
          }}
        />
      )}

      <div
        className="project-group__cell"
        style={{
          width: GROUPED_PLANNER_USER_WIDTH,
          border: "2px solid " + project.color,
          left: groupClients ? "50px" : 0,
        }}
      >
        <WhiteTooltip title={`[${project.code}] ${project.name}`}>
          <div className="project-group__project-container">
            <div className="project-group__project-circle" style={{ backgroundColor: project.color }}></div>
            <p className="project-grouped__project-name">{SliceStringWithDots(project.code, 13)}</p>
          </div>
        </WhiteTooltip>

        {["admin", "planner"].includes(userRole) && (
          <TeambookIcon
            name={icons.USER_PLUS}
            id={"createUserGr"}
            width={26}
            onClick={() => openAddUserToGroup(project)}
            tooltipTitle={t("planning.add_user_group_text")}
            style={{ opacity: localStorage.getItem(lsKeys.THEME) === "dark" ? 0.75 : 1 }}
          />
        )}
      </div>

      {index === 0 && milestones.length === 0 && (
        <div
          className="project-group__milestone-hint"
          onMouseDown={(e) => {
            if (e.button === 1) return;
            if (["contractor", "regular"].includes(userRole)) return;

            const rect = e.target.getBoundingClientRect();
            const y = e.clientY - rect.top; //y position within the element.

            projectMouseDowned(getPlannerOffsetX(e), y, index, project);
          }}
          onMouseMove={(e) => {
            if (["contractor", "regular"].includes(userRole)) return;

            projectMouseMoved(getPlannerOffsetX(e), project.id);
          }}
          onMouseUp={(e) => {
            if (e.button === 1) return;
            projectMouseUped();
          }}
          onDragOver={(e) => {
            changeTooltip({ open: false });
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{ left: calculateHintLeftOffset() }}
        >
          {!["contractor", "self_planner", "regular"].includes(userRole) && t("planning.milestones.hint")}
        </div>
      )}

      {columns}

      {milestonesObjects}

      <GroupedCreationMilestone
        index={index}
        weekendsHidden={weekendsHidden}
        groupedUsers={groupedProjectUsers}
        bookingFormat={bookingFormat}
        milestoneProject={project}
        projectMouseMoved={projectMouseMoved}
        projectMouseUped={projectMouseUped}
        groupClients={groupClients}
        ref={(el) => (milestoneCreationRef.current[index] = el)}
      />
    </div>
  );
};

ProjectGroup.propTypes = {
  project: PropTypes.object.isRequired,
  weekendsHidden: PropTypes.bool.isRequired,
  changeTooltip: PropTypes.func.isRequired,
  projectMouseDowned: PropTypes.func.isRequired,
  projectMouseMoved: PropTypes.func.isRequired,
  projectMouseUped: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  automaticMilestones: PropTypes.array.isRequired,
  milestones: PropTypes.array.isRequired,
  openEditMilestone: PropTypes.func.isRequired,
  openAddUserToGroup: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
  groupedProjectUsers: PropTypes.array.isRequired,
  bookingFormat: PropTypes.object.isRequired,
  groupClients: PropTypes.bool.isRequired,
  showClientDivider: PropTypes.bool.isRequired,
  setMilestones: PropTypes.func.isRequired,
  milestoneCreationRef: PropTypes.object.isRequired,
};

export default ProjectGroup;
