import { useActualsStore } from "../../stores/actualsStore";
import TimeLogging from "./time_logging/TimeLogging";
import ApprovalComponent from "./approval/ApprovalComponent";

const ActualsPage = () => {
  const [actualsMode] = useActualsStore((state) => [state.actualsMode]);

  return actualsMode === "logging" ? <TimeLogging /> : <ApprovalComponent />;
};

export default ActualsPage;
