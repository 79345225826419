import { create } from "zustand";
import lsKeys from "../components/default_values/defaultKeys";

export const useActualsStore = create((set) => ({
  timeLoggingMode: localStorage.getItem(lsKeys.ACTUALS_TIMESHEET_MODE) || "project",
  timesheetAddProjectOpen: false,
  timesheetAddedProjects: [],
  copyPlannerWithNotes: localStorage.getItem(lsKeys.ACTUALS_COPY_PLANNER_WITH_NOTES) !== "false",

  setTimeLoggingMode: (mode) => {
    localStorage.setItem(lsKeys.ACTUALS_TIMESHEET_MODE, mode);
    set({ timeLoggingMode: mode });
  },
  setTimesheetAddProjectOpen: (open) => set({ timesheetAddProjectOpen: open }),
  setTimesheetAddedProjects: (projects) => set({ timesheetAddedProjects: projects }),
  setCopyPlannerWithNotes: (value) => {
    localStorage.setItem(lsKeys.ACTUALS_COPY_PLANNER_WITH_NOTES, value);
    set({ copyPlannerWithNotes: value });
  },

  actualsMode: localStorage.getItem(lsKeys.ACTUALS_MODE) || "logging",
  setActualsMode: (mode) => {
    localStorage.setItem(lsKeys.ACTUALS_MODE, mode);
    set({ actualsMode: mode });
  },
}));
