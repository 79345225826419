import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "./Tooltips";
import { ICON_COLOR, TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import PropTypes from "prop-types";

const TeambookTextForm = ({
  fieldName,
  onChange,
  fieldValue,
  type,
  inputProps,
  width,
  rows,
  multiline,
  style,
  autoFocus,
  placeholder,
  id,
  whiteSpace,
  onFocus = () => {},
  onBlur = () => {},
  className,
  disabled,
  isPassword = false,
  onKeyDown,
  showPass = false,
  setShowPass = () => {},
  field = null,
  errorMessage = "",
  errorField = null,
  inputBottomOffset,
  closeErrorMessage = () => {},
}) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const hasError = errorMessage !== "" && errorField === field;

  return (
    <div
      className={`input-form-component text-form__container ${className || ""} ${hasError ? "invalid-value" : ""}`}
      style={{ width, ...style, position: "relative", marginBottom: inputBottomOffset }}
    >
      {fieldName && (
        <p className="input-form__form-text text-form__paragraph" style={{ whiteSpace: whiteSpace }}>
          {fieldName}
        </p>
      )}

      <div className={`input-container ${isFocused ? "focused" : ""}`}>
        <TextField
          onKeyDown={onKeyDown}
          id={id}
          className={`text-field input-field ${isPassword ? "password" : ""}`}
          variant="outlined"
          InputProps={{
            style: {
              height: "42px",
              border: "unset !important",
            },
          }}
          type={isPassword ? (showPass ? "text" : "password") : type}
          onChange={(event) => {
            onChange(event.target.value);
            closeErrorMessage();
          }}
          value={fieldValue}
          inputProps={inputProps}
          rows={rows}
          multiline={!!multiline}
          autoFocus={autoFocus}
          placeholder={placeholder}
          onFocus={(e) => {
            onFocus(e);
            setIsFocused(true);
          }}
          onBlur={(e) => {
            onBlur(e);
            setIsFocused(false);
          }}
          disabled={disabled}
        />

        {hasError && (
          <div className="input-form__error-field">
            <TeambookIcon color={ICON_COLOR.COMMON} name={icons.WARNING} />
            <p>{errorMessage}</p>
          </div>
        )}

        {isPassword && (
          <WhiteTooltip title={t(`profile.password.${showPass ? "hide_password" : "show_password"}`)}>
            <div className={`text-form__password-eye`} onClick={setShowPass}>
              <TeambookIcon name={showPass ? icons.EYE_OPENED : icons.EYE_CLOSED} />
            </div>
          </WhiteTooltip>
        )}
      </div>
    </div>
  );
};

TeambookTextForm.propTypes = {
  fieldName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  fieldValue: PropTypes.string.isRequired,
  type: PropTypes.string,
  inputProps: PropTypes.object,
  width: PropTypes.string,
  rows: PropTypes.number,
  multiline: PropTypes.bool,
  style: PropTypes.object,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  whiteSpace: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isPassword: PropTypes.bool,
  onKeyDown: PropTypes.func,
  showPass: PropTypes.bool,
  setShowPass: PropTypes.func,
  field: PropTypes.string,
  errorMessage: PropTypes.string,
  errorField: PropTypes.string,
  inputBottomOffset: PropTypes.number,
  closeErrorMessage: PropTypes.func,
};

export default TeambookTextForm;
