import React from "react";
import "./default_styles.scss";
import { ICON_COLOR, TeambookIcon } from "../default_images/TeambookIcon";
import Api from "../../Api";
import { icons } from "../default_images/IconsList";
import lsKeys from "../default_values/defaultKeys";
import * as Sentry from "@sentry/react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    Api.Administrators.create_frontend_errors(
      error.toString() + "=======" + error.stack.toString(),
      window.location.href
    );

    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.REACT_APP_ENV !== "production") return;

    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      return (
        <div lang={localStorage.getItem(lsKeys.LANGUAGE)} className="problem-page">
          <p className="main-text">{t("error_page.yikes")}</p>

          <p className="secondary-text" style={{ marginBottom: "40px" }}>
            {t("error_page.something_wrong")}
          </p>

          <p className="secondary-text">{t("error_page.next")}</p>

          <div className="flex">
            <div className="problem-page__dash" />
            <p className="secondary-text">
              <b>{t("error_page.try_again_1")}</b>{" "}
              <a href="#" onClick={() => window.location.reload()}>
                {t("error_page.try_again_2")}
              </a>{" "}
              {t("error_page.try_again_3")}
            </p>
          </div>
          <div className="flex">
            <div className="problem-page__dash" />
            <p className="secondary-text">
              <b> {t("error_page.home_1").split(":")[0]}: </b>
              {t("error_page.home_1").split(":")[1]} <a href="/profile">{t("error_page.home_2")}</a>{" "}
              {t("error_page.home_3")}
            </p>
          </div>
          <div className="flex">
            <div className="problem-page__dash" />
            <p className="secondary-text">
              <b>{t("error_page.connection").split(":")[0]}:</b> {t("error_page.connection").split(":")[1]}
            </p>
          </div>
          <div className="flex">
            <div className="problem-page__dash" />
            <p className="secondary-text">
              <b> {t("error_page.support").split(":")[0]}:</b> {t("error_page.support").split(":")[1]}{" "}
              <a href="mailto:support@teambookapp.com">support@teambookapp.com</a>
            </p>
          </div>

          <TeambookIcon name={icons.NEW_ERROR_BG} color={ICON_COLOR.ALTERNATIVE} className="problem-page__icon" />
        </div>
      );
    }

    return this.props.children;
  }
}

window.onerror = function (message, source, lineno, colno, error) {
  // console.error("Global error:", error);
  // Optionally, handle or log the error here
};

export default ErrorBoundary;
