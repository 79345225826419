import React, {useEffect, useState} from "react";
import { DateTime } from "luxon";
import Api from "../../../Api";
import lsKeys from "../../default_values/defaultKeys";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import UsersProfileImage from "../../default_components/UsersProfileImage";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { useProfileStore } from "../../../stores/profileStore";
import {useCapacityStore} from "../../../stores/capacity";

const usersWithData = (users, reservations) => {
  return users.filter((u) =>
    reservations
      .filter((ur) => ur.days_reserved > 0)
      .map((ur) => ur.user_id)
      .includes(u.id)
  );
};

const StaffingRow = ({
  date,
  users,
  jumpToUser,
  project,
  selectedViewType,
  addedUsers,
}) => {
  const { t } = useTranslation();
  const fullAccessRoles = ["admin", "planner"];
  const role = localStorage.getItem(lsKeys.ROLE);
  const [account] = useAccountStore((state) => [state.account], shallow);
  const [profile] = useProfileStore((state) => [state.profile], shallow);
  const [capacityReservations, setCapacityReservations] = useCapacityStore(
    (state) => [state.userCapacity, state.setUserCapacity],
    shallow
  );

  const [calculatedDurations, setCalculatedDurations] = useState([]);

  useEffect(() => {
    const mapping = {};

    capacityReservations.filter((ur) => ur.project_id === project.id).forEach((cr) => {
      mapping[cr.user_id] ||= {};

      mapping[cr.user_id][DateTime.fromISO(cr.date).toISODate()] = cr.days_reserved;
    })

    setCalculatedDurations(mapping);
  }, [capacityReservations]);

  const updateInputValue = (user, newValue, index) => {
    if (account.subscription.subscription_id === "Free" && account.subscription.projects_available === 10) {
      return;
    }

    const calculatedDate = date.plus({ months: index }).toISODate();
    const mapping = { ...calculatedDurations };
    mapping[user.id] ||= {};
    mapping[user.id][calculatedDate] = newValue;

    setCalculatedDurations(mapping);
  }

  const updateUserReservation = (user, newValue, index) => {
    if (account.subscription.subscription_id === "Free" && account.subscription.projects_available === 10) {
      return;
    }

    const oldValue = capacityReservations
      .find((ur) => ur.user_id === user.id && ur.project_id === project.id && ur.date === date.plus({ months: index }).toISODate())
      ?.days_reserved || 0;

    if (parseInt(newValue) === parseInt(oldValue)) return;

    Api.CapacityReservations.create({
      user_id: user.id,
      project_id: project.id,
      days_reserved: newValue,
      date: date.plus({ months: index }).toISODate(),
    }).then(() => {
      Api.CapacityReservations.get({
        user_ids: [user.id],
        start_date: date.toISODate(),
        end_date: date.plus({ years: 2 }).toISODate(),
      }).then((res) => {
        setCapacityReservations([...capacityReservations.filter((ur) => ur.user_id !== user.id), ...res.data]);
      });
    });
  };

  const handleFocus = (event) => event.target.select();

  const shownUsers = () => {
    const usersInCapacity = usersWithData(
      users,
      capacityReservations.filter((ur) => ur.project_id === project.id)
    );

    return [...usersInCapacity.filter((u) => !addedUsers.map((_u) => _u.id).includes(u.id)), ...addedUsers];
  };

  return (
    <>
      {shownUsers().map((user) => (
        <div className="flex project-row" key={`user-row-${user.id}-date-${date}`}>
          <div
            className="users-block__project-info users-block__projects-capacity greyed"
            onClick={() => jumpToUser(user.id)}
            title={t("planning.capacity.go_to_user")}
          >
            <UsersProfileImage size={30} user={user} className="avatar" userImage={user.avatars["30"]} />
            <p className="user-name font-italic">{user.name}</p>

            <TeambookIcon className="cursor-pointer" name={icons.ARROW_DOWN} style={{ transform: "rotate(-90deg)" }} />
          </div>

          {[...Array(selectedViewType)].map((x, i, arr) => (
            <div
              style={{
                borderRight:
                  arr.length - 1 === i
                    ? "1px solid var(--stroke)"
                    : date.plus({ months: i }).month === 12
                    ? "1px solid var(--text-12)"
                    : "1px solid var(--stroke)",
              }}
              className="table-cell font-italic greyed"
              key={`user-row-${user.id}-date-${date}-month-${i}`}
            >
              {fullAccessRoles.includes(role) || (user.id === profile.id && profile.role === "self_planner") ? (
                <input
                  onChange={(event) => updateInputValue(user, event.target.value >= 0 ? event.target.value : 0, i)}
                  onBlur={(event) => updateUserReservation(user, event.target.value >= 0 ? event.target.value : 0, i, event)}
                  onFocus={handleFocus}
                  onWheel={(e) => e.target.blur()}
                  value={calculatedDurations[user.id]?.[(date.plus({ month: i })).toISODate()] || 0}
                  style={{ width: "100%" }}
                  type={"number"}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => e.preventDefault()}
                />
              ) : (
                <p>{calculatedDurations[user.id]?.[(date.plus({ month: i })).toISODate()] || 0}</p>
              )}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default StaffingRow;
