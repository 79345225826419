import PlannerActions from "./PlannerActions";
import {
  useBookingsCreatingStore,
  useBookingsEditingStore,
  useBookingsStore,
  useCopyBookingsStore,
  useDateStore,
  useSelectedProjectStore,
  useShiftPressedStore,
  useZoomStore,
} from "../../../../stores/planner";
import $ from "jquery";
import Api from "../../../../Api";
import { getCellWidthForZoom, getNumberOfDays, OffsetDays, ToDateString } from "../../../../lib/DateUtils";
import { siteOffsetX, siteOffsetY, usersMaxScheduleTime } from "../../../../lib/PlannerUtils";
import { useAccountStore } from "../../../../stores/accountStore";

class GroupedPlannerActions extends PlannerActions {
  mouseUped(
    offsetX,
    offsetY,
    users,
    e,
    multipleSelectRef,
    selectBooking,
    plannerDispatcher,
    closeEditWindow,
    openCreationWindow,
    groupClients,
    setSelectedProject,
    groupedProjectUsers,
    bookingFormat,
    shownProjects,
    activeProjects,
    projects,
    setProjectsType,
    createBookingsRef
  ) {
    if (groupClients) {
      offsetX -= 50;
    }

    if (useShiftPressedStore.getState().shiftPressed || useShiftPressedStore.getState().startSelected) {
      if (!multipleSelectRef || !multipleSelectRef.current) return;

      multipleSelectRef.current.setOpen(false);
      multipleSelectRef.current.setPos({ x: 0, y: 0 });
      multipleSelectRef.current.setSize({ width: 0, height: 0 });
      let clientsOffset = groupClients ? 50 : 0;

      const zoom = useZoomStore.getState().zoom;
      const scrollList = $("#planner-scroll-list");
      const scrollLeft = scrollList.scrollLeft();
      const scrollTop = scrollList.scrollTop();
      const startX = useShiftPressedStore.getState().startX;
      const startY = useShiftPressedStore.getState().startY;
      const clientY = e?.clientY - siteOffsetY(zoom) || 0;
      const endX = offsetX + clientsOffset + scrollLeft;
      const endY = clientY + scrollTop;
      const cellWidth = getCellWidthForZoom();

      Array.from($(".booking")).forEach((booking) => {
        const bookingElement = $(booking);
        const bookingId = +booking.id.split("-")[1];

        const left = bookingElement.offset().left + scrollLeft - siteOffsetX(zoom);
        const right = left + cellWidth;
        const top = bookingElement.offset().top + scrollTop - siteOffsetY(zoom);
        const bottom = top + bookingElement.height();

        const intersectsX = left < endX && right > startX;
        const intersectsY = top < endY && bottom > startY;

        if (intersectsX && intersectsY) {
          selectBooking(this.getBookingById(bookingId));
        }
      });

      useShiftPressedStore.getState().setStartSelected(false);
    } else {
      offsetY += $("#planner-scroll-list").scrollTop();
      const project = this.getCreationProject(
        e,
        groupedProjectUsers,
        bookingFormat,
        shownProjects,
        activeProjects,
        projects
      );

      if (
        this.userIndexStart !== null &&
        e.button === 0 &&
        !e.ctrlKey &&
        !useCopyBookingsStore.getState().copyModeActive
      ) {
        let duration = createBookingsRef?.current.find((ref) => ref.bookings.length > 0)?.duration;

        useBookingsCreatingStore.getState().setDuration(duration);

        if (duration) {
          openCreationWindow();
        }
        setSelectedProject(project);
      }

      if (useCopyBookingsStore.getState().copyModeActive && useCopyBookingsStore.getState().copyBookingIds.length > 0) {
        if (this.clickStartX === offsetX && this.clickStartY === offsetY && e.button === 0) {
          let new_bookings = useBookingsStore.getState().bookings;

          Api.Bookings.copy(
            useCopyBookingsStore.getState().copyBookingIds,
            users[this.userIndexStart].id,
            ToDateString(this.userDateStart)
          ).then((response) => {
            useBookingsStore.getState().setBookings([...new_bookings, ...response.data]);

            let userWithBookingsUpdated = groupedProjectUsers
              .flat()
              .filter((user, index) => index === this.userIndexStart)
              .map((u) => u.id);

            userWithBookingsUpdated = groupedProjectUsers
              .flat()
              .map((user, i) => (userWithBookingsUpdated.includes(user.id) ? i : null))
              .filter((u) => u !== null);

            plannerDispatcher.afterCreationBookingAssignment(userWithBookingsUpdated);

            this.userIndexStart = null;
            this.userDateStart = null;
          });
        }
      } else {
        if (this.clickStartX === offsetX && this.clickStartY === offsetY && e.button === 0) {
          if (!this.userDateStart) {
            this.calculateUserDateStart(offsetX, offsetY, users);
          }

          if (!this.userDateStart) return;

          const isCreatedInThisDate = createBookingsRef.current[this.userIndexStart]?.bookings?.some(
            (b) => b[0] === this.userIndexStart && b[1].getTime() === this.userDateStart.getTime()
          );

          if (!isCreatedInThisDate && createBookingsRef.current[this.userIndexStart]) {
            if (this.shiftCreation) {
              this.shiftCreation = false;

              createBookingsRef.current.forEach((bookingRef) => {
                bookingRef.updateBookings([]);
              });

              createBookingsRef.current[this.userIndexStart].updateBookings([
                [this.userIndexStart, this.userDateStart],
              ]);

              useBookingsCreatingStore.getState().setBookings([this.userIndexStart, this.userDateStart]);
            } else {
              createBookingsRef.current[this.userIndexStart].updateBookings([
                ...createBookingsRef.current[this.userIndexStart].bookings,
                [this.userIndexStart, this.userDateStart],
              ]);

              useBookingsCreatingStore.getState().addBooking([this.userIndexStart, this.userDateStart]);
            }

            createBookingsRef.current[this.userIndexStart].updateDuration(
              useAccountStore.getState().account?.default_booking_duration / 60
            );

            createBookingsRef.current.forEach((bookingRef) => {
              if (bookingRef) {
                bookingRef.updateDuration(useAccountStore.getState().account?.default_booking_duration / 60);
              }
            });

            useBookingsCreatingStore
              .getState()
              .setDuration(useAccountStore.getState().account?.default_booking_duration / 60);

            closeEditWindow();

            openCreationWindow();
          }
        } else {
          let duration = createBookingsRef?.current.find((ref) => ref?.bookings?.length > 0)?.duration;

          useBookingsCreatingStore.getState().setDuration(duration);

          if (duration) {
            openCreationWindow();
          }
        }

        useBookingsCreatingStore
          .getState()
          .setDuration(createBookingsRef?.current.find((ref) => ref?.bookings?.length > 0)?.duration);

        this.userIndexStart = null;
        this.userDateStart = null;
      }

      useShiftPressedStore.getState().setStartSelected(false);
      useShiftPressedStore.getState().setShiftPressed(false);
    }

    super.mouseUped(offsetX, offsetY, users, e);
  }

  mouseMoved(
    e,
    offsetX,
    offsetY,
    users,
    weekendsHidden,
    multipleSelectRef,
    bookingFormat,
    groupClients,
    groupedProjectUsers,
    zoom,
    projectRowOffset,
    closeEditWindow,
    shownProjects,
    activeProjects,
    projects,
    createBookingsRef
  ) {
    if (groupClients) {
      offsetX -= 50;
    }

    if (useShiftPressedStore.getState().shiftPressed || useShiftPressedStore.getState().startSelected) {
      let _height = 0;
      const userHeights = users.map(
        (user) => (usersMaxScheduleTime(user) / 60) * this.formatMapping[bookingFormat] + 17
      );
      let clientsOffset = groupClients ? 50 : 0;

      userHeights.forEach((userHeight, index) => {
        if (
          _height + projectRowOffset(offsetY) < offsetY &&
          _height + userHeight + projectRowOffset(offsetY) > offsetY
        ) {
          this.userIndexEnd = index;

          multipleSelectRef.current.setPos({
            x: useShiftPressedStore.getState().startX,
            y: useShiftPressedStore.getState().startY,
          });

          const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(zoom);
          const dayWidth = getCellWidthForZoom(zoom);

          let calculatedOffsetX = 0;
          let dateIteration = 0;

          while (calculatedOffsetX < offsetX + $("#planner-scroll-list").scrollLeft()) {
            calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
            dateIteration++;
          }

          multipleSelectRef.current.setSize({
            width:
              offsetX + $("#planner-scroll-list").scrollLeft() - useShiftPressedStore.getState().startX + clientsOffset,
            height: offsetY + $("#planner-scroll-list").scrollTop() - useShiftPressedStore.getState().startY,
          });

          this.userDateEnd = OffsetDays(useDateStore.getState().date, dateIteration - 1);
        }

        _height += userHeight;
      });
    } else {
      if (this.userIndexStart === null || useCopyBookingsStore.getState().copyModeActive) {
        return null;
      }

      const project = this.getCreationProject(
        e,
        groupedProjectUsers,
        bookingFormat,
        shownProjects,
        activeProjects,
        projects
      );

      multipleSelectRef.current.setPos({ x: 0, y: 0 });
      multipleSelectRef.current.setSize({ width: 0, height: 0 });

      let users = groupedProjectUsers.flat();
      offsetY += $("#planner-scroll-list").scrollTop();

      const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(zoom);
      const dayWidth = getCellWidthForZoom(zoom);
      let _height = 0;
      const userHeights = users.map(
        (user) => (usersMaxScheduleTime(user) / 60) * this.formatMapping[bookingFormat] + 17
      );

      userHeights.forEach((userHeight, index) => {
        if (
          _height + projectRowOffset(offsetY) < offsetY &&
          _height + userHeight + projectRowOffset(offsetY) > offsetY
        ) {
          this.userIndexEnd = index;

          let calculatedOffsetX = 0;
          let dateIteration = 0;

          while (calculatedOffsetX < offsetX + $("#planner-scroll-list").scrollLeft()) {
            calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
            dateIteration++;
          }

          this.userDateEnd = OffsetDays(useDateStore.getState().date, dateIteration - 1);
        }

        _height += userHeight;
      });

      let currentHeight = 0;

      for (let i = 0; i < this.userIndexEnd; i++) {
        currentHeight += userHeights[i];
      }

      let currentBookingsHeight = 0;
      let currentBookings = useBookingsStore
        .getState()
        .bookings.filter(
          (booking) =>
            booking.user_id === users[this.userIndexEnd].id &&
            booking.date === ToDateString(this.userDateEnd) &&
            booking.deleted_at === null
        );

      currentBookings.forEach((booking) => {
        currentBookingsHeight += (booking.duration / 60) * this.formatMapping[bookingFormat];
      });

      let newDuration =
        (offsetY - projectRowOffset(offsetY) - currentHeight - currentBookingsHeight) /
        this.formatMapping[bookingFormat];

      newDuration = +(Math.round(newDuration * 2) / 2).toFixed(2);

      createBookingsRef.current.forEach((bookingCreateRef) => {
        if (
          this.userIndexStart !== this.userIndexEnd ||
          getNumberOfDays(this.userDateStart, this.userDateEnd) > 0 ||
          this.multipleCreation
        ) {
          this.multipleCreation = true;
          bookingCreateRef?.updateBookings(this.redrawCreationBookings(weekendsHidden));
          bookingCreateRef?.updateDuration(newDuration);
        } else {
          if (
            useBookingsCreatingStore
              .getState()
              .bookingsCreating.findIndex((b) => b[0] === this.userIndexStart && b[1] === this.userDateStart) === -1
          ) {
            createBookingsRef.current[this.userIndexStart].updateBookings([
              ...createBookingsRef.current[this.userIndexStart].bookings.filter(
                (b) => b[0] !== this.userIndexStart || b[1] !== this.userDateStart
              ),
              [this.userIndexStart, this.userDateStart],
            ]);
          }

          createBookingsRef.current.forEach((bookingRef) => {
            if (bookingRef) {
              bookingRef.updateDuration(newDuration);
            }
          });
        }
      });

      if (project?.kind === "time_off") {
        useSelectedProjectStore.getState().setSelectedTimeOff(project);
        useSelectedProjectStore.getState().setProjectsType("time_off");
      } else {
        useSelectedProjectStore.getState().setSelectedProject(project);
        useSelectedProjectStore.getState().setProjectsType("default");
      }
    }
  }

  mouseDowned(
    offsetX,
    offsetY,
    groupedProjectUsers,
    multipleSelectRef,
    weekendsHidden,
    bookingFormat,
    groupClients,
    projectRowOffset
  ) {
    if (useShiftPressedStore.getState().ctrlPressed && useBookingsEditingStore.getState().bookingsEditing.length > 0)
      return;

    if (groupClients) {
      offsetX -= 50;
    }

    if (useShiftPressedStore.getState().shiftPressed) {
      useShiftPressedStore.getState().setStartSelected(true);
      multipleSelectRef.current.setOpen(false);
      multipleSelectRef.current.setPos({ x: 0, y: 0 });
      multipleSelectRef.current.setSize({ width: 0, height: 0 });
      let clientsOffset = groupClients ? 50 : 0;

      useShiftPressedStore.getState().setStartX(offsetX + $("#planner-scroll-list").scrollLeft() + clientsOffset);
      useShiftPressedStore.getState().setStartY(offsetY + $("#planner-scroll-list").scrollTop());

      multipleSelectRef.current.setPos({
        x: offsetX + $("#planner-scroll-list").scrollLeft() + clientsOffset,
        y: offsetY + $("#planner-scroll-list").scrollTop(),
      });

      multipleSelectRef.current.setOpen(true);
    } else {
      const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(useZoomStore.getState().zoom);
      const dayWidth = getCellWidthForZoom(useZoomStore.getState().zoom);
      let currentHeight = 0;
      const users = groupedProjectUsers.flat();
      const userHeights = users.map(
        (user) => (usersMaxScheduleTime(user) / 60) * this.formatMapping[bookingFormat] + 17
      );

      offsetY += $("#planner-scroll-list").scrollTop();

      this.clickStartX = offsetX;
      this.clickStartY = offsetY;

      userHeights.forEach((userHeight, index) => {
        if (
          currentHeight + projectRowOffset(offsetY) < offsetY &&
          currentHeight + userHeight + projectRowOffset(offsetY) > offsetY
        ) {
          this.userIndexStart = index;

          let calculatedOffsetX = 0;
          let dateIteration = 0;

          while (calculatedOffsetX < offsetX + $("#planner-scroll-list").scrollLeft()) {
            calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
            dateIteration++;
          }

          this.userDateStart = OffsetDays(useDateStore.getState().date, dateIteration - 1);
        }

        currentHeight += userHeight;
      });
    }
  }

  getCreationProject(e, groupedProjectUsers, bookingFormat, shownProjects, activeProjects, projects) {
    const userHeights = groupedProjectUsers.map((array) =>
      array
        .map((user) => (usersMaxScheduleTime(user) / 60) * this.formatMapping[bookingFormat] + 17)
        .reduce((partialSum, a) => partialSum + a, 50)
    );

    userHeights[0] += 250;

    const offsetY = e.pageY + document.querySelector("#planner-scroll-list")?.scrollTop;

    let offsetHeight = 0;
    let selectedProject;

    userHeights.forEach((height, i) => {
      if (!selectedProject && (offsetY < height + offsetHeight || i + 1 === userHeights.length)) {
        selectedProject = shownProjects[i];
      }

      offsetHeight += height;
    });

    if (selectedProject?.active) {
      return selectedProject;
    } else {
      return activeProjects.find((pr) => pr.active) || projects.find((pr) => pr.active);
    }
  }
}

export default GroupedPlannerActions;
