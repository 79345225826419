import { SliceStringWithDots } from "../../../lib/StringFormat";
import PropTypes from "prop-types";

const PdfUserBooking = ({ booking }) => {
  return (
    <div
      className="booking"
      style={{
        backgroundColor: booking.tentative
          ? "transparent"
          : booking.project_color + "B3",
        border: booking.tentative
          ? "2px solid " + booking.project_color
          : "#ffffffB3",
        height: `${(137 / 8) * (booking.duration / 60)}px`,
        zIndex: 1,
        position: "relative",
      }}
    >
      {!booking.tentative && (
        <div
          className="booking-line"
          style={{
            backgroundColor: booking.project_color,
            width: 4,
          }}
        />
      )}
      <p
        style={{
          color: booking.tentative
            ? booking.project_color
            : "var(--background-1)",
        }}
      >
        {SliceStringWithDots(booking.project_code, 6)}
      </p>

      <p
        style={{
          color: booking.tentative
            ? booking.project_color
            : "var(--background-1)",
        }}
      >
        {booking.duration / 60}h
      </p>
    </div>
  );
};

PdfUserBooking.propTypes = {
  booking: PropTypes.object,
};

export default PdfUserBooking;
