import { useDragLayer } from "react-dnd";
import { useFilteredUsersStore, useMoveUserStore } from "../../../stores/planner";
import UserAvatarCell from "./UserAvatar";
import $ from "jquery";
import { siteOffsetY } from "../../../lib/PlannerUtils";

const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
};

export const DragLayer = ({ calculateCellHeight, bookingFormat, departments }) => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  function getItemStyles(initialOffset, currentOffset) {
    if (!initialOffset || !currentOffset) {
      return {
        display: "none",
      };
    }
    let { x, y } = currentOffset;

    if (y < siteOffsetY()) {
      y = siteOffsetY();
    }

    let lastUserRow = $(
      "#user-row-" +
        useFilteredUsersStore.getState().filteredUsers[useFilteredUsersStore.getState().filteredUsers.length - 1].id
    );

    let maxOffsetY = 0;

    if (lastUserRow.length) {
      maxOffsetY = lastUserRow.offset().top;

      if (y > maxOffsetY) {
        y = maxOffsetY;
      }
    }

    const transform = `translate(${30}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }

  function renderItem() {
    switch (itemType) {
      case "USER":
        return (
          <UserAvatarCell
            isDraggable={false}
            user={useMoveUserStore.getState().startUser}
            bookingFormat={bookingFormat}
            showButtons={false}
            setIsSwappingUsers={() => {}}
            key={`user-avatar-cell-${useMoveUserStore.getState().startUser.id}}`}
            height={calculateCellHeight(
              useFilteredUsersStore
                .getState()
                .filteredUsers.findIndex((user) => useMoveUserStore.getState().startUser.id === user.id)
            )}
            departments={departments}
            style={{
              background: "var(--background-2)",
            }}
            dragging
          />
        );
      default:
        return null;
    }
  }
  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>{renderItem()}</div>
    </div>
  );
};
