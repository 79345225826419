import { useTranslation } from "react-i18next";
import { ICON_COLOR, TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import lsKeys from "../default_values/defaultKeys";
import { useProfileStore } from "../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import Header from "../header/Header";

const NoMatch = ({ type = "notFound" }) => {
  const { t } = useTranslation();

  const [profile] = useProfileStore((state) => [state.profile], shallow);

  const role = localStorage.getItem(lsKeys.ROLE) || "regular";

  const errorMessage = {
    notFound: {
      header: t("oops_error"),
      description: t("cant_find_page"),
      icon: <TeambookIcon className="notfound_img" color={ICON_COLOR.ALTERNATIVE} name={icons.ERROR_404} />,
    },
    noAccess: {
      header: t("access_blocked"),
      description: t("access_blocked_message", {
        role: t(`users.${role}`),
      }),
      icon: (
        <TeambookIcon
          className="notfound_img blocked-role_img"
          color={ICON_COLOR.ALTERNATIVE}
          name={icons.NEW_ERROR_BG}
        />
      ),
    },
  };

  if (!profile) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Header />
      <div className="row header-error__container" id="404-page">
        <p className="error__header">{errorMessage[type].header}</p>
        <p className="text">{errorMessage[type].description}</p>
      </div>
      {errorMessage[type].icon}
    </>
  );
};

NoMatch.propTypes = {
  type: PropTypes.string,
};

export default NoMatch;
