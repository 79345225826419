import { useEffect, useState } from "react";
import NewUser from "./NewUser/NewUser";
import ShowUser from "./ShowUser/ShowUser";
import EditUser from "./EditUser/EditUser";
import { useTeambookDataStore } from "../../../stores/users";
import PropTypes from "prop-types";

const UserForm = ({
  analyzeAllUsersResponse,
  getAllUsers,
  setDisplayingActiveUsers,
  deleteUserFunction,
  displayingActiveUsers,
  copyCalendar,
  activateUsers,
}) => {
  const [teams, user, userFormState] = useTeambookDataStore((state) => [
    state.teams,
    state.chosenUser,
    state.userFormState,
    state.selectedUsers,
    state.setSelectedUsers,
  ]);
  const [userTeams, setUserTeams] = useState([]);

  useEffect(() => {
    if (userFormState !== "create") {
      setUserTeams(
        user.team_ids.map((user_team) => {
          return teams.find((team) => team.id === user_team);
        })
      );
    }
  }, [user]);

  const closeShow = () => {
    useTeambookDataStore.getState().setUserFormState("");
    useTeambookDataStore.getState().setChosenUser(null);
    useTeambookDataStore.getState().setTab("general");
  };

  const onEdit = () => {
    useTeambookDataStore.getState().setUserFormState("edit");
  };

  if (userFormState === "create") {
    return (
      <NewUser analyzeAllUsersResponse={analyzeAllUsersResponse} setDisplayingActiveUsers={setDisplayingActiveUsers} />
    );
  } else if (userFormState === "show") {
    return (
      <ShowUser
        teams={userTeams}
        onEdit={onEdit}
        closeShow={closeShow}
        deleteUserFunction={deleteUserFunction}
        activateUserFunction={activateUsers}
        displayingActiveUsers={displayingActiveUsers}
        copyCalendar={copyCalendar}
        getAllUsers={getAllUsers}
      />
    );
  } else if (userFormState === "edit") {
    return <EditUser getAllUsers={getAllUsers} />;
  }

  return <div />;
};

UserForm.propTypes = {
  analyzeAllUsersResponse: PropTypes.func,
  getAllUsers: PropTypes.func,
  setDisplayingActiveUsers: PropTypes.func,
  deleteUserFunction: PropTypes.func,
  displayingActiveUsers: PropTypes.bool,
  copyCalendar: PropTypes.func,
  activateUsers: PropTypes.func,
};

export default UserForm;
