import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import { icons } from "../../../default_images/IconsList";
import TeambookIconButton from "../../../default_components/TeambookIconButton";
import PropTypes from "prop-types";

const AddUserPlusButton = ({ handleClickOpen, disabled }) => {
  const { t } = useTranslation();

  const disableButton = () => {
    return {
      opacity: disabled && "0.4",
      pointerEvents: disabled && "none",
    };
  };

  return (
    <WhiteTooltip title={t("planning.add_user")}>
      <div
        style={{
          pointerEvents: disabled && "none",
        }}
        onClick={handleClickOpen}
      >
        <TeambookIconButton name={icons.ADD_PLUS} style={disableButton()} />
      </div>
    </WhiteTooltip>
  );
};

AddUserPlusButton.propTypes = {
  handleClickOpen: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AddUserPlusButton;
