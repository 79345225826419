import PropTypes from "prop-types";
import "./projection.scss";
import { DateTime } from "luxon";
import ProjectionDayBookings from "./ProjectionDayBookings";

const ProjectionUserBookings = ({ user, bookings, date, isClientSharing, tasks }) => {
  const userBookings = bookings.filter((booking) => booking.user_id === user.id);
  let weekColumns = [];

  for (let i = 0; i < 12; i++) {
    let dayColumns = [];

    for (let j = 0; j < 7; j++) {
      let relativeDate = date.plus({ day: i * 7 + j });

      const bookings = userBookings.filter((booking) => booking.date === relativeDate.toFormat("yyyy-MM-dd"));

      const isDayOff = relativeDate.weekday > 5;
      const isLessToday = relativeDate < DateTime.now().minus({ day: 1 });

      dayColumns.push(
        <div className={`projection__day ${isDayOff && "weekends"}`}>
          {bookings.length > 0 && (
            <ProjectionDayBookings
              bookings={bookings}
              isLessToday={isLessToday}
              isClientSharing={isClientSharing}
              tasks={tasks}
            />
          )}
        </div>
      );
    }

    weekColumns.push(<div className="projection__week-column">{dayColumns}</div>);
  }

  return <div className="projection__weeks-container">{weekColumns}</div>;
};

ProjectionUserBookings.propTypes = {
  user: PropTypes.object.isRequired,
  bookings: PropTypes.array.isRequired,
  date: PropTypes.object.isRequired,
  isClientSharing: PropTypes.bool.isRequired,
  tasks: PropTypes.array.isRequired,
};

export default ProjectionUserBookings;
