import React, { useState } from "react";
import Api from "../../Api";
import "./login.css";
import "../default_styles/tb-colors.css";

import { useTranslation } from "react-i18next";
import TeambookBlueButton from "../default_components/TeambookBlueButton";
import TeambookTextForm from "../default_components/TeambookTextForm";
import PasswordRules from "../register/PasswordRules";
import { ICON_COLOR, TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";

const ForgotPassword = ({ email: initialEmail, setForgotPasswordOpen }) => {
  const [email, setEmail] = useState(initialEmail);
  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [passwordRulesVisible, setPasswordRulesVisible] = useState(false);
  const { t } = useTranslation();

  const switchPasswordShow = () => {
    setPasswordShown(!passwordShown);
  };

  const switchConfirmPasswordShow = () => {
    setConfirmPasswordShow(!confirmPasswordShow);
  };

  const sendCode = () => {
    Api.Auth.forgot_password(email)
      .then((response) => {
        setCodeSent(true);
        setErrorMessage(null);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error);
        setErrorField("email");
      });
  };

  const verifyCode = () => {
    let letterRegex = /.*[^0-9].*/;
    let numberRegex = /.*[0-9].*/;

    let lengthPassword = password.length >= 8;
    let letterRule = letterRegex.test(password);
    let numberRule = numberRegex.test(password);

    if (!lengthPassword || !letterRule || !numberRule) {
      setErrorMessage("Please check your password to fit all rules.");
      setErrorField("password");
      return;
    }

    if (password !== passwordConfirmation) {
      setErrorMessage("Passwords do not match.");
      setErrorField("password_confirmation");
      return;
    }

    Api.Auth.update_password(code, password)
      .then((response) => {
        setPasswordUpdated(true);
        setErrorMessage(null);
        setErrorField(null);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error.message);
        setErrorField("code");
      });
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
    setErrorField(null);
  };

  const PasswordRulesShow = () => {
    setPasswordRulesVisible(true);
  };

  return (
    <div className="login">
      <div className="login__left-side mobile_hidden">
        <div className="auth__left-side-background" />

        <div className="login__left-side-images">
          <TeambookIcon
            name={icons.TB_LOGO}
            color={ICON_COLOR.ALTERNATIVE}
            className="register__tb-logo mobile_hidden"
          />

          <TeambookIcon
            name={icons.NEW_LOGIN_BG}
            color={ICON_COLOR.ALTERNATIVE}
            style={{ left: 0, right: 0, margin: "0 auto", top: 210, zIndex: 2 }}
          />
        </div>
      </div>

      <div className="login__right-side">
        <TeambookIcon
          name={icons.TB_LOGO}
          color={ICON_COLOR.ALTERNATIVE}
          className="login__tb-logo desktop_hidden"
          style={{ top: 40, left: 30, position: "absolute" }}
        />
        <div className="login__form">
          <p className="login__input-side__login-text">{t("auth.reset_password")}</p>

          <p
            onClick={() => setForgotPasswordOpen(false)}
            className="register__input-side__login-link"
            style={{ margin: 0, cursor: "pointer" }}
          >
            {t("auth.go_back")}
          </p>

          {codeSent ? (
            passwordUpdated ? (
              <div className="login__input-side__form__data">
                <p style={{ color: "var(--text-2)" }}>{t("auth.password_updated")}</p>
              </div>
            ) : (
              <div className="login__input-side__form__data">
                <TeambookTextForm
                  className="text-field"
                  fieldName={t("auth.code")}
                  variant="outlined"
                  type="text"
                  onChange={setCode}
                  value={code}
                  errorField={errorField}
                  errorMessage={errorMessage}
                  closeErrorMessage={closeErrorMessage}
                  field={"code"}
                />

                <div style={{ position: "relative" }}>
                  <TeambookTextForm
                    className="text-field"
                    fieldName={t("auth.password")}
                    placeholder={t("auth.min_8_chars")}
                    type={passwordShown ? "text" : "password"}
                    onChange={setPassword}
                    value={password}
                    onFocus={PasswordRulesShow}
                    isPassword
                    showPass={passwordShown}
                    setShowPass={switchPasswordShow}
                    errorField={errorField}
                    errorMessage={errorMessage}
                    closeErrorMessage={closeErrorMessage}
                    field={"password"}
                    style={{ marginBottom: errorMessage && errorField === "password" ? 25 : 0 }}
                  />
                </div>

                <PasswordRules password={password} visible={passwordRulesVisible} />

                <div style={{ position: "relative" }}>
                  <TeambookTextForm
                    className="text-field"
                    fieldName={t("auth.password_confirmation")}
                    placeholder={t("auth.min_8_chars")}
                    type={confirmPasswordShow ? "text" : "password"}
                    onChange={setPasswordConfirmation}
                    value={passwordConfirmation}
                    isPassword
                    showPass={confirmPasswordShow}
                    setShowPass={switchConfirmPasswordShow}
                    errorField={errorField}
                    errorMessage={errorMessage}
                    closeErrorMessage={closeErrorMessage}
                    field={"password_confirmation"}
                  />
                </div>

                <TeambookBlueButton
                  className="login__input-side__submit"
                  text={t("auth.update_password")}
                  style={{ width: "100%" }}
                  onClick={verifyCode}
                />
              </div>
            )
          ) : (
            <div className="login__input-side__form__data">
              <div className="form-value">
                <TeambookTextForm
                  className="text-field"
                  fieldName={t("auth.email")}
                  placeholder="john_smith@mail.com"
                  type="email"
                  onChange={setEmail}
                  fieldValue={email}
                  errorField={errorField}
                  errorMessage={errorMessage}
                  closeErrorMessage={closeErrorMessage}
                  field={"email"}
                />
              </div>

              <TeambookBlueButton
                text={t("auth.send_code")}
                style={{ width: "100%", marginTop: 10 }}
                onClick={sendCode}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
