import { useTranslation } from "react-i18next";
import moment from "moment";
import { TeambookReactSelect } from "../../default_components/TeambookReactSelect";
import { InputNumber } from "../../default_components/InputNumber";
import { useEffect } from "react";
import PropTypes from "prop-types";
import TeambookRadioButton from "../../default_components/TeambookRadioButton";
import TeambookDatePicker from "../../default_components/teambook_date_selector/DatePicker";
import { DateTime } from "luxon";
import { icons } from "../../default_images/IconsList";
import { TeambookIcon, ICON_COLOR } from "../../default_images/TeambookIcon";
import { TeambookCheckbox } from "../../default_components/TeambookCheckbox";

const BookingRepetition = ({
  repetitionType,
  setRepetitionType,
  repetitionEvery,
  creationBookingsArray,
  setRepetitionEvery,
  repetitionEndsAfter,
  setRepetitionEndsAfter,
  repetitionEndsAfterNumber,
  setRepetitionEndsAt,
  repetitionEndsAt,
  setRepetitionEndsAfterNumber,
  repetitionEndsAtDate,
  setRepetitionEndsAtDate,
  repetitionIgnoreWeekends,
  setRepetitionIgnoreWeekends,
}) => {
  const { t, i18n } = useTranslation();
  moment.locale(i18n.language);

  const weekdayWithCapital = (date) => {
    return moment(date)
      .format("dddd")
      .replace(/^\w/, (c) => c.toUpperCase());
  };

  const repetitionEveryHash = () => {
    //1, 2, 3, ..., 30, 31
    let repetitions = Array.from({ length: 31 }, (_, i) => i + 1);

    if (repetitionType === "weekly") {
      repetitions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    } else if (repetitionType === "monthly") {
      repetitions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    } else if (repetitionType === "yearly") {
      repetitions = [1, 2, 3, 4, 5];
    } else if (repetitionType === "custom") {
      repetitions = [1, 2, 3, 4];
    }

    return repetitions.map(function (el) {
      return {
        value: el,
        label: el,
        name: el,
      };
    });
  };

  const repetitionOptions = () => {
    const values = [
      {
        value: "weekly",
        label: t("planning.4_weeks"),
      },
      {
        value: "monthly",
        label: t("planning.3_months"),
      },
      {
        value: "yearly",
        label: t("planning.years"),
      },
      // {
      //   value: "custom",
      //   label: t("planning.custom"),
      // },
    ];

    if ([...new Set(creationBookingsArray.map((data) => data[0]))].length === creationBookingsArray.length) {
      values.unshift({
        value: "daily",
        label: t("planning.days"),
      });
    }

    return values;
  };

  useEffect(() => {
    if (
      [...new Set(creationBookingsArray.map((data) => data[0]))].length !== creationBookingsArray.length &&
      repetitionType === "daily"
    ) {
      setRepetitionType("weekly");
    }
  }, [creationBookingsArray]);

  return (
    <>
      <div className="booking-window__repetition-container">
        <div className="booking-window__repetition-container__header">
          <p>{t("planning.every")}:</p>
        </div>

        <div className="booking-window__repetition-row">
          <TeambookRadioButton
            fieldName={""}
            checked={repetitionType !== "custom"}
            onCheck={() => setRepetitionType("daily")}
            options={repetitionOptions()}
          />

          <InputNumber
            value={repetitionEvery.value}
            setValue={(value) => {
              setRepetitionEvery({
                value: value,
                label: value,
              });
            }}
            min={1}
            max={31}
            step={1}
            showDropdown
            dropdownOptions={repetitionEveryHash()}
            arrowSize={16}
          />

          <TeambookReactSelect
            fieldName={""}
            value={repetitionOptions().find((option) => option.value === repetitionType)}
            onChange={(value) => {
              setRepetitionType(value.value);
            }}
            menuPlacement={"top"}
            isSearchable={false}
            options={repetitionOptions()}
            width={"100%"}
            minWidth={"100%"}
            height={32}
            style={{ width: "100%" }}
          />
        </div>

        <div className="booking-window__repetition-row">
          <TeambookRadioButton checked={repetitionType === "custom"} onCheck={() => setRepetitionType("custom")} />
          <p>
            {`${Math.ceil(creationBookingsArray[0][1].getDate() / 7)} ${weekdayWithCapital(
              creationBookingsArray[0][1]
            )} ${t("planning.of_month")}`}
          </p>
        </div>

        <div className="booking-window__repetition-container__header" style={{ marginTop: 20 }}>
          <p>{t("planning.will_end")}</p>
        </div>

        <div className="booking-window__repetition-row">
          <div className="booking-window__repetition-end-switch">
            <div
              className={`booking-window__repetition-end-switch__option ${repetitionEndsAfter ? "active" : ""}`}
              onClick={() => {
                setRepetitionEndsAfter(true);
                setRepetitionEndsAt(false);
              }}
            >
              <p>{t("planning.repetition_after")}</p>
            </div>
            <div
              className={`booking-window__repetition-end-switch__option ${repetitionEndsAt ? "active" : ""}`}
              onClick={() => {
                setRepetitionEndsAfter(false);
                setRepetitionEndsAt(true);
              }}
            >
              <p>{t("planning.repetition_on")}</p>
            </div>
          </div>

          {repetitionEndsAfter && (
            <div className="booking-window__repetition-row__input-number">
              <InputNumber
                value={repetitionEndsAfterNumber}
                setValue={(value) => setRepetitionEndsAfterNumber(value)}
                min={0}
                max={100}
                step={1}
                arrowSize={16}
              />

              <p>{t("planning.times")}</p>
            </div>
          )}

          {repetitionEndsAt && (
            <div className="booking-window__repetition-row__input-number">
              <TeambookDatePicker
                variant="borderedInline"
                height={32}
                formatStart="d/MM/y"
                from={DateTime.fromJSDate(repetitionEndsAtDate)}
                onDayClick={(date) => setRepetitionEndsAtDate(date.toJSDate())}
                type="default"
                highlight="selected"
                closeOnSelect={true}
                width={130}
                range={1}
                disabledRanges={["1_year"]}
                customIcon={
                  <TeambookIcon name={icons.CALENDAR} color={ICON_COLOR.ALTERNATIVE} style={{ marginLeft: 8 }} />
                }
                menuPosition="left"
              />
            </div>
          )}
        </div>

        {repetitionType !== "custom" && (
          <div className="booking-window__repetition-ignore-weekends">
            <TeambookCheckbox
              checked={repetitionIgnoreWeekends}
              onChange={() => setRepetitionIgnoreWeekends(!repetitionIgnoreWeekends)}
              color="primary"
            />
            <p className="ignore-weekends" onClick={() => setRepetitionIgnoreWeekends(!repetitionIgnoreWeekends)}>
              {t("planning.ignore_weekends")}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

BookingRepetition.propTypes = {
  repetitionType: PropTypes.string.isRequired,
  setRepetitionType: PropTypes.func.isRequired,
  repetitionEvery: PropTypes.object.isRequired,
  setRepetitionEvery: PropTypes.func.isRequired,
  repetitionEndsAfter: PropTypes.bool.isRequired,
  setRepetitionEndsAfter: PropTypes.func.isRequired,
  repetitionEndsAt: PropTypes.bool.isRequired,
  setRepetitionEndsAt: PropTypes.func.isRequired,
  repetitionEndsAfterNumber: PropTypes.number.isRequired,
  setRepetitionEndsAfterNumber: PropTypes.func.isRequired,
  repetitionEndsAtDate: PropTypes.object.isRequired,
  setRepetitionEndsAtDate: PropTypes.func.isRequired,
  repetitionIgnoreWeekends: PropTypes.bool.isRequired,
  setRepetitionIgnoreWeekends: PropTypes.func.isRequired,
  creationBookingsArray: PropTypes.array.isRequired,
  theme: PropTypes.string.isRequired,
  arrowColor: PropTypes.string.isRequired,
};

export default BookingRepetition;
