import {
  DaysOfTheWeek,
  OffsetDays,
  GetDaysInZoom,
  GetStartDateForPlannerDate,
  ToDateString,
  getYearWeekNumber,
  getCellWidthForZoom,
} from "../../../../lib/DateUtils";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import GenerateMonthRow from "../../lib/GenerateMonthRow";
import { useDateStore, useZoomStore } from "../../../../stores/planner";
import { shallow } from "zustand/shallow";
import { GROUPED_PLANNER_USER_WIDTH } from "../../../../lib/PlannerUtils";
import { isWeekend } from "date-fns";
import { MonthSeparator } from "../../users_planner/components/MonthSeparator";
import { MonthName } from "../../default_components/MonthName";
import { DateTime } from "luxon";
import PropTypes from "prop-types";

const DateCell = ({ date, allRowsCreation, displayAsRedText, weekendsHidden, isLastCell }) => {
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);
  let dateText = `${DaysOfTheWeek()[date.getDay()]} ${date.getDate()}`;
  const today = new Date();

  if (zoom === 90) {
    dateText = `${date.getDate()}`;
  }

  if (ToDateString(date) === ToDateString(today)) {
    return (
      <div className={`date-cell-header-${zoom} date-row__date-cell__today`} onClick={() => allRowsCreation(date)}>
        <p className="date-row__date-text__today ">{dateText}</p>
      </div>
    );
  } else if (date < today) {
    return (
      <div
        className={`date-cell-header-${zoom} date-row__date-cell__past`}
        style={{
          borderRight:
            date.getDay() === 0
              ? "1px solid var(--stroke)"
              : date.getDay() === 5 && !weekendsHidden && "1px solid var(--stroke)",
          borderTopRightRadius: isLastCell ? 7 : 0,
          backgroundColor: [6, 0].includes(date.getDay()) && "var(--background-2)",
        }}
        onClick={() => allRowsCreation(date)}
      >
        <p
          className="date-row__date-text"
          style={{
            color: displayAsRedText ? "var(--text-1)" : "var(--text-13)",
          }}
        >
          {dateText}
        </p>

        <div className="planner-overlap" />

        {/* {monthName(zoom, date, t)} */}
      </div>
    );
  }

  return (
    <div
      className={`date-cell-header-${zoom} date-row__date-cell`}
      style={{
        borderRight:
          date.getDay() === 0
            ? "1px solid var(--stroke)"
            : date.getDay() === 5 && !weekendsHidden && "1px solid var(--stroke)",
        borderTopRightRadius: isLastCell ? 7 : 0,
        backgroundColor: [6, 0].includes(date.getDay()) && "var(--background-2)",
      }}
    >
      <p className="date-row__date-text" onClick={() => allRowsCreation(date)}>
        {dateText}
      </p>

      {/* {monthName(zoom, date, t)} */}
    </div>
  );
};

const GroupedDateRow = ({ weekendsHidden, weekendStart, userOrder, changeOrder, groupClients }) => {
  const { t } = useTranslation();

  const [zoom] = useZoomStore((state) => [state.zoom], shallow);
  const [date] = useDateStore((state) => [state.date], shallow);

  let days = GetDaysInZoom(zoom);
  const firstPlannerDate = GetStartDateForPlannerDate(date, zoom);
  let filterOrder = localStorage.getItem("groupedPlannerUserOrder");

  const setAlphabeticalOrder = () => {
    if (userOrder === "a") {
      changeOrder("z");
    } else {
      changeOrder("a");
    }
  };

  let rows = [];
  let monthRow = GenerateMonthRow(zoom, firstPlannerDate, days, weekendsHidden, weekendStart, 161, date, groupClients);

  const weekInRange = Math.floor(
    DateTime.fromJSDate(firstPlannerDate)
      .plus({ days: GetDaysInZoom(zoom) })
      .diff(DateTime.fromJSDate(firstPlannerDate), "weeks").weeks
  );

  const calculateLeftOffset = () => {
    return groupClients ? "50px" : 0;
  };

  rows.push(
    <div
      className="grouped-planner__filter-cell"
      key={`filter-cell-${firstPlannerDate}`}
      style={{
        left: calculateLeftOffset(),
        width: GROUPED_PLANNER_USER_WIDTH,
        borderLeft: groupClients && "none",
      }}
    >
      <div style={{ borderRadius: groupClients && "unset" }} className="filter-cell">
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <WhiteTooltip title={t("planning.alphabetical_order")}>
            {localStorage.getItem("groupedPlannerUserOrder") === "a" ? (
              <div className="date-row__sort-icon" onClick={setAlphabeticalOrder} style={{ color: "var(--text-1)" }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.4082 3.01367L3.17773 11H2.41602L7.01562 2.47461H7.54883L7.4082 
                  3.01367ZM8.63867 11L7.14453 2.94922L7.19141 2.46875H7.68945L9.34766 11H8.63867ZM8.63867
                  7.98828L8.5332 8.60938H4.13281L4.23242 7.98828H8.63867ZM8.99023 20.3848L8.88477 21H3.17773L3.2832 
                  20.3848H8.99023ZM10.0859 13.0195L3.39453 21H2.92578L3.01953 20.4199L9.69922 12.4746H10.1797L10.0859
                  13.0195ZM9.875 12.4688L9.76953 13.084H4.44922L4.54883 12.4688H9.875Z"
                    fill="currentColor"
                  />
                  <path
                    d="M16.5 5V20M16.5 20L19 16.7391M16.5 20L14 16.7391"
                    stroke="currentColor"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            ) : (
              <div
                className="date-row__sort-icon"
                style={{ color: filterOrder === "z" ? "var(--text-1)" : "var(--text-15)" }}
              >
                <svg
                  id="alphabeticalOrder"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={setAlphabeticalOrder}
                >
                  <path
                    d="M8.99023 10.3848L8.88477 11H3.17773L3.2832 10.3848H8.99023ZM10.0859 
                  3.01953L3.39453 11H2.92578L3.01953 10.4199L9.69922 2.47461H10.1797L10.0859 3.01953ZM9.875
                  2.46875L9.76953 3.08398H4.44922L4.54883 2.46875H9.875ZM7.4082 13.0137L3.17773 21H2.41602L7.01562
                  12.4746H7.54883L7.4082 13.0137ZM8.63867 21L7.14453 12.9492L7.19141 12.4688H7.68945L9.34766 21H8.63867ZM8.63867
                  17.9883L8.5332 18.6094H4.13281L4.23242 17.9883H8.63867Z"
                    fill="currentColor"
                  />
                  <path
                    d="M16.5 5V20M16.5 20L19 16.7391M16.5 20L14 16.7391"
                    stroke="currentColor"
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </WhiteTooltip>
        </div>
      </div>
    </div>
  );

  for (let i = 1; i < days + 1; i++) {
    let newDate = OffsetDays(firstPlannerDate, i - 1);
    const displayAsRedText = !weekendsHidden && (weekendStart === i % 7 || (weekendStart + 1) % 7 === i % 7);

    if (weekendsHidden && (weekendStart === i % 7 || (weekendStart + 1) % 7 === i % 7)) {
      if (isWeekend(newDate)) {
        rows.push(
          <div style={{ width: 10 }} className="date-row__weekend-cell__container">
            <div
              className="date-row__weekend-cell"
              style={{
                borderLeft: newDate.getDay() === 0 && "unset",
              }}
              key={`date-cell-${newDate}`}
            >
              {/* {monthName(zoom, newDate, t, isLastCell || isSecondLastCell)} */}
            </div>
            {(weekendStart + 1) % 7 === i % 7 && i !== days && <div className="date-row__weekend-cell__right-border" />}
          </div>
        );
      }
    } else {
      rows.push(
        <DateCell
          key={`date-cell-${newDate}`}
          date={newDate}
          allRowsCreation={() => {}}
          displayAsRedText={displayAsRedText}
          weekendsHidden={weekendsHidden}
          isLastCell={i === days}
        />
      );
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "fit-content",
          position: "sticky",
          top: "0",
          zIndex: "5",
        }}
      >
        {groupClients && <div style={{ borderLeft: "unset" }} className="project-group__client-holder" />}
        <MonthSeparator zIndex={4} page="grouped" />

        <MonthName page="grouped" zIndex={4} />
        {monthRow}

        {Array.from({ length: weekInRange }).map((_, i) => {
          const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(zoom);
          const weekWidth = getCellWidthForZoom(zoom) * 5 + weekendWidth * 2;
          const clientsOffset = groupClients ? 50 : 0;

          return (
            <p
              style={{
                left: clientsOffset + GROUPED_PLANNER_USER_WIDTH + weekWidth * i,
                width: getCellWidthForZoom(zoom),
              }}
              key={`week-number-${i}`}
              className="date-cell-header__week-number"
            >
              {getYearWeekNumber(DateTime.fromJSDate(firstPlannerDate).plus({ week: i }))}
            </p>
          );
        })}
      </div>

      <div
        style={{
          display: "flex",
          width: "fit-content",
          position: "sticky",
          top: "34px",
          zIndex: "5",
        }}
      >
        {groupClients && <div className="project-group__client-holder date-row" />}

        {rows}
        <MonthSeparator zIndex={3} page="grouped" />
      </div>
    </>
  );
};

GroupedDateRow.propTypes = {
  weekendsHidden: PropTypes.bool.isRequired,
  weekendStart: PropTypes.number.isRequired,
  userOrder: PropTypes.string.isRequired,
  changeOrder: PropTypes.func.isRequired,
  groupClients: PropTypes.bool.isRequired,
};

DateCell.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  allRowsCreation: PropTypes.func.isRequired,
  displayAsRedText: PropTypes.bool.isRequired,
  weekendsHidden: PropTypes.bool.isRequired,
  isLastCell: PropTypes.bool.isRequired,
};

export default GroupedDateRow;
