import { memo } from "react";
import User from "./User";
import { TeambookReactSelect } from "../../default_components/TeambookReactSelect";
import Project from "./Project";
import BlueArrowDown from "../../default_images/darkblue_arrow_down.svg";
import { WhiteTooltip } from "../../default_components/Tooltips";
import { useTranslation } from "react-i18next";
import { sortEntityColumn } from "../lib/sortEntityColumn";
import { icons } from "../../default_images/IconsList";
import TeambookIconButton from "../../default_components/TeambookIconButton";
import PropTypes from "prop-types";

const UsersColumn = ({
  users,
  selectedUser,
  setSelectedUser,
  sortMode,
  allUserLogs,
  date,
  setSortMode,
  setSplitBy,
  splitBy,
  splitOptions,
  projects,
}) => {
  const { t } = useTranslation();

  return (
    <div className="users-column">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          gap: 10,
          paddingBottom: 16,
          marginTop: 2,
        }}
      >
        <WhiteTooltip title={t("planning.alphabetical_order")} placement={"bottom"}>
          <div>
            <TeambookIconButton
              name={sortMode === "z" ? icons.SORT_Z : icons.SORT_A}
              onClick={() => {
                if (sortMode === "z") {
                  setSortMode("a");
                } else {
                  setSortMode("z");
                }
              }}
            />
          </div>
        </WhiteTooltip>

        <div className="users-column__split-switcher">
          <TeambookReactSelect
            height={40}
            onChange={(option) => setSplitBy(option.value)}
            value={splitOptions.find((val) => val.value === splitBy)}
            options={splitOptions}
            dropdownArrow={BlueArrowDown}
          />
        </div>
        <WhiteTooltip placement="bottom" title={t("actuals.delta_sort")}>
          <div>
            <TeambookIconButton
              name={icons.DELTA}
              onClick={() => {
                if (sortMode === "delta_hours_default") {
                  setSortMode("delta_hours_reverse");
                } else {
                  setSortMode("delta_hours_default");
                }
              }}
            />
          </div>
        </WhiteTooltip>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          overflowY: "auto",
          height: "100%",
          width: 336,
        }}
      >
        {sortEntityColumn(splitBy, users, projects, sortMode, date, allUserLogs).map((entity) =>
          splitBy === "by_user" ? (
            <User
              key={entity.id}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              user={entity}
              allUserLogs={allUserLogs.filter((log) => log.user_id === entity.id)}
              date={date}
            />
          ) : (
            <Project
              key={entity.id}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              project={entity}
              allUserLogs={allUserLogs.filter((log) => log.project_id === entity.id)}
              date={date}
            />
          )
        )}
      </div>
    </div>
  );
};

UsersColumn.propTypes = {
  users: PropTypes.array.isRequired,
  selectedUser: PropTypes.object.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  sortMode: PropTypes.string.isRequired,
  allUserLogs: PropTypes.array.isRequired,
  date: PropTypes.object.isRequired,
  setSortMode: PropTypes.func.isRequired,
  setSplitBy: PropTypes.func.isRequired,
  splitBy: PropTypes.string.isRequired,
  splitOptions: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
};
export default memo(UsersColumn);
