import { useTranslation } from "react-i18next";
import { TeambookReactSelect } from "./TeambookReactSelect";
import { components } from "react-select";
import PropTypes from "prop-types";

const Option = (props) => {
  const { t } = useTranslation();

  return (
    <components.Option {...props}>
      <p className="tb-role-picker__role-name">{props.children}</p>
      <p className="tb-role-picker__role-description">{t(`users.${props.data.value}_description`)}</p>
    </components.Option>
  );
};

const EmptyDropdownIndicator = () => null;

export const TeambookAuthorizationPicker = ({ value, setValue, options, isDisabled }) => {
  const customComponents = {
    Option,
  };

  if (isDisabled) {
    customComponents.DropdownIndicator = EmptyDropdownIndicator;
  }

  return (
    <TeambookReactSelect
      width={260}
      maxMenuHeight={421}
      value={value}
      onChange={(option) => setValue(option)}
      options={options}
      grayPlaceholder
      newComponents={customComponents}
      isDisabled={isDisabled}
    />
  );
};

Option.propTypes = {
  children: PropTypes.string,
  data: PropTypes.object,
};

TeambookAuthorizationPicker.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func,
  options: PropTypes.array,
  isDisabled: PropTypes.bool,
};
