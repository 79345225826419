import lsKeys from "../../default_values/defaultKeys";
import TeambookDateSelector from "./TeambookDateSelector";
import { useRef, useState } from "react";
import { ICON_COLOR, TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import PropTypes from "prop-types";

const TeambookDatePicker = ({
  from,
  to,
  classes = {
    datePickerClassName: "",
    containerClassName: "tb-date-picker",
    arrowsBlockClassName: "",
  },
  formatStart,
  formatEnd,
  style,
  setRange = () => {},
  range,
  setFrom,
  type = "range", // default | range
  onArrowClicks,
  onDayClick = () => {},
  height = 40,
  closeOnSelect = false,
  highlight = "selected", // selected | today
  selectOnArrowClick = true,
  disabledRanges = ["6_months", "2_years", "1_year"],
  variant = "default",
  menuPosition,
  theme,
  arrowColor = ICON_COLOR.DEFAULT,
  width,
  errorMessage,
  errorField,
  name,
  customIcon,
}) => {
  const datePickerRef = useRef(null);
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false);
  const isRangePicker = type === "range";

  const hasError = errorMessage && errorField === name;

  const closeDatePicker = () => setIsDatePickerOpened(false);

  const classNamesByVariant = {
    default: "default_picker",
    inline: "inline_picker",
    borderedInline: "bordered_inline_picker",
  };

  return (
    <>
      <div
        style={{
          ...style,
          height: height || "unset",
          // width: width || 142,
          minWidth: width || 145,
          padding: "0px 10px",
        }}
        className={`${classes.containerClassName} ${classNamesByVariant[variant]} ${
          errorMessage && errorField === name ? "invalid-value" : ""
        }`}
      >
        <div
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", gap: 5 }}
          className={classes.arrowsBlockClassName}
        >
          {variant === "default" && (
            <TeambookIcon
              alt="arrow-left"
              className="arrow-block__arrow-left"
              name={icons.SMALL_ARROW_LEFT}
              color={arrowColor}
              theme={theme}
              style={{ padding: "6px 9px" }}
              onClick={() => {
                if (onArrowClicks) {
                  onArrowClicks.left();
                } else {
                  setFrom((pr) => ({
                    start: pr.start.minus({ month: 1 }).startOf("month"),
                    end: pr.end.minus({ month: 1 }).endOf("month"),
                  }));
                }
              }}
            />
          )}
          <div
            ref={datePickerRef}
            onClick={(e) => {
              setIsDatePickerOpened(true);
              datePickerRef.current.left = e.currentTarget.offsetLeft;
            }}
            className="tb-date-picker__date-show"
          >
            {+range === 1 ? (
              <p className="tb-date-picker__start-container">
                {from.setLocale(localStorage.getItem(lsKeys.LANGUAGE)).toFormat(formatStart || "MMM yyyy")}
              </p>
            ) : (
              <p>
                {from
                  .setLocale(localStorage.getItem(lsKeys.LANGUAGE))
                  .toFormat(formatStart + (from.year === to.year ? "" : " yyyy"))}
                {"  -  "}
                {to.setLocale(localStorage.getItem(lsKeys.LANGUAGE)).toFormat(formatEnd)}
              </p>
            )}
          </div>

          {variant === "default" && (
            <TeambookIcon
              className="arrow-block__arrow-right"
              alt="arrow-right"
              name={icons.SMALL_ARROW_LEFT}
              color={arrowColor}
              theme={theme}
              style={{ rotate: "180deg", padding: "6px 9px" }}
              onClick={() => {
                if (onArrowClicks) {
                  onArrowClicks.right();
                } else {
                  setFrom((pr) => ({
                    start: pr.start.plus({ month: 1 }).startOf("month"),
                    end: pr.end.plus({ month: 1 }).endOf("month"),
                  }));
                }
              }}
            />
          )}

          {isDatePickerOpened && (
            <TeambookDateSelector
              datePickerRef={datePickerRef}
              setRange={setRange}
              range={range}
              setSelectedDates={setFrom}
              selectedDates={{ start: from, end: to || from }}
              closeDatePicker={closeDatePicker}
              isRangePicker={isRangePicker}
              onDayClick={onDayClick}
              closeOnSelect={closeOnSelect}
              highlight={highlight}
              selectOnArrowClick={selectOnArrowClick}
              disabledRanges={disabledRanges}
              variant={variant}
              menuPosition={menuPosition}
              theme={theme}
            />
          )}
        </div>

        {["inline", "borderedInline"].includes(variant) &&
          (customIcon ? (
            <div
              className="tb-default-picker__inline-calendar-icon-container"
              onClick={() => setIsDatePickerOpened(true)}
            >
              {customIcon}
            </div>
          ) : (
            <TeambookIcon
              theme={theme}
              onClick={() => setIsDatePickerOpened(true)}
              className="tb-default-picker__inline-calendar-icon"
              name={icons.FILLED_CALENDAR}
            />
          ))}
      </div>

      {hasError && (
        <div className="picker-form__error-field">
          <TeambookIcon color={ICON_COLOR.COMMON} name={icons.WARNING} />
          <p>{errorMessage}</p>
        </div>
      )}
    </>
  );
};

TeambookDatePicker.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  formatStart: PropTypes.string.isRequired,
  formatEnd: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  setRange: PropTypes.func.isRequired,
  range: PropTypes.number.isRequired,
  setFrom: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  onArrowClicks: PropTypes.func.isRequired,
  onDayClick: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  closeOnSelect: PropTypes.bool.isRequired,
  highlight: PropTypes.string.isRequired,
  selectOnArrowClick: PropTypes.bool.isRequired,
  disabledRanges: PropTypes.array.isRequired,
  variant: PropTypes.string.isRequired,
  menuPosition: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  arrowColor: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  errorMessage: PropTypes.string.isRequired,
  errorField: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  customIcon: PropTypes.element,
};
export default TeambookDatePicker;
