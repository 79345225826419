import { useTranslation } from "react-i18next";
import { icons } from "../default_images/IconsList";
import { ICON_COLOR, TeambookIcon } from "../default_images/TeambookIcon";
import { DatePicker } from "@mui/x-date-pickers";
import PropTypes from "prop-types";

export default function MuiDatePicker({ errorMessage, errorField, name, closeErrorMessage = () => {}, ...props }) {
  const { t } = useTranslation();

  const hasError = errorMessage && errorField === name;

  const FilledCalendar = () => (
    <TeambookIcon
      style={{ width: 24, height: 24 }}
      name={icons.FILLED_CALENDAR}
      tooltipTitle={t("actuals.select_date")}
    />
  );

  return (
    <div className={`picker-form-component ${hasError ? "invalid-value" : ""}`}>
      <DatePicker
        {...props}
        onChange={(date) => {
          closeErrorMessage();
          return props.onChange(date);
        }}
        className={`${props.className} `}
        slots={{
          openPickerIcon: FilledCalendar,
        }}
      />

      {hasError && (
        <div className="picker-form__error-field">
          <TeambookIcon color={ICON_COLOR.COMMON} name={icons.WARNING} />
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
}

MuiDatePicker.propTypes = {
  errorMessage: PropTypes.string,
  errorField: PropTypes.string,
  name: PropTypes.string,
  closeErrorMessage: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};
