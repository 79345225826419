import PlannerActions from "./PlannerActions";
import {
  useBookingsCreatingStore,
  useBookingsEditingStore,
  useBookingsStore,
  useCopyBookingsStore,
  useDateStore,
  useSelectedProjectStore,
  useShiftPressedStore,
  useZoomStore,
} from "../../../../stores/planner";
import $ from "jquery";
import Api from "../../../../Api";
import { getCellWidthForZoom, getNumberOfDays, OffsetDays, ToDateString } from "../../../../lib/DateUtils";
import { siteOffsetX, siteOffsetY, usersMaxScheduleTime } from "../../../../lib/PlannerUtils";
import { useAccountStore } from "../../../../stores/accountStore";

class UserPlannerActions extends PlannerActions {
  mouseUped(
    offsetX,
    offsetY,
    users,
    e,
    multipleSelectRef,
    selectBooking,
    plannerDispatcher,
    weekendsHidden,
    bookingFormat,
    createBookingsRef,
    usersRef,
    closeEditWindow,
    openCreationWindow
  ) {
    if (useShiftPressedStore.getState().shiftPressed || useShiftPressedStore.getState().startSelected) {
      if (multipleSelectRef.current) {
        multipleSelectRef.current.setOpen(false);
        multipleSelectRef.current.setPos({ x: 0, y: 0 });
        multipleSelectRef.current.setSize({ width: 0, height: 0 });
      }

      const zoom = useZoomStore.getState().zoom;
      const scrollList = $("#planner-scroll-list");
      const scrollLeft = scrollList.scrollLeft();
      const scrollTop = scrollList.scrollTop();
      const startX = useShiftPressedStore.getState().startX;
      const startY = useShiftPressedStore.getState().startY;
      const clientY = e?.clientY - siteOffsetY(zoom) || 0;
      const endX = offsetX + scrollLeft;
      const endY = clientY + scrollTop;
      const cellWidth = getCellWidthForZoom();

      Array.from($(".booking")).forEach((booking) => {
        const bookingElement = $(booking);
        const bookingId = +booking.id.split("-")[1];
        const left = bookingElement.offset().left + scrollLeft - siteOffsetX(zoom);
        const right = left + cellWidth;
        const top = bookingElement.offset().top + scrollTop - siteOffsetY(zoom);
        const bottom = top + bookingElement.height();

        const intersectsX = left < endX && right > startX;
        const intersectsY = top < endY && bottom > startY;

        if (intersectsX && intersectsY) {
          selectBooking(this.getBookingById(bookingId));
        }
      });
    }

    if (useCopyBookingsStore.getState().copyModeActive && useCopyBookingsStore.getState().copyBookingIds.length > 0) {
      let new_bookings = useBookingsStore.getState().bookings;

      if (this.clickStartX === offsetX && this.clickStartY === offsetY && users[this.userIndexStart]) {
        Api.Bookings.copy(
          useCopyBookingsStore.getState().copyBookingIds,
          users[this.userIndexStart].id,
          ToDateString(this.userDateStart)
        ).then((response) => {
          useBookingsStore.getState().setBookings([...new_bookings, ...response.data]);

          plannerDispatcher.afterCreationBookingAssignment([this.userIndexStart]);

          this.userIndexStart = null;
          this.userDateStart = null;
        });
      }
    } else {
      if (
        this.clickStartX === offsetX &&
        this.clickStartY === offsetY &&
        e.button === 0 &&
        !useCopyBookingsStore.getState().copyModeActive
      ) {
        if (!this.userDateStart) {
          this.calculateUserDateStart(offsetX, offsetY, users);
        }

        if (!this.userDateStart) return;

        const isWeekend =
          (weekendsHidden && this.userDateStart.getDay() === 0) ||
          (weekendsHidden && this.userDateStart.getDay() === 6);

        const isCreatedInThisDate = createBookingsRef.current[this.userIndexStart]?.bookings?.some(
          (b) => b[0] === this.userIndexStart && b[1].getTime() === this.userDateStart.getTime()
        );

        // This part draws creation bookings in case of click creation.
        if (!isCreatedInThisDate && !isWeekend && createBookingsRef.current[this.userIndexStart]) {
          if (this.shiftCreation) {
            this.shiftCreation = false;

            createBookingsRef.current.forEach((bookingRef) => {
              bookingRef.updateBookings([]);
            });

            createBookingsRef.current[this.userIndexStart].updateBookings([[this.userIndexStart, this.userDateStart]]);

            useBookingsCreatingStore.getState().setBookings([this.userIndexStart, this.userDateStart]);
          } else {
            createBookingsRef.current[this.userIndexStart].updateBookings([
              ...createBookingsRef.current[this.userIndexStart].bookings,
              [this.userIndexStart, this.userDateStart],
            ]);

            useBookingsCreatingStore.getState().addBooking([this.userIndexStart, this.userDateStart]);
          }

          createBookingsRef.current.forEach((p) => {
            p.updateDuration(useAccountStore.getState().account?.default_booking_duration / 60);
          });

          let duration = useAccountStore.getState().account?.default_booking_duration;
          if (useSelectedProjectStore.getState().projectsType === "time_off") {
            const weekDay = this.userDateStart.getDay();
            const userSchedule = users[this.userIndexStart].schedule;
            duration = userSchedule[weekDay][0] + userSchedule[weekDay][2];

            createBookingsRef.current.forEach((ref) => {
              ref.updateDuration(duration / 60);
            });
          }

          useBookingsCreatingStore.getState().setDuration(duration / 60);

          closeEditWindow();
          openCreationWindow();
        }
      } else {
        if (this.userIndexStart !== null && e.button === 0 && !e.ctrlKey) {
          let duration = createBookingsRef?.current.find((ref) => ref.bookings.length > 0)?.duration;

          useBookingsCreatingStore.getState().setDuration(duration);

          if (duration) {
            openCreationWindow();
          }
        }
      }

      this.userIndexStart = null;
      this.userDateStart = null;
    }

    useShiftPressedStore.getState().setStartSelected(false);
    useShiftPressedStore.getState().setShiftPressed(false);

    this.multipleCreation = false;

    super.mouseUped(offsetX, offsetY, users, e);
  }

  // Sorry for this code shit, @DagonWat created it, but nobody understands it anymore :(
  mouseMoved(offsetX, offsetY, users, weekendsHidden, multipleSelectRef, bookingFormat, createBookingsRef) {
    if (useShiftPressedStore.getState().shiftPressed || useShiftPressedStore.getState().startSelected) {
      let _height = 0;
      const userHeights = users.map(
        (user) => (usersMaxScheduleTime(user) / 60) * this.formatMapping[bookingFormat] + 17
      );

      userHeights.forEach((userHeight, index) => {
        if (
          _height < offsetY + $("#planner-scroll-list").scrollTop() &&
          _height + userHeight > offsetY + $("#planner-scroll-list").scrollTop()
        ) {
          this.userIndexEnd = index;

          const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(useZoomStore.getState().zoom);
          const dayWidth = getCellWidthForZoom(useZoomStore.getState().zoom);

          multipleSelectRef.current.setPos({
            x: useShiftPressedStore.getState().startX,
            y: useShiftPressedStore.getState().startY,
          });

          let calculatedOffsetX = 0;
          let dateIteration = 0;

          while (calculatedOffsetX < offsetX + $("#planner-scroll-list").scrollLeft()) {
            calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
            dateIteration++;
          }

          multipleSelectRef.current.setSize({
            width: offsetX + $("#planner-scroll-list").scrollLeft() - useShiftPressedStore.getState().startX,
            height: offsetY + $("#planner-scroll-list").scrollTop() - useShiftPressedStore.getState().startY,
          });

          this.userDateEnd = OffsetDays(useDateStore.getState().date, dateIteration - 1);
        }

        _height += userHeight;
      });
    } else {
      if (this.userIndexStart === null || useCopyBookingsStore.getState().copyModeActive) {
        return null;
      }

      multipleSelectRef.current.setPos({ x: 0, y: 0 });
      multipleSelectRef.current.setSize({ width: 0, height: 0 });

      const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(useZoomStore.getState().zoom);
      const dayWidth = getCellWidthForZoom(useZoomStore.getState().zoom);
      let _height = 0;
      const userHeights = users.map(
        (user) => (usersMaxScheduleTime(user) / 60) * this.formatMapping[bookingFormat] + 17
      );

      userHeights.forEach((userHeight, index) => {
        if (
          _height < offsetY + $("#planner-scroll-list").scrollTop() &&
          _height + userHeight > offsetY + $("#planner-scroll-list").scrollTop()
        ) {
          this.userIndexEnd = index;

          let calculatedOffsetX = 0;
          let dateIteration = 0;

          while (calculatedOffsetX < offsetX + $("#planner-scroll-list").scrollLeft()) {
            calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
            dateIteration++;
          }

          this.userDateEnd = OffsetDays(useDateStore.getState().date, dateIteration - 1);
        }

        _height += userHeight;
      });

      let currentHeight = 0;

      for (let i = 0; i < this.userIndexEnd; i++) {
        currentHeight += userHeights[i];
      }

      let currentBookingsHeight = 0;

      let currentBookings =
        useBookingsStore.getState().bookings?.filter((booking) => {
          return (
            booking.user_id === users[this.userIndexEnd].id &&
            booking.date === ToDateString(this.userDateEnd) &&
            booking.deleted_at === null
          );
        }) || [];

      currentBookings.forEach((booking) => {
        currentBookingsHeight += (booking.duration / 60) * this.formatMapping[bookingFormat];
      });

      let newDuration =
        (offsetY + $("#planner-scroll-list").scrollTop() - currentHeight - currentBookingsHeight) /
        this.formatMapping[bookingFormat];

      const userMaxDuration =
        Math.max(...(users[this.userIndexEnd]?.schedule?.map((s) => s[0] + s[2]) || [0]), 480) / 60;

      newDuration = +(Math.ceil(newDuration * 2) / 2).toFixed(2);
      newDuration = Math.max(newDuration, 0.5);
      newDuration = Math.min(newDuration, userMaxDuration);

      // This part re-paints creation bookings in case of drag/drop creation.
      createBookingsRef.current.forEach((bookingCreateRef) => {
        if (
          this.userIndexStart !== this.userIndexEnd ||
          getNumberOfDays(this.userDateStart, this.userDateEnd) > 0 ||
          this.multipleCreation
        ) {
          this.multipleCreation = true;
          bookingCreateRef?.updateBookings(this.redrawCreationBookings(weekendsHidden));
          bookingCreateRef?.updateDuration(newDuration);
        } else {
          if (
            useBookingsCreatingStore
              .getState()
              .bookingsCreating.findIndex((b) => b[0] === this.userIndexStart && b[1] === this.userDateStart) === -1
          ) {
            createBookingsRef.current[this.userIndexStart].updateBookings([
              ...createBookingsRef.current[this.userIndexStart].bookings.filter(
                (b) => b[0] !== this.userIndexStart || b[1] !== this.userDateStart
              ),
              [this.userIndexStart, this.userDateStart],
            ]);
          }

          createBookingsRef.current.forEach((p) => {
            p.updateDuration(newDuration);
          });
        }
      });
    }
  }

  mouseDowned(offsetX, offsetY, users, multipleSelectRef, weekendsHidden, bookingFormat) {
    if (useShiftPressedStore.getState().ctrlPressed && useBookingsEditingStore.getState().bookingsEditing.length > 0)
      return;

    if (useShiftPressedStore.getState().shiftPressed) {
      useShiftPressedStore.getState().setStartSelected(true);
      multipleSelectRef.current.setOpen(false);
      multipleSelectRef.current.setPos({ x: 0, y: 0 });
      multipleSelectRef.current.setSize({ width: 0, height: 0 });

      useShiftPressedStore.getState().setStartX(offsetX + $("#planner-scroll-list").scrollLeft());
      useShiftPressedStore.getState().setStartY(offsetY + $("#planner-scroll-list").scrollTop());

      multipleSelectRef.current.setPos({
        x: offsetX + $("#planner-scroll-list").scrollLeft(),
        y: offsetY + $("#planner-scroll-list").scrollTop(),
      });

      multipleSelectRef.current.setOpen(true);
    }

    this.clickStartX = offsetX;
    this.clickStartY = offsetY;

    const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(useZoomStore.getState().zoom);
    const dayWidth = getCellWidthForZoom(useZoomStore.getState().zoom);
    let currentHeight = 0;
    const userHeights = users.map((user) => (usersMaxScheduleTime(user) / 60) * this.formatMapping[bookingFormat] + 17);

    userHeights.forEach((userHeight, index) => {
      if (
        currentHeight < offsetY + $("#planner-scroll-list").scrollTop() &&
        currentHeight + userHeight > offsetY + $("#planner-scroll-list").scrollTop()
      ) {
        this.userIndexStart = index;

        let calculatedOffsetX = 0;
        let dateIteration = 0;

        while (calculatedOffsetX < offsetX + $("#planner-scroll-list").scrollLeft()) {
          calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
          dateIteration++;
        }

        this.userDateStart = OffsetDays(useDateStore.getState().date, dateIteration - 1);
      }

      currentHeight += userHeight;
    });
  }
}

export default UserPlannerActions;
