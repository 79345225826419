import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import ControlPanel from "../ControlPanel";
import "../administration.scss";
import axios from "axios";
import Api from "../../../Api";
import { Dialog } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { AWS_URL, ICON_FOLDERS, TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TeambookTextForm from "../../default_components/TeambookTextForm";
import TeambookOutlinedButton from "../../default_components/TeambookOutlinedButton";
import { TeambookReactSelect } from "../../default_components/TeambookReactSelect";

export default function ImagesPage() {
  const adminToken = localStorage.getItem("tb-admin-token");

  const [uploadImageOpen, setUploadImageOpen] = useState(false);
  const [imageName, setImageName] = useState("");
  const [folder, setFolder] = useState("default");

  const uploadFolders = [
    { value: "default", label: "default" },
    { value: "alternative", label: "alternative" },
    { value: "common", label: "common" },
  ];

  const insertNewLocale = () => {};

  const backgroundColor = (folder) => {
    if (folder.includes("light")) {
      return "white";
    } else {
      return "black";
    }
  };

  return (
    <>
      <Link to="/administration">
        <TeambookBlueButton
          style={{
            zIndex: "1",
            position: "fixed",
            left: "5px",
            top: "5px",
          }}
          text={"To Administration"}
        />
      </Link>

      <TeambookOutlinedButton
        text={"Insert Image"}
        onClick={() => setUploadImageOpen(true)}
        style={{ position: "fixed", left: "180px", top: "5px", zIndex: "1" }}
      />

      <div className="flex icons-component">
        {Object.values(ICON_FOLDERS).map((folder) => {
          return (
            <div className="icons-column">
              <div className="flex language-header">
                <p>{folder}</p>
              </div>
              <div className="icons-list">
                {Object.values(icons).map((icon) => {
                  return (
                    <div className="icons-list__icon-row">
                      <p>{icon}</p>
                      <img
                        style={{ width: "50px", maxHeight: "50px", backgroundColor: backgroundColor(folder) }}
                        src={`${AWS_URL}/${folder}/${icon}.svg`}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      <ControlPanel />

      {uploadImageOpen && (
        <Dialog
          className="tb-default-dialog"
          open={true}
          onClose={() => setUploadImageOpen(false)}
          aria-labelledby="add-user-dialog"
        >
          <DialogTitle className="planner-add-user-dialog__dialog-title" id="add-user-dialog">
            <p>Insert Image to S3</p>
            <TeambookIcon name={icons.CROSS} onClick={() => setUploadImageOpen(false)} />
          </DialogTitle>

          <DialogContent style={{ width: "500px" }}>
            <TeambookTextForm
              onChange={setImageName}
              fieldValue={imageName}
              type={"text"}
              placeholder={"header_image"}
              width={200}
            />

            <TeambookReactSelect
              value={uploadFolders.find((compFolder) => compFolder.value === folder)}
              options={uploadFolders}
              onChange={(f) => {
                setFolder(f.value);
              }}
              height={38}
              width={200}
            />
          </DialogContent>

          <DialogActions>
            <TeambookBlueButton
              text={"Insert image"}
              onClick={() => {
                insertNewLocale();
              }}
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
