import PropTypes from "prop-types";
import "./projection.scss";
import { SliceStringWithDots } from "../../lib/StringFormat";
import ProjectionUserBookings from "./ProjectionUserBookings";

const ProjectionUserRows = ({ users, bookings, date, isClientSharing, tasks }) => {
  return users.map((user, key) => (
    <div key={key} className={`projection__user-row ${isClientSharing && key === 0 && "first-row"}`}>
      <div className="projection__user-column">
        <p>
          {SliceStringWithDots(user.first_name, 9)} {SliceStringWithDots(user.last_name, 14)}
        </p>
      </div>

      <ProjectionUserBookings
        user={user}
        bookings={bookings}
        date={date}
        isClientSharing={isClientSharing}
        tasks={tasks}
      />
    </div>
  ));
};

ProjectionUserRows.propTypes = {
  users: PropTypes.array.isRequired,
  bookings: PropTypes.array.isRequired,
  date: PropTypes.object.isRequired,
  isClientSharing: PropTypes.bool.isRequired,
};

export default ProjectionUserRows;
