import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TeambookCheckbox } from "../../default_components/TeambookCheckbox";
import lsKeys from "../../default_values/defaultKeys";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const SettingsLeftSide = ({ page, changePage }) => {
  const { t } = useTranslation();
  const [stagingSettingsEnabled, setStagingSettingsEnabled] = useState(
    localStorage.getItem(lsKeys.STAGING_SETTINGS_ENABLED) === "true"
  );

  const [account] = useAccountStore((state) => [state.account], shallow);

  const switchStagingSettings = () => {
    localStorage.setItem(lsKeys.STAGING_SETTINGS_ENABLED, !stagingSettingsEnabled);
    setStagingSettingsEnabled(!stagingSettingsEnabled);

    window.location.reload();
  };

  const PagesArray = [
    { name: t("settings.general_info.name"), value: "settings" },
    { name: t("settings.time_off.name"), value: "time_offs" },
    { name: t("settings.tasks.name"), value: "tasks" },
    { name: t("settings.schedule.name"), value: "schedule" },
    { name: t("settings.integration.name"), value: "integration" },
    { name: t("settings.customization.name"), value: "customization" },
    { name: t("settings.subscription.subscription_billing"), value: "subscription" },
    { name: t("settings.delete_account.name"), value: "delete" },
  ];

  const renderPages = ({ name }, index) => {
    let isDisabled = false;

    if (localStorage.getItem(lsKeys.ROLE) === "planner" && [6, 7].includes(index)) {
      isDisabled = true;
    }

    return (
      <div
        onClick={() => changePage(PagesArray[index].value)}
        className="settings-option"
        style={{
          backgroundColor: page === PagesArray[index].value ? "var(--stroke)" : "var(--background-1)",
          pointerEvents: isDisabled ? "none" : "unset",
          opacity: isDisabled ? 0.5 : 1,
        }}
        key={index}
      >
        <p style={{ color: page === PagesArray[index].value ? "var(--button-text-hover)" : "var(--text-2)" }}>{name}</p>
      </div>
    );
  };

  return (
    <div className="org-settings-main-container">
      <p className="profile__title">
        {t("settings.account_settings")}{" "}
        {process.env.REACT_APP_ENV !== "production" && process.env.REACT_APP_ENV !== "sandbox" ? account.id : ""}
        {process.env.REACT_APP_ENV !== "production" && process.env.REACT_APP_ENV !== "sandbox" ? (
          <div onClick={switchStagingSettings} className="staging-settings__checkbox pointer">
            <TeambookCheckbox checked={stagingSettingsEnabled} />
            <p style={{ margin: 0 }}>Enable Staging settings</p>
          </div>
        ) : (
          ""
        )}
      </p>

      <div className="org-settings-options">{PagesArray.map(renderPages)}</div>
    </div>
  );
};

export default SettingsLeftSide;
