import { useEffect, useState, useRef } from "react";
import "../../../default_styles/tb-colors.css";
import * as DefaultColors from "../../../default_values/DefaultColors";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "./../../../default_components/Tooltips";
import TeambookColorPicker from "../../../default_components/TeambookColorPicker";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import { icons } from "../../../default_images/IconsList";
import TeambookIconButton from "../../../default_components/TeambookIconButton";
import PropTypes from "prop-types";

const findFirstFreeColor = (tags) => {
  let availableColors = [...DefaultColors.projectColors];

  tags.forEach((tag) => {
    let indexOfElement = availableColors.indexOf(tag.color);

    if (indexOfElement !== -1) {
      availableColors.splice(indexOfElement, 1);
    }
  });

  if (availableColors.length > 0) {
    return availableColors[0];
  } else {
    return DefaultColors.projectColors[0];
  }
};

function CreateTag({
  tags,
  setCreateOpen,
  createTag,
  tagName,
  setTagName,
  tagColor,
  setTagColor,
  errorMessage,
  closeErrorMessage,
  errorField,
}) {
  const { t } = useTranslation();
  const [changeColorsOpened, setChangeColorsOpened] = useState(false);
  const anchorRef = useRef(null);

  useEffect(() => {
    setTagColor(findFirstFreeColor(tags));
    setTagName("");
  }, []);

  const closeCreation = () => {
    setTagName("New Tag");
    setTagColor(findFirstFreeColor(tags));
    closeErrorMessage();
    setCreateOpen(false);
  };

  return (
    <div>
      <div className="tag-menu__tag-editing">
        <div className="tag-menu__tag-add">
          <div style={{ width: 70, padding: "0px 23px" }}>
            <WhiteTooltip title={t("projects.change_color")}>
              <div
                className="tags-color-circle"
                style={{ border: `2px solid ${tagColor}`, backgroundColor: `${tagColor}66`, cursor: "pointer" }}
                onClick={() => setChangeColorsOpened(true)}
                ref={anchorRef}
              />
            </WhiteTooltip>
          </div>

          <TeambookColorPicker
            color={tagColor}
            setColor={setTagColor}
            changeColorsOpened={changeColorsOpened}
            setChangeColorsOpened={setChangeColorsOpened}
            anchorRef={anchorRef}
          />

          <div>
            <TeambookTextForm
              id={"tagName"}
              width={200}
              fieldValue={tagName}
              onChange={setTagName}
              autoFocus
              placeholder={t("users.tag_name")}
              errorField={errorField}
              errorMessage={errorMessage}
              closeErrorMessage={closeErrorMessage}
              field={"name"}
            />
          </div>

          <div className="tag-menu__tag-number">
            <p style={{ margin: 0 }}>0</p>
          </div>

          <div
            style={{
              paddingLeft: 68,
              display: "flex",
              gap: 20,
            }}
          >
            <TeambookIconButton name={icons.CHECK} onClick={createTag} tooltipTitle={t("save")} id={"createTag"} />

            <TeambookIconButton name={icons.CROSS} onClick={closeCreation} tooltipTitle={t("cancel")} />
          </div>
        </div>
      </div>
    </div>
  );
}

CreateTag.propTypes = {
  tags: PropTypes.array.isRequired,
  setCreateOpen: PropTypes.func.isRequired,
  createTag: PropTypes.func.isRequired,
  tagName: PropTypes.string.isRequired,
  setTagName: PropTypes.func.isRequired,
  tagColor: PropTypes.string.isRequired,
  setTagColor: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  closeErrorMessage: PropTypes.func.isRequired,
  errorField: PropTypes.string,
};

export default CreateTag;
